/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Model from '@tripian/model';
import moment from 'moment';
import ImgLazy from '../Base/ImgLazy/ImgLazy';
import styles from './CustomerOfferCard.module.scss';
import { Button } from '../Base/Button/Button';

interface ICustomerOfferCard {
  poiName: string;
  offer: Model.Offer;
}

export const CustomerOfferCard: React.FC<ICustomerOfferCard> = ({ poiName, offer }) => (
  <div className={styles.offerCard}>
    <div className={styles.offerCardImgGeneral}>
      <ImgLazy src={offer.imageUrl} alt="" objectFit="cover" className={styles.offerCardImage} x={256} y={256} />
      {offer.campaign && <div className={styles.offerCardCampaignName}>{offer.campaign.title}</div>}
    </div>
    <div className={styles.offerCardBottom}>
      <div className={styles.bottomContainer}>
        <div className={styles.offerCardName}>{poiName}</div>
        <div className={styles.offerCardTitle}>{offer.title}</div>
        <div className={styles.offerType}>{offer.productType.name.toUpperCase()}</div>

        {offer && (
          <>
            {offer.description && (
              <div className={styles.offerCardCaption}>
                <div className={styles.offerDesc}>{offer.description}</div>
              </div>
            )}
            {offer.campaign && (
              <div className={styles.offerCardCaption}>
                <div className={styles.offerDesc}>{offer.campaign?.description}</div>
              </div>
            )}
          </>
        )}

        <div className={styles.offerCardDate}>
          Offer Valid {moment(offer?.timeframe?.start).format('MMM Do')} - {moment(offer?.timeframe?.end).format('MMM Do')}
        </div>
        <Button customClassName={styles.offerButton} disabled type="primary" label={`Claim ${offer.currency}$${offer.discount} cashback`} />
      </div>
    </div>
  </div>
);
