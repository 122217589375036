import moment from 'moment';
import { useHistory } from 'react-router';
import { CAMPAIGN_REPORT, CAMPAIGN_CREATE, CAMPAIGN_UPDATE, CAMPAIGN_CUSTOMER_REPORT } from '../../const/ROUTER_PATH_TITLE';
import Model from '@tripian/model';
import { initialCampaignFormData } from '../../helper/constants';

export const useCampaignMenuEvents = () => {
  const history = useHistory();

  const onItemClick = (campaign: Model.Campaign) => history.push(`${CAMPAIGN_REPORT.PATH}/${campaign.id}`, campaign);
  const onCustomerReportClick = (campaign: Model.Campaign) => history.push(`${CAMPAIGN_CUSTOMER_REPORT.PATH}/${campaign.id}`, campaign);
  const onDuplicate = (campaign: Model.Campaign): void => {
    let duplicateCampaign = { ...campaign };

    if (moment(campaign.timeframe.start).isAfter(moment())) {
      duplicateCampaign.timeframe.start = initialCampaignFormData.startDate;
    }

    if (moment(campaign.timeframe.end).isAfter(moment())) {
      duplicateCampaign.timeframe.end = initialCampaignFormData.endDate;
    }

    history.push(`${CAMPAIGN_CREATE.PATH}`, duplicateCampaign);
  };

  const onEdit = (campaign: Model.Campaign): void => {
    history.push(`${CAMPAIGN_UPDATE.PATH}`, campaign);
  };

  return {
    onItemClick,
    onCustomerReportClick,
    onEdit,
    onDuplicate,
  };
};
