import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router';
import UserContext from '../../../context/User/UserContext';
// import USER_ACTION_TYPES from '../../context/User/USER_ACTION_TYPES';
// import { LOGIN } from '../../const/ROUTER_PATH_TITLE';
import { Button } from '../../../components/Base/Button/Button';
import { Input } from '../../../components/Base/Input/Input';
import styles from './WaitingVerifyPage.module.scss';
import { api } from '@tripian/core';
import Model from '@tripian/model';
import USER_ACTION_TYPES from '../../../context/User/USER_ACTION_TYPES';
import { InputLabel } from '../../../components/Base/InputLabel/InputLabel';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { LineStep } from '../../../components/Base/LineStep/LineStep';
import { LinkButton } from '../../../components/Base/LinkButton/LinkButton';
import { FormContainer } from '../../../components/Base/FormContainer/FormContainer';
import { BUSINESS_PROFILE } from '../../../const/ROUTER_PATH_TITLE';
import { ModalPage } from '../../../components/Base/ModalPage/ModalPage';

export const WaitingVerifyPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [vStarted, setVStarted] = useState<boolean>(false);
  const [vChecked, setVChecked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [vCode, setVCode] = useState<string>('');
  const [needHelp, setNeedHelp] = useState(false);

  const { payloadData, dispatch } = useContext(UserContext);
  const history = useHistory();

  document.title = 'Waiting Verify';

  const verifyBusiness = (phoneNumber: string, sms = true) => {
    setLoading(true);
    setErrorMessage('');
    api
      .startVerify(phoneNumber, sms ? Model.VERIFY_CHANNEL.SMS : Model.VERIFY_CHANNEL.CALL)
      .then((res: Model.BusinessVerify) => {
        setVStarted(true);
      })
      .catch(() => {
        if (sms === false) setErrorMessage("Phone calls not allowed to the given number. Please try 'Send Text'");
        else {
          setErrorMessage('Something went wrong. Please try again later.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkVerifyBusiness = (phoneNumber: string, verificationCode: string) => {
    setLoading(true);
    setErrorMessage('');
    api
      .checkVerify(phoneNumber, Model.VERIFY_CHANNEL.SMS, verificationCode)
      .then((res: Model.BusinessVerify) => {
        if (res.valid) {
          setVChecked(true);
          if (res)
            setTimeout(() => {
              fetchBusiness();
            }, 1000);
        } else {
        }
      })
      .catch((error) => {
        setErrorMessage(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchBusiness = () => {
    api.business().then((business) => {
      if (business.isVerified) {
        dispatch({ type: USER_ACTION_TYPES.SET_BUSINESS, payload: { business } });
        history.replace(BUSINESS_PROFILE.PATH);
      } else
        setTimeout(() => {
          fetchBusiness();
        }, 1000);
    });
  };

  // temp
  // const logout = () => {
  //   dispatch({ type: USER_ACTION_TYPES.LOG_OUT, payload: {} });
  //   history.push(LOGIN.PATH);
  // };

  if (payloadData.user !== undefined && payloadData.business !== undefined) {
    const phone = '+' + payloadData.business.phone;
    const step1 = vStarted === false;

    return (
      <>
        <LineStep stepCount={3} step={step1 ? 2 : 3} customClassName={styles.lineStep} />
        <FullCenter loading={loading} centerY={false} maxWidth={500} loaderFixed={true}>
          <div className={styles.hContainer}>
            <h4>{step1 ? 'Phone Number' : 'Verification Code'}</h4>
            {step1 ? null : <div className={styles.question}>Didn’t receive your code?</div>}
            {step1 ? (
              <LinkButton text="Need help?" onClick={() => setNeedHelp(true)} />
            ) : (
              <LinkButton text="Request code again?" onClick={() => setVStarted(false)} />
            )}
          </div>

          {errorMessage !== '' ? <h6 className={styles.error + ' center'}>Error: {errorMessage}</h6> : null}

          <FormContainer customClassName={styles.formContainer}>
            {step1 ? (
              <>
                <h6 className="py4">Verify your business phone number</h6>
                <h5 className="my4">+{payloadData.business?.phone}</h5>
                <hr />
                <h6 className="mb4">How can we contact you at the number above?</h6>

                <div className={`${styles.buttons} row`}>
                  <div className="col col12 col6-m">
                    <Button fluid label="Send text *" onClick={() => verifyBusiness(phone)} />
                  </div>
                  <div className="col col12 col6-m">
                    <Button fluid label="Call me" onClick={() => verifyBusiness(phone, false)} />{' '}
                  </div>
                </div>

                <span className={styles.desc}>* mobile only</span>
                {/* <Button label="Back" onClick={logout} size="large" customClassName="m3" /> */}
              </>
            ) : vChecked === false ? (
              <>
                <h6 className="py4">Enter the verification code provided:</h6>
                <InputLabel text="Verification code" customClassName="my2" />
                <Input
                  name="verification-code"
                  type="text"
                  value={vCode}
                  onChange={(e) => {
                    setVCode(e.target.value);
                  }}
                  customClassName="mb3"
                  size="large"
                />
                <div className={`${styles.buttons} row`}>
                  <div className="col col12 col6-m">
                    <Button fluid label="Back" type="secondary" onClick={() => setVStarted(false)} />
                  </div>
                  <div className="col col12 col6-m">
                    <Button fluid label="Submit" onClick={() => checkVerifyBusiness(phone, vCode)} />
                  </div>
                </div>
              </>
            ) : (
              <>
                <h4 className={styles.success}>Verification completed.</h4>
                <h4 className={styles.success}>Please wait for redirecting...</h4>
              </>
            )}
          </FormContainer>
        </FullCenter>
        <ModalPage size="small" show={needHelp} onClose={() => setNeedHelp(false)}>
          <div style={{ padding: '2rem 0', fontSize: '1.5rem', textAlign: 'center' }}>
            Please check the phone number listed in the business profile, as this is where the Verification Code will be sent. <br />
            You must have direct access to this phone to receive the code over voice or text.
          </div>
        </ModalPage>
      </>
    );
  }

  return <h3 className={styles.error}>User or business is empty. Please contact with us by info@tripian.com</h3>;
};
