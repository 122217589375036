import React from 'react';
import { CN } from '../../className';

import styles from './Select.module.scss';

export interface SelectProps {
  id: string;
  name: string;
  options: string[];
  size?: 'small' | 'default' | 'large';
  defaultEmpty?: boolean;
  selectedValue?: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  customClassName?: string;
}

export const Select: React.FC<SelectProps> = ({ name, options, size = 'default', defaultEmpty, selectedValue, onChange, customClassName = '' }) => {
  const className = CN(styles.select, [
    [styles.small, size === 'small'],
    [styles.large, size === 'large'],
    [styles.default, size === 'default'],
    [customClassName, customClassName !== ''],
  ]);

  return (
    <select onChange={onChange} className={className} value={selectedValue}>
      {defaultEmpty && <option hidden disabled value="" defaultValue="" />}
      {options.map((o) => (
        <option key={`${name}-option-${o}`}>{o}</option>
      ))}
    </select>
  );
};
