/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import { hoursFormat } from '../../../helper/common';
import Model from '@tripian/model';
import { Icon } from '../../Base/Icon/Icon';
import Price from '../Price/Price';
import RatingStars from '../RatingStars/RatingStars';
import styles from './BusinessInfoText.module.scss';

interface BusinessInfoText {
  business: Model.Business;
}

const PoiInfoText: React.FC<BusinessInfoText> = ({ business }) => {
  const openedHours = (
    <div className={styles.openedHours}>
      <table>
        <tbody>
          {hoursFormat(business.businessHours || '')?.map((a) => (
            <tr key={`${business.id}${a.day}${a.clock}${Math.floor(Math.random() * 100 + 1)}`}>
              <td>{a.day}</td>
              <td className={styles.clock}>
                {a.clock[0]} <br />
                {a.clock[1]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <span className={styles.arrowup">
        <Svg
          size="1rem"
          viewBox="0 0 320 512"
          path="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"
        />
      </span> */}
    </div>
  );

  // const mustTryMessage = () => {
  //   const mustTryList = business.mustTries;
  //   if (mustTryList.length === 1) {
  //     return `This spot is famous for its ${mustTryList[0].name}.`;
  //   }

  //   return `This spot is famous for its ${mustTryList
  //     .slice(0, mustTryList.length - 1)
  //     .map((taste) => taste.name)
  //     .join(', ')} and ${mustTryList[mustTryList.length - 1].name}.`;
  // };

  // const cuisinesArray = business.cuisines?.split(', ') || [];
  // const uniqueCuisines = removeDuplicateValues(cuisinesArray, (s1, s2) => s1 === s2);

  const info = (
    <>
      {
        <div className={styles.globalRating}>
          <div className={styles.ratingTitle}>Global rating:</div>
          <div className={styles.ratingContent}>
            <span className={styles.ratingCount}>({business.ratingCount})</span>
            <RatingStars height={1.4} rating={`${business.rating ? business.rating * 20 : null}`} />
            {business.price ? (
              <>
                <span className={styles.bull}>&bull;</span>
                <Price price={business.price} />
              </>
            ) : null}
          </div>
        </div>
      }
      <hr />
      <div className={styles.poiInfo}>
        <ul>
          {/* {uniqueCuisines.length > 0 ? (
            <li>
              <div className={styles.cuisinesIcon} />
              <div className={styles.header}>Cuisine: </div>
              <div className={styles.content}>{uniqueCuisines.join(', ')}</div>
            </li>
          ) : null}
          {business.tags.length > 0 ? (
            <li>
              <div className={styles.featuresIcon} />
              <div className={styles.header}>Features:</div>
              <div className={styles.content}>
                <input type="checkbox" id="features" className={styles.featuresToggle} />
                <label htmlFor="features" className={styles.featuresContent}>
                  {business.tags.length > 0 ? business.tags.join(', ') : 'empty'}
                </label>
              </div>
            </li>
          ) : null}
          {business.mustTries.length !== 0 ? (
            <li style={{ backgroundColor: '#e6faff' }}>
              <div className={styles.mustTryIcon} />
              <div className={styles.header}>Must Try:</div>
              <div className={styles.content}>{mustTryMessage()}</div>
            </li>
          ) : null} */}
          {business.businessHours ? (
            <li>
              <div className={styles.icon}>
                <Icon name="HOURS" />
              </div>
              <div className={styles.header}>Hours:</div>
              <div className={styles.content}>
                <label htmlFor="collapsible" className={styles.hoursContent}>
                  {openedHours}
                </label>
              </div>
            </li>
          ) : null}
          {business.phone ? (
            <li>
              <div className={styles.icon}>
                <Icon name="CALL2" />
              </div>
              <div className={styles.header}>Phone: </div>
              <div className={styles.content}>
                <div className={styles.phone}>
                  <a rel="noopener noreferrer" target="_blank" href={`tel:${business.phone}`}>
                    {business.phone}
                  </a>
                </div>
              </div>
            </li>
          ) : null}
          {business.web ? (
            <li>
              <div className={styles.icon}>
                <Icon name="LINK" />
              </div>
              <div className={styles.header}>Web: </div>
              <div className={styles.content}>
                <div className={styles.web}>
                  <a rel="noopener noreferrer" target="_blank" href={`${business.web}`}>
                    {business.web}
                  </a>
                </div>
              </div>
            </li>
          ) : null}
          {business.address ? (
            <li>
              <div className={styles.icon}>
                <Icon name="LOCATION" />
              </div>
              <div className={styles.header}>Address: </div>
              <div className={styles.content}>
                <input type="checkbox" id="address" className={styles.addressToggle} />
                <label htmlFor="address" className={styles.addressContent}>
                  {business.address ? business.address : 'empty'}
                </label>
              </div>
            </li>
          ) : null}
        </ul>
      </div>
    </>
  );

  return (
    <div>
      <div className={styles.poiInfoText}>
        <hr />
        <h6 className={styles.poiTitle}>{business.name} </h6>
        {info}
      </div>
    </div>
  );
};

export default PoiInfoText;
