import { init } from '@tripian/core';
import Model from '@tripian/model';
import '../../config';

export const setConfigList = async (): Promise<void> => {
  const apiConfigList: Model.ConfigList = await fetch(`${window.tconfig.API_URL}/misc/config-list?domain=${window.tconfig.HOSTNAME}`)
    .then((response) => response.json())
    .then((d) => d.data)
    .catch((err) => {
      console.error(`/misc/config-list?domain=${window.tconfig.HOSTNAME}`, err);
      throw new Error(`/misc/config-list?domain=${window.tconfig.HOSTNAME}`, err);
    });

  if (apiConfigList === undefined || apiConfigList.apiKey === undefined || apiConfigList.apiKey === '') {
    console.error('apiConfigList', apiConfigList);
    throw new Error('apiConfigList.apiKey not found!');
  }
  window.tconfig.API_KEY = apiConfigList.apiKey;

  init(window.tconfig.API_URL, window.tconfig.API_KEY, undefined, false);

  const DEFAULT_COLORS = {
    PRIMARY: '#e9314a',
    SECONDARY: '#ffeef0',
    SUCCESS: '#2da52deb',
    WARNING: '#f9a938',
    INFO: '#333232ff',
    DANGER: '#e54e53',
    TEXT_PRIMARY: '#434b55',
    BACKGROUND: '#f5f5f5',
    HEADER_BACKGROUND: 'f5f5f5',
    HEADER_TEXT_COLOR: '#000000',
  };

  const defaultTosUrl = 'https://www.tripian.com/terms-conditions.html';
  const defaultPpUrl = 'https://www.tripian.com/privacy-policy.html';

  window.tconfig.BRAND_NAME = apiConfigList.whiteLabels.brandName;

  window.tconfig.IMAGE_PATHS = {
    LOGO_PATH_DARK: apiConfigList.whiteLabels.imagePaths.logoPathDark,
    LOGO_PATH_LIGHT: apiConfigList.whiteLabels.imagePaths.logoPathLight,
    FORM_HEADER_IMG_URL:
      apiConfigList.whiteLabels.imagePaths.formHeaderImgUrl === '' ? undefined : apiConfigList.whiteLabels.imagePaths.formHeaderImgUrl,
    FORM_BG_IMG_URL: apiConfigList.whiteLabels.imagePaths.formBgImgUrl === '' ? undefined : apiConfigList.whiteLabels.imagePaths.formBgImgUrl,
    APP_BACKGROUND_IMG_URL:
      apiConfigList.whiteLabels.imagePaths.appBackgroundImgUrl === '' ? undefined : apiConfigList.whiteLabels.imagePaths.appBackgroundImgUrl,
  };

  window.tconfig.GOOGLE_ANALYTICS_URL = apiConfigList.whiteLabels.googleAnalyticsUrl.business;
  window.tconfig.GOOGLE_ANALYTICS_KEY = apiConfigList.whiteLabels.googleAnalyticsKey.business;
  window.tconfig.QR_READER = apiConfigList.baseFeatures.qrReader;

  window.tconfig.COLORS = {
    PRIMARY: apiConfigList.whiteLabels.theme?.light.primary || DEFAULT_COLORS.PRIMARY,
    SECONDARY: apiConfigList.whiteLabels.theme?.light.secondary || DEFAULT_COLORS.SECONDARY,
    SUCCESS: apiConfigList.whiteLabels.theme?.light.success || DEFAULT_COLORS.SUCCESS,
    WARNING: apiConfigList.whiteLabels.theme?.light.warning || DEFAULT_COLORS.WARNING,
    INFO: apiConfigList.whiteLabels.theme?.light.info || DEFAULT_COLORS.INFO,
    DANGER: apiConfigList.whiteLabels.theme?.light.danger || DEFAULT_COLORS.DANGER,
    TEXT_PRIMARY: apiConfigList.whiteLabels.theme?.light.textPrimary || DEFAULT_COLORS.TEXT_PRIMARY,
    BACKGROUND: apiConfigList.whiteLabels.theme?.light.background || DEFAULT_COLORS.BACKGROUND,
    HEADER_BACKGROUND: apiConfigList.whiteLabels.theme?.light.headerBg || DEFAULT_COLORS.HEADER_BACKGROUND,
    HEADER_TEXT_COLOR: apiConfigList.whiteLabels.theme?.light.headerTextColor || DEFAULT_COLORS.HEADER_TEXT_COLOR,
  };

  window.tconfig.SBT = {
    OFFER_TYPES: apiConfigList.sbt.offerTypes,
    CAMPAIGN_DEFAULT_CURRENCY: apiConfigList.sbt.campaignDefaultCurrency,
  };

  window.tconfig.BRAND_URL = apiConfigList.whiteLabels.brandUrl;
  window.tconfig.TOS_URL = apiConfigList.whiteLabels.tosUrl || defaultTosUrl;
  window.tconfig.PP_URL = apiConfigList.whiteLabels.ppUrl || defaultPpUrl;
};
