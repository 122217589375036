import React from 'react';
import { CN } from '../../className';
import styles from './Button.module.scss';

export interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  type?: 'primary' | 'secondary';
  /**
   * How large should the button be?
   */
  size?: 'medium' | 'small';
  /**
   * Is the button fluid in it's parent?
   */
  fluid?: boolean;
  /**
   * Button contents
   */
  label: string;
  /**
   * Disabled to button events
   */
  disabled?: boolean;
  /**
   * Optional click handler
   */
  onClick?: () => void;

  customClassName?: string;
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
  type = 'primary',
  size = 'medium',
  fluid = false,
  disabled = false,
  label,
  customClassName = '',
  ...props
}) => {
  const className = CN(styles.button, [
    [styles.primary, type === 'primary'],
    [styles.secondary, type === 'secondary'],
    [styles.small, size === 'small'],
    [styles.fluid, fluid === true],
    [styles.disabled, disabled === true],
    [customClassName, customClassName !== ''],
  ]);

  return (
    <button type="button" className={className} {...props} disabled={disabled}>
      {label}
    </button>
  );
};
