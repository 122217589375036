import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import ReportTable from './ReportTable/ReportTable';
import { useBusinessReport } from './useBusinessReport';
import { BUSINESS_REPORT } from '../../../const/ROUTER_PATH_TITLE';
import classes from './BusinessReportPage.module.scss';

export const BusinessReportPage = () => {
  const { loading, data, fetchBusinessReport, onExportAll } = useBusinessReport();

  document.title = BUSINESS_REPORT.TITLE;

  return (
    <FullCenter centerY={false} customClassName={classes.businessReportContainer} /* loading={loading} */>
      <ReportTable data={data} fetch={fetchBusinessReport} loading={loading} exportAllOnClick={onExportAll} />
    </FullCenter>
  );
};
