import { TableCell, TableRow } from '@mui/material';
import Model from '@tripian/model';
import moment from 'moment';
import { DatePicker } from '../../../../../components/DatePicker/DatePicker';
import styles from './ReportTableFilter.module.scss';

type Props = {
  filters: Partial<Model.CampaignCustomerReportRequest>;
  setFilters: (newFilter: Partial<Model.CampaignCustomerReportRequest>) => void;
};
export const ReportTableFilter: React.FC<Props> = ({ filters, setFilters }) => {
  const onFreetextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'customerFullName') setFilters({ ...filters, fullName: e.target.value });
    else if (e.target.name === 'customerEmail') setFilters({ ...filters, email: e.target.value });
    else if (e.target.name === 'leadCustomerFullName') setFilters({ ...filters, fullName: e.target.value });
    else if (e.target.name === 'leadCustomerEmail') setFilters({ ...filters, email: e.target.value });
    else if (e.target.name === 'numberOfTravellers') {
      if (e.target.value === '') {
        setFilters({ ...filters, numberOfTravelers: undefined });
      } else {
        setFilters({ ...filters, numberOfTravelers: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) });
      }
    } else if (e.target.name === 'lengthOfStay') {
      if (e.target.value === '') {
        setFilters({ ...filters, lengthOfStay: undefined });
      } else {
        setFilters({ ...filters, lengthOfStay: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) });
      }
    } else if (e.target.name === 'typeOfProperty') setFilters({ ...filters, propertyType: e.target.value });
    else if (e.target.name === 'nameOfProperty') setFilters({ ...filters, propertyName: e.target.value });
    else if (e.target.name === 'arrivalFrom') setFilters({ ...filters, arrivalFrom: e.target.value });
    else if (e.target.name === 'airline') setFilters({ ...filters, airline: e.target.value });
    else if (e.target.name === 'status') {
      if (e.target.value === '') {
        setFilters({ ...filters, status: undefined });
      } else {
        setFilters({ ...filters, status: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) });
      }
    } else if (e.target.name === 'optInOfferCount') {
      if (e.target.value === '') {
        setFilters({ ...filters, claimCount: undefined });
      } else {
        setFilters({ ...filters, claimCount: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) });
      }
    } else if (e.target.name === 'redeemedOfferCount') {
      if (e.target.value === '') {
        setFilters({ ...filters, redeemCount: undefined });
      } else {
        setFilters({ ...filters, redeemCount: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) });
      }
    } else if (e.target.name === 'totalRedeemedAmount') {
      if (e.target.value === '') {
        setFilters({ ...filters, totalRedeemedAmount: undefined });
      } else {
        setFilters({ ...filters, totalRedeemedAmount: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) });
      }
    } else if (e.target.name === 'redeemedAts') {
      if (e.target.value === '') {
        setFilters({ ...filters, redeemedAt: undefined });
      } else {
        setFilters({ ...filters, redeemedAt: e.target.value });
      }
    } else if (e.target.name === 'howDidYouHear') {
      setFilters({ ...filters, heardBy: e.target.value });
    }
  };

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.name === 'status') {
      setFilters({
        ...filters,
        status: e.target.value === 'All' ? undefined : Number(e.target.value),
      });
    } else if (e.target.name === 'howDidYouBookYourTrip') {
      setFilters({
        ...filters,
        bookedTripBy: e.target.value === 'All' ? undefined : (e.target.value as 'Online' | 'Travel Agent/ Tour Operator' | 'Direct with hotel'),
      });
    }
  };

  return (
    <TableRow className={styles.filterRow}>
      {/* 0 */}
      <TableCell className={styles.tableHeader}>Filters:</TableCell>
      {/* 1 */}
      <TableCell>
        <input
          className={styles.customInput}
          name="customerFullName"
          placeholder="Full Name"
          type="text"
          value={filters.fullName}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* 2 */}
      <TableCell>
        <input
          className={styles.customInput}
          name="customerEmail"
          placeholder="Email"
          type="text"
          value={filters.email}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* 3 */}
      <TableCell>
        <input
          className={`${styles.customInput} ${styles.amountInput}`}
          name="numberOfTravellers"
          placeholder="Number"
          type="text"
          value={filters.numberOfTravelers}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* 4 */}
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Application Date"
          value={filters.applicationDate ? moment(filters.applicationDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, applicationDate: date });
            } else {
              setFilters({ ...filters, applicationDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
      {/* 5 */}
      <TableCell>
        <select className={styles.reportSelectStatus} name="status" value={filters.status} onChange={onSelectChange}>
          <option value="All">All</option>
          <option value="1">Approved</option>
          <option value="0">Waiting</option>
          <option value="-1">Rejected</option>
        </select>
      </TableCell>
      {/* 6 */}
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Arrival Date"
          value={filters.arrivalDate ? moment(filters.arrivalDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, arrivalDate: date });
            } else {
              setFilters({ ...filters, arrivalDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
      {/* 7 */}
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Departure Date"
          value={filters.departureDate ? moment(filters.departureDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, departureDate: date });
            } else {
              setFilters({ ...filters, departureDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
      {/* 8 */}
      <TableCell>
        <input
          className={`${styles.customInput} ${styles.amountInput}`}
          name="lengthOfStay"
          placeholder="Number"
          type="text"
          value={filters.lengthOfStay}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* 9 */}
      <TableCell>
        <input
          className={styles.customInput}
          name="typeOfProperty"
          placeholder="Property Type"
          type="text"
          value={filters.propertyType}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* 10 */}
      <TableCell>
        <input
          className={styles.customInput}
          name="nameOfProperty"
          placeholder="Property Name"
          type="text"
          value={filters.propertyName}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* 11 */}
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Booking Date"
          value={filters.bookingDate ? moment(filters.bookingDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, bookingDate: date });
            } else {
              setFilters({ ...filters, bookingDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
      {/* 12 */}
      <TableCell>
        <input
          className={styles.customInput}
          name="arrivalFrom"
          placeholder="Arrival From"
          type="text"
          value={filters.arrivalFrom}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* 13 */}
      <TableCell>
        <input className={styles.customInput} name="airline" placeholder="Airline" type="text" value={filters.airline} onChange={onFreetextChange} />
      </TableCell>
      {/* 14 */}
      <TableCell>
        <input
          className={`${styles.customInput} ${styles.amountInput}`}
          name="optInOfferCount"
          placeholder="Count"
          type="text"
          value={filters.claimCount}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* 15 */}
      <TableCell>
        <input
          className={`${styles.customInput} ${styles.amountInput}`}
          name="redeemedOfferCount"
          placeholder="Count"
          type="text"
          value={filters.redeemCount}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* 16 */}
      <TableCell>
        <input
          className={styles.customInput}
          name="totalRedeemedAmount"
          placeholder="Amount"
          type="text"
          value={filters.totalRedeemedAmount}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* 17 */}
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Claimed Date"
          value={filters.claimDate ? moment(filters.claimDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, claimDate: date });
            } else {
              setFilters({ ...filters, claimDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
      {/* 18 */}
      <TableCell>
        <input
          className={styles.customInput}
          name="redeemedAts"
          placeholder="Redeemed At"
          type="text"
          value={filters.redeemedAt ? filters.redeemedAt : undefined}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* 19 */}
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Redeemed Date"
          value={filters.redeemDate ? moment(filters.redeemDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, redeemDate: date });
            } else {
              setFilters({ ...filters, redeemDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
      {/* 20 */}
      <TableCell>
        <select className={styles.reportSelectStatus} name="howDidYouBookYourTrip" value={filters.bookedTripBy} onChange={onSelectChange}>
          <option value="All">All</option>
          <option value="Online">Online</option>
          <option value="Travel Agent/ Tour Operator">Travel Agent/ Tour Operator</option>
          <option value="Direct with hotel">Direct with hotel</option>
        </select>
      </TableCell>
      {/* 21 */}
      <TableCell>
        <input
          className={styles.customInput}
          name="howDidYouHear"
          placeholder="Heard By"
          type="text"
          value={filters.heardBy}
          onChange={onFreetextChange}
        />
      </TableCell>
    </TableRow>
  );
};
