/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode } from 'react';
import { Icon } from '../Base/Icon/Icon';

import styles from './IconRow.module.scss';

type Props = {
  icon:
    | 'CLOCK'
    | 'CAR'
    | 'COMPONENT'
    | 'USER_TICK'
    | 'EYE'
    | 'GALLERY'
    | 'COPY'
    | 'BOOK_SAVED'
    | 'TRASH'
    | 'EYE_SLASH'
    | 'EDIT'
    | 'CAMPAIGN_LIMIT'
    | 'AVAILABLE'
    | 'RECURRENT'
    | 'BLACKOUT_DAY'
    | 'IS_FLEXIBLE'
    | 'BONUS';
  header: string;
  text?: string | ReactNode;
};

export const IconRow: React.FC<Props> = ({ icon, header, text }) => {
  return (
    <div className={styles.iconRow}>
      <Icon name={icon} />
      <div className={styles.header}>{header}</div>
      <div className={styles.separater} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};
