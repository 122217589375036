import { useCallback } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Icon } from '../../../../../components/Base/Icon/Icon';
import styles from './ReportTableHeader.module.scss';

type Props = {
  orderColumnIndex: number;
  setOrderColumnIndex: (newOrderColumnIndex: number) => void;
  orderAsc: boolean;
  setOrderAsc: (newOrderAsc: boolean) => void;
};
export const ReportTableHeader: React.FC<Props> = ({ orderColumnIndex, setOrderColumnIndex, orderAsc, setOrderAsc }) => {
  const rowHeaderClicked = useCallback(
    (index: number) => {
      if (orderColumnIndex === index) setOrderAsc(!orderAsc);
      else {
        setOrderColumnIndex(index);
        setOrderAsc(true);
      }
    },
    [orderAsc, orderColumnIndex, setOrderAsc, setOrderColumnIndex],
  );

  return (
    <TableRow style={{ cursor: 'pointer' }} className={styles.reporTableHeader}>
      <TableCell onClick={() => rowHeaderClicked(-1)} className={styles.tableSmallWidth}>
        <div className={styles.tableHeaderContent}>
          ID {orderColumnIndex === -1 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(0)}>
        <div className={styles.tableHeaderContent}>
          Business Name {orderColumnIndex === 0 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(1)}>
        <div className={styles.tableHeaderContent}>
          Offer Title {orderColumnIndex === 1 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(2)}>
        <div className={styles.tableHeaderContent}>
          Opt In Count {orderColumnIndex === 2 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(3)} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Start Date {orderColumnIndex === 3 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(4)} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          End Date {orderColumnIndex === 4 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
    </TableRow>
  );
};
