import moment from 'moment';
// import { Customer } from '../model/api';

export const offerTimeFormat = (timeframe: { start: string; end: string }) => {
  const startDate = moment(timeframe.start);
  const endDate = moment(timeframe.end);

  // console.log(endDate.dayOfYear(), startDate.dayOfYear());
  if (endDate.dayOfYear() !== startDate.dayOfYear()) return moment(startDate).format('MMM D') + ' - ' + moment(endDate).format('MMM D');

  return moment(startDate).format('MMM D') + ' (' + moment(startDate).format('HH:mm') + ' - ' + moment(endDate).format('HH:mm') + ')';
};

/* export const offerAcceptedFormat = (timeframe: { start: string; end: string }, customers: Customer[]) => {
  let messageDay = '';
  let viewDate: moment.Moment | undefined;

  const today = moment();
  const startDate = moment(timeframe.start);
  const endDate = moment(timeframe.end);

  // didn't started
  if (today.dayOfYear() < startDate.dayOfYear()) {
    viewDate = startDate;
    messageDay = moment(startDate).format('MMM D');
  }
  // finished
  else if (endDate.dayOfYear() < today.dayOfYear()) {
    viewDate = undefined;
    messageDay = '';
  }
  // today
  else {
    viewDate = today;
    messageDay = 'Today';
  }

  let customersCount = customers.length;
  if (viewDate === undefined) return `${customersCount} accepted`;

  customersCount = customers.filter((c) => moment(c.optInDate).dayOfYear() === viewDate?.dayOfYear()).length;
  return `${customersCount} for ${messageDay}`;
}; */
