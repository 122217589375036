/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { imgUrl } from '../../../helper/common';
import ImageGallery from 'react-image-gallery';

import './BusinessInfoImage.scss';
import Model from '@tripian/model';

interface IBusinessInfoImage {
  business: Model.Business;
}

const BusinessInfoImage: React.FC<IBusinessInfoImage> = ({ business }) => {
  const images = business.gallery.map((img) => {
    return { url: imgUrl(img.url, 800, 600) };
  });

  return (
    <div>
      <ImageGallery
        autoPlay={false}
        showFullscreenButton={false}
        showPlayButton={false}
        showBullets
        infinite={false}
        showThumbnails={false}
        items={images.map((img) => {
          return { original: img.url, thumbnail: img.url };
        })}
      />
    </div>
  );
};

export default BusinessInfoImage;
