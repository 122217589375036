import React, { useContext } from 'react';
import BusinessInfoImage from '../../../components/BusinessInfo/BusinessInfoImage/BusinessInfoImage';
import BusinessInfoText from '../../../components/BusinessInfo/BusinessInfoText/BusinessInfoText';
import UserContext from '../../../context/User/UserContext';
import { BUSINESS_PROFILE } from '../../../const/ROUTER_PATH_TITLE';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { FormContainer } from '../../../components/Base/FormContainer/FormContainer';
import styles from './BusinessProfilePage.module.scss';

export const BusinessProfilePage = () => {
  const { payloadData } = useContext(UserContext);

  document.title = BUSINESS_PROFILE.TITLE;

  if (payloadData.business) {
    return (
      <FullCenter centerY={false} maxWidth={410} customClassName={styles.fullCenter}>
        <FormContainer customClassName={styles.container}>
          <BusinessInfoImage business={payloadData.business} />
          <BusinessInfoText business={payloadData.business} />
        </FormContainer>
      </FullCenter>
    );
  }

  return <span>Business Not Found</span>;
};
