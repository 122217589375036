/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from 'react';
import { useHistory } from 'react-router';
import Model from '@tripian/model';
import { api } from '@tripian/core';
import { CAMPAIGNS, CAMPAIGN_CREATE } from '../../../const/ROUTER_PATH_TITLE';
import { errorMessage } from '../../../helper/toastr';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { initialCampaignFormData } from '../../../helper/constants';
import { CampaignForm } from '../../../components/CampaignForm/CampaignForm';
import styles from './CampaignUpdatePage.module.scss';

export const CampaignUpdatePage = () => {
  const history = useHistory();

  const campaignToFormData = (campaign: Model.Campaign): Model.CampaignAddRequest => ({
    ...initialCampaignFormData,
    title: campaign.title,
    category: campaign.category,
    limited: campaign.limited,
    // currency: campaign.currency,
    isAmountMax: campaign.isAmountMax,
    amount: campaign.amount,
    maximumOfferAmount: campaign.maximumOfferAmount,
    maximumRecipients: campaign.maximumRecipients,
    description: campaign.description,
    startDate: campaign.timeframe.start,
    endDate: campaign.timeframe.end,
    recurrent: campaign.timeframe.recurrent,
    blackouts: campaign.timeframe.blackouts,
    bonus: campaign.bonus,
  });

  const [campaignFormData, setCampaignFormData] = useState<Model.CampaignAddRequest>(campaignToFormData(history.location.state as Model.Campaign));
  const [loading, setLoading] = useState<boolean>(false);

  document.title = CAMPAIGN_CREATE.TITLE;

  const onSubmit = () => {
    setLoading(true);
    api
      .businessCampaignUpdate((history.location.state as Model.Campaign).id, {
        endDate: campaignFormData.endDate,
        recurrent: campaignFormData.recurrent,
        blackouts: campaignFormData.blackouts,
        bonus: campaignFormData.bonus,
        isAmountMax: campaignFormData.isAmountMax,
        maximumRecipients: campaignFormData.maximumRecipients,
        description: campaignFormData.description,
      })
      .then((d) => {
        if (d === true) {
          history.push(CAMPAIGNS.PATH);
        } else {
          errorMessage('Failed to update.Please try again later.');
        }
      })
      .catch((err) => {
        errorMessage(err);
        setLoading(false);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FullCenter centerY={false} customClassName={styles.fullCenter} loading={loading}>
      <CampaignForm
        formData={campaignFormData}
        formOnChange={(campaignData) => {
          setCampaignFormData(campaignData);
        }}
        formCallBack={() => {
          setLoading(true);
          onSubmit();
        }}
        goBack={() => history.push(CAMPAIGNS.PATH)}
        isCampaignEdit
      />
    </FullCenter>
  );
};
