import Model from '@tripian/model';

const allProductTypes: Model.OfferProductType[] = [
  {
    id: 1,
    name: Model.PRODUCT_TYPE_NAME.EXPERIENCES,
  },
  {
    id: 2,
    name: Model.PRODUCT_TYPE_NAME.DINING,
  },

  {
    id: 3,
    name: Model.PRODUCT_TYPE_NAME.SHOPPING,
  },
];

export const productTypeById = (productTypeId: number): Model.OfferProductType =>
  allProductTypes.find((p) => p.id === productTypeId) || allProductTypes[0];

/* export const productType = (productTypeName: Model.PRODUCT_TYPE_NAME, receiveMethod: Model.OFFER_RECEIVE_METHOD): Model.OfferProductType =>
  allProductTypes.find((p) => p.name === productTypeName && p.receiveMethod === receiveMethod) || allProductTypes[0]; */

/* export const receiveMethod = (productTypeId: number): Model.OFFER_RECEIVE_METHOD =>
  allProductTypes.find((p) => p.id === productTypeId)?.receiveMethod || Model.OFFER_RECEIVE_METHOD.TAKEOUT; */

/* export const receiveMethodByName = (productType: Model.PRODUCT_TYPE_NAME): Model.OFFER_RECEIVE_METHOD[] =>
  allProductTypes.filter((p) => p.name === productType).map((p) => p.receiveMethod); */

export const productTypeName = (productTypeId: number): Model.PRODUCT_TYPE_NAME =>
  allProductTypes.find((p) => p.id === productTypeId)?.name || Model.PRODUCT_TYPE_NAME.EXPERIENCES;

export const productTypeId = (productTypeName: Model.PRODUCT_TYPE_NAME): number => allProductTypes.find((p) => p.name === productTypeName)?.id ?? -1;

export const OFFER_TYPES: Model.OFFER_TYPE[] = [
  Model.OFFER_TYPE.AMOUNT,
  Model.OFFER_TYPE.PERCENTAGE,
  Model.OFFER_TYPE.QUANTITY,
  Model.OFFER_TYPE.SPEND_FOR_GIFT,
  Model.OFFER_TYPE.VOUCHER,
];

export const PRODUCT_TYPE_NAMES: Model.PRODUCT_TYPE_NAME[] = [
  Model.PRODUCT_TYPE_NAME.EXPERIENCES,
  Model.PRODUCT_TYPE_NAME.DINING,
  Model.PRODUCT_TYPE_NAME.SHOPPING,
];

export const OFFER_STATUSES: Model.OFFER_STATUS[] = [
  Model.OFFER_STATUS.ACTIVE,
  Model.OFFER_STATUS.INACTIVE,
  Model.OFFER_STATUS.EXPIRED,
  Model.OFFER_STATUS.ARCHIVE,
];

export const offerTypeName = (offerType: Model.OFFER_TYPE): string => {
  const offerTypeTexts = [
    { type: Model.OFFER_TYPE.AMOUNT, text: 'Dollar discount on purchase' },
    { type: Model.OFFER_TYPE.PERCENTAGE, text: 'Percent discount on purchase' },
    { type: Model.OFFER_TYPE.QUANTITY, text: 'Buy 1, Get 1 or Multi-Buy' },
    { type: Model.OFFER_TYPE.SPEND_FOR_GIFT, text: 'Gift on purchase' },
    { type: Model.OFFER_TYPE.VOUCHER, text: 'Voucher' },
  ];
  return offerTypeTexts.find((ott) => ott.type === offerType)?.text || '';
};
