import React from 'react';
import styles from './PasswordControl.module.scss';

type PasswordControlProps = {
  password: string;
};

export const PasswordControl: React.FC<PasswordControlProps> = ({ password }) => {
  return (
    <div className={styles.mainDiv}>
      <div className={styles.item}>
        {password.length < 6 ? <i className={`${styles.timesIcon} fa fa-times`} /> : <i className={`${styles.checkIcon} fa fa-check`} />}
        <span className={styles.text}>6 characters minimum</span>
      </div>
      <div className={styles.item}>
        {password.match(/[a-z]/) ? <i className={`${styles.checkIcon} fa fa-check`} /> : <i className={`${styles.timesIcon} fa fa-times`} />}
        <span className={styles.text}>Lower case letter</span>
      </div>
      {/* <div className={styles.item}>
        {password.match(/[A-Z]/) ? <i className={`${styles.checkIcon} fa fa-check`} /> : <i className={`${styles.timesIcon} fa fa-times`} />}
        <span className={styles.text}>Upper case letter</span>
      </div> */}
      <div className={styles.item}>
        <div className={styles.item}>
          {password.match(/[0-9]/) ? <i className={`${styles.checkIcon} fa fa-check`} /> : <i className={`${styles.timesIcon} fa fa-times`} />}
        </div>
        <span className={styles.text}>Number</span>
      </div>
    </div>
  );
};
