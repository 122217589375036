import Model from '@tripian/model';
import React from 'react';
import moment from 'moment';
import { OfferCard } from '../../../../components/OfferCard/OfferCard';
import { Button } from '../../../../components/Base/Button/Button';
import styles from './OfferPreviewItem.module.scss';
import { FullCenter } from '../../../../components/Base/FullCenter/FullCenter';
import QRCode from 'react-qr-code';

type Props = {
  offer: Model.Offer;
  customers: Model.OfferCustomer[];
  businessName: string;
  customerLoading: boolean;
  goBack: () => void;
};

export const OfferPreviewItem: React.FC<Props> = ({ offer, customers, businessName, customerLoading, goBack }) => {
  return (
    <FullCenter maxWidth="30rem">
      <div className={styles.hContainer}>
        <h4>Offer Summary</h4>
        <div className={styles.question}>This is how users will see your offer.</div>
      </div>

      {offer ? <OfferCard businessName={businessName} offer={offer} voucherOffer={offer as any as Model.VoucherOfferAddRequest} /> : null}
      <div className={styles.itemsContainer}>
        {/* Aşağısı qrUrl Olacak */}
        {window.tconfig.QR_READER === 'customer' && (
          <QRCode
            className={styles.qrCode}
            value={offer.qrUrl ? `https://${offer.qrUrl}` : `https://trial-dev.tripian.com/offer-payment/${offer.id}`}
          />
        )}

        <div className={styles.hContainer}>
          <h5>Customers</h5>
          <div className={styles.question}>This offer has been opt-in by the following customers.</div>
          {customerLoading ? (
            <div>Customers loading.</div>
          ) : customers.length === 0 ? (
            <div>No customers have opted in to this offer.</div>
          ) : (
            customers.map((c, i) => (
              <div key={`offer-customers-${i}`} className={styles.customer}>{`${c.customerFirstName} ${c.customerLastName} for ${moment(
                c.optInDate,
              ).format('MMM D')}`}</div>
            ))
          )}
        </div>
      </div>

      <Button onClick={goBack} label="Back" type="secondary" customClassName={styles.button} />
    </FullCenter>
  );
};
