/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useCallback, useState, useContext } from 'react';

import { useHistory } from 'react-router';
import { OFFER_CREATE, OFFERS } from '../../../const/ROUTER_PATH_TITLE';
import { OfferItemList } from '../../../components/OfferItemList/OfferItemList';
import { TabMenu } from '../../../components/TabMenu/TabMenu';
import styles from './OffersPage.module.scss';
import UserContext from '../../../context/User/UserContext';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';

const tabItems: string[] = ['Active', 'Inactive', 'Archived'];

export const OffersPage = () => {
  const [selectedTab, setSelectedTab] = useState<'Active' | 'Inactive' | 'Archived'>('Active');
  const [refreshCursor, setRefreshCursor] = useState<number>(0);

  const { payloadData } = useContext(UserContext);

  const history = useHistory();
  document.title = OFFERS.TITLE;

  const fetchOffers = useCallback(() => {
    setRefreshCursor((prev) => prev + 1);
  }, []);

  //#region OfferItem menu clicks

  //#endregion

  return (
    <FullCenter centerY={false} customClassName={styles.fullCenter}>
      <TabMenu selectedMenuItem={selectedTab} menuItems={tabItems} onClick={(stab: string) => setSelectedTab(stab as any)} />
      <div className={styles.uu}>
        <OfferItemList
          myStatus="Active"
          status={selectedTab}
          businessName={payloadData.business?.name || ''}
          refreshCursor={refreshCursor}
          refreshOffers={fetchOffers}
        />
        <OfferItemList
          myStatus="Inactive"
          status={selectedTab}
          businessName={payloadData.business?.name || ''}
          refreshCursor={refreshCursor}
          refreshOffers={fetchOffers}
        />
        <OfferItemList
          myStatus="Archived"
          status={selectedTab}
          businessName={payloadData.business?.name || ''}
          refreshCursor={refreshCursor}
          refreshOffers={fetchOffers}
        />
        <a className={styles.float} onClick={() => history.push(OFFER_CREATE.PATH)}>
          <i className="fa fa-plus"></i>
        </a>
      </div>
    </FullCenter>
  );
};
