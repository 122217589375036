import React, { useEffect, useState } from 'react';
import Model from '@tripian/model';
import { PersonalInformationForm } from './PersonalInformationForm/PersonalInformationForm';
import { Button } from '../../../Base/Button/Button';
import { Validate } from '../../../../model/Validate';
import { initialValidate, ordinalSuffix, validatePersonalInformation } from '../../helper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { Icon } from '../../../Base/Icon/Icon';
import styles from './PersonalInformations.module.scss';

const initialPersonalInformations: Model.PersonalInformation = {
  title: '' as 'Mr' | 'Mrs' | 'Ms',
  firstName: '',
  lastName: '',
  arrivalDate: '',
  departureDate: '',
  departureDestination: '',
  airline: '',
  flightNumber: '',
  dateOfBirth: '',
  emailAddress: '',
  phoneNumber: '',
};
type Props = {
  adult: number;
  personalInformations: Model.PersonalInformation[];
  personalInformationsOnChange: (personalInformations: Model.PersonalInformation[]) => void;
  backButtonClick: () => void;
  continueButtonClick: () => void;
};

export const PersonalInformations: React.FC<Props> = ({
  adult,
  personalInformations,
  personalInformationsOnChange,
  backButtonClick,
  continueButtonClick,
}) => {
  const [validates, setValidates] = useState<Validate[][]>(Array.from({ length: adult }, () => Array.from({ length: 12 }, () => initialValidate)));
  const [isContinueClicked, setIsContinueClicked] = useState<boolean>(false);
  const [expandeds, setExpandeds] = useState<{ expand: boolean; invalidInputIndex: number }[]>(
    Array.from({ length: adult }, () => ({ expand: false, invalidInputIndex: -1 })),
  );

  useEffect(() => {
    const newValidates: Validate[][] = [];
    personalInformations.forEach((personalInformation, i) => {
      const validate = validatePersonalInformation(
        personalInformation,
        personalInformations.filter((_, j) => i !== j).map((x) => x.emailAddress),
      );
      newValidates.push([...validate]);
      setValidates(newValidates);
    });
  }, [personalInformations]);

  useEffect(() => {
    if (adult < personalInformations.length) {
      personalInformationsOnChange(personalInformations.slice(0, adult));
    } else if (personalInformations.length < adult) {
      const newPersonalInformations = Array.from({ length: adult - personalInformations.length }, () => initialPersonalInformations);
      personalInformationsOnChange([...personalInformations, ...newPersonalInformations]);
    }
  }, [adult, personalInformations, personalInformations.length, personalInformationsOnChange]);

  const personalInformationFormOnChange = (index: number, newPersonalInformation: Model.PersonalInformation) => {
    const befores = personalInformations.slice(0, index);
    // const current = personalInformations[index];
    const afters = personalInformations.slice(index + 1);

    const autoFilledAfters = afters.map((personalInformation) => {
      return {
        ...personalInformation,
        airline: newPersonalInformation.airline,
        flightNumber: newPersonalInformation.flightNumber,
        departureDestination: newPersonalInformation.departureDestination,
        departureDate: newPersonalInformation.departureDate,
        arrivalDate: newPersonalInformation.arrivalDate,
      };
    });

    personalInformationsOnChange([...befores, newPersonalInformation, ...autoFilledAfters]);
  };

  return (
    <>
      {personalInformations.map((personalInformation, index) => {
        return (
          <div key={index} className={styles.accordionRow}>
            <Accordion
              expanded={expandeds[index].expand}
              onChange={(event: React.SyntheticEvent, newExpanded: boolean) => {
                const newExpandeds = [...expandeds];
                newExpandeds[index].expand = newExpanded;
                newExpandeds[index].invalidInputIndex = -1;
                setExpandeds(newExpandeds);
              }}
            >
              <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={styles.title}>{`${ordinalSuffix(index + 1)} Traveler`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PersonalInformationForm
                  key={index}
                  personalInformation={personalInformation}
                  personalInformationFormOnChange={(newPersonalInformation: Model.PersonalInformation) =>
                    personalInformationFormOnChange(index, newPersonalInformation)
                  }
                  otherEmails={personalInformations.filter((_, j) => index !== j).map((x) => x.emailAddress)}
                  isContinueClicked={isContinueClicked}
                  invalidInputIndex={expandeds[index].invalidInputIndex}
                />
              </AccordionDetails>
            </Accordion>
            {validates[index] && !validates[index].some((x) => x.isValid === false) ? (
              <Icon name="DONE" className={styles.done} />
            ) : (
              <Icon name="UNDONE" className={styles.undone} />
            )}
          </div>
        );
      })}

      <hr />
      <div className={`${styles.stepButtonsDiv} row`}>
        <div className="col col12 col6-m">
          <Button fluid label="Back" type="secondary" onClick={backButtonClick} />
        </div>
        <div className="col col12 col6-m">
          <Button
            fluid
            label="Continue"
            onClick={() => {
              setIsContinueClicked(true);
              const invalidAccordionIndex = validates.findIndex((validate) => validate.some((x) => x.isValid === false));
              if (invalidAccordionIndex > -1) {
                const invalidInputIndex = validates[invalidAccordionIndex].findIndex((v) => v.isValid === false);
                const newExpandeds = Array.from({ length: adult }, () => ({ expand: false, invalidInputIndex: -1 }));
                newExpandeds[invalidAccordionIndex].expand = true;
                newExpandeds[invalidAccordionIndex].invalidInputIndex = invalidInputIndex;
                setExpandeds(newExpandeds);
              } else {
                continueButtonClick();
              }
            }}
            /* disabled={validates.filter((validate) => validate.some((x) => x.isValid === false)).length > 0} */
          />
        </div>
      </div>
    </>
  );
};
