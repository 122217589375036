import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Model, { helper } from '@tripian/model';
import moment from 'moment';
import { initialValidate, validatePersonalInformation } from '../../../helper';
import { airlineList, countryListAllIsoData } from '../../../constant';
import { RSelect } from '../../../../RSelect/RSelect';
import { DatePicker } from '../../../../DatePicker/DatePicker';
import { ErrorMessage } from '../../../../Base/ErrorMessage/ErrorMessage';
import { InputLabel } from '../../../../Base/InputLabel/InputLabel';
import { Select } from '../../../../Base/Select/Select';
import { Validate } from '../../../../../model/Validate';
import { Input } from '../../../../Base/Input/Input';
import PhoneInput from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js/core';
import 'react-phone-number-input/style.css';
import styles from './PersonalInformationForm.module.scss';

type Props = {
  personalInformation: Model.PersonalInformation;
  personalInformationFormOnChange: (newPersonalInformation: Model.PersonalInformation) => void;
  otherEmails: string[];
  isContinueClicked: boolean;
  invalidInputIndex: number;
};

export const PersonalInformationForm: React.FC<Props> = ({
  personalInformation,
  personalInformationFormOnChange,
  otherEmails,
  isContinueClicked,
  invalidInputIndex,
}) => {
  const [validate, setValidate] = useState<Validate[]>([
    initialValidate,
    initialValidate,
    initialValidate,
    initialValidate,
    initialValidate,
    initialValidate,
    initialValidate,
    initialValidate,
    initialValidate,
    initialValidate,
    initialValidate,
    initialValidate,
  ]);
  const [countryCode, setCountryCode] = useState<string>();

  const itemsRef = useRef<(HTMLDivElement | null)[]>(Array.from({ length: validate.length }, () => null));

  useEffect(() => {
    if (invalidInputIndex > -1) {
      itemsRef.current[invalidInputIndex]?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [invalidInputIndex, validate]);

  useEffect(() => {
    const validate = validatePersonalInformation(personalInformation, otherEmails);
    setValidate(validate);
  }, [otherEmails, personalInformation]);

  return (
    <div>
      <InputLabel text="Title" customClassName={`${styles.title} mt3`} />{' '}
      {!validate[0].isValid && isContinueClicked && validate[0].message && (
        <>
          <div ref={(el) => (itemsRef.current[0] = el)} />
          <ErrorMessage message={validate[0].message} />
        </>
      )}
      <Select
        id="title"
        name="title"
        options={['Mr', 'Mrs', 'Ms']}
        defaultEmpty
        selectedValue={personalInformation.title}
        onChange={(event) => personalInformationFormOnChange({ ...personalInformation, title: event.target.value as 'Mr' | 'Mrs' | 'Ms' })}
        size="large"
        customClassName={styles.applicationSelect}
      />
      <hr />
      <InputLabel text="First Name" customClassName={`${styles.title} mt3`} />{' '}
      {!validate[1].isValid && isContinueClicked && validate[1].message && (
        <>
          <div ref={(el) => (itemsRef.current[1] = el)} />
          <ErrorMessage message={validate[1].message} />
        </>
      )}
      <Input
        type="text"
        name="firstName"
        size="large"
        value={personalInformation.firstName}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          personalInformationFormOnChange({ ...personalInformation, firstName: event.target.value.trimLeft() })
        }
        customClassName="mb4"
        placeholder="Write here"
      />
      <hr />
      <InputLabel text="Last Name" customClassName={`${styles.title} mt3`} />
      {!validate[2].isValid && isContinueClicked && validate[2].message && (
        <>
          <div ref={(el) => (itemsRef.current[2] = el)} />
          <ErrorMessage message={validate[2].message} />
        </>
      )}
      <Input
        type="text"
        name="lasttName"
        size="large"
        value={personalInformation.lastName}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          personalInformationFormOnChange({ ...personalInformation, lastName: event.target.value.trimLeft() })
        }
        customClassName="mb4"
        placeholder="Write here"
      />
      <hr />
      <InputLabel text="Arrival Date" customClassName={`${styles.title} mt3`} />
      {!validate[3].isValid && isContinueClicked && validate[3].message && (
        <>
          <div ref={(el) => (itemsRef.current[3] = el)} />
          <ErrorMessage message={validate[3].message} />
        </>
      )}
      <DatePicker
        // label="Arrival Date"
        typeable={false}
        value={moment(personalInformation.arrivalDate)}
        minDate={moment()}
        onChange={(event: moment.Moment | null) => {
          if (event) {
            const date = event.format();
            if (moment(personalInformation.departureDate).diff(moment(date), 'days', false) < 4) {
              personalInformationFormOnChange({ ...personalInformation, arrivalDate: date, departureDate: '' });
            } else {
              personalInformationFormOnChange({ ...personalInformation, arrivalDate: moment(date).format('YYYY-MM-DD') });
            }
          }
        }}
        className="w-full"
      />
      <hr />
      <InputLabel text="Departure Date" customClassName={`${styles.title} mt3`} />
      {!validate[4].isValid && isContinueClicked && validate[4].message && (
        <>
          <div ref={(el) => (itemsRef.current[4] = el)} />
          <ErrorMessage message={validate[4].message} />
        </>
      )}
      <DatePicker
        // label="Departure Date"
        typeable={false}
        value={moment(personalInformation.departureDate)}
        minDate={moment(personalInformation.arrivalDate).add(4, 'days')}
        onChange={(event: moment.Moment | null) => {
          if (event) {
            const date = event.format();
            if (moment(date).diff(moment(personalInformation.arrivalDate), 'days') > 3) {
              personalInformationFormOnChange({ ...personalInformation, departureDate: moment(date).format('YYYY-MM-DD') });
            }
          }
        }}
        disabled={personalInformation.arrivalDate === ''}
        className="w-full"
      />
      <hr />
      <InputLabel text="Arriving From" customClassName={`${styles.title} mt3`} />
      {!validate[5].isValid && isContinueClicked && validate[5].message && (
        <>
          <div ref={(el) => (itemsRef.current[5] = el)} />
          <ErrorMessage message={validate[5].message} />
        </>
      )}
      <RSelect
        options={countryListAllIsoData
          .sort((a, b) => helper.compareStringForSort(a.name, b.name))
          .map((country) => ({
            id: country.id,
            label: country.name,
            payload: country,
            isSelected: personalInformation.departureDestination === country.name,
          }))}
        defaultOptionId={countryListAllIsoData.find((c) => c.name === personalInformation.departureDestination)?.id}
        placeHolder="Please select"
        onSelectedChanged={(selectedOption) =>
          personalInformationFormOnChange({ ...personalInformation, departureDestination: selectedOption.label })
        }
      />
      <hr />
      <InputLabel text="Airline" customClassName={`${styles.title} mt3`} />
      {!validate[6].isValid && isContinueClicked && validate[6].message && (
        <>
          <div ref={(el) => (itemsRef.current[6] = el)} />
          <ErrorMessage message={validate[6].message} />
        </>
      )}
      <RSelect
        options={airlineList
          .sort((a, b) => helper.compareStringForSort(a.name, b.name))
          .map((airline) => ({
            id: airline.id,
            label: airline.name,
            payload: airline,
            isSelected: personalInformation.airline === airline.name,
          }))}
        defaultOptionId={airlineList.find((a) => a.name === personalInformation.airline)?.id}
        placeHolder="Please select"
        onSelectedChanged={(selectedOption) => personalInformationFormOnChange({ ...personalInformation, airline: selectedOption.label })}
      />
      <hr />
      <InputLabel text="Flight Number" customClassName={`${styles.title} mt3`} />
      {!validate[7].isValid && isContinueClicked && validate[7].message && (
        <>
          <div ref={(el) => (itemsRef.current[7] = el)} />
          <ErrorMessage message={validate[7].message} />
        </>
      )}
      <Input
        type="text"
        name="flightNumber"
        size="large"
        value={personalInformation.flightNumber}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          personalInformationFormOnChange({ ...personalInformation, flightNumber: event.target.value })
        }
        customClassName="mb4"
        placeholder="Write here"
      />
      <hr />
      <InputLabel text="Date of Birth" customClassName={`${styles.title} mt3`} />
      {!validate[8].isValid && isContinueClicked && validate[8].message && (
        <>
          <div ref={(el) => (itemsRef.current[8] = el)} />
          <ErrorMessage message={validate[8].message} />
        </>
      )}
      <DatePicker
        // label="Date of Birth"
        typeable={false}
        value={moment(personalInformation.dateOfBirth)}
        onChange={(event: moment.Moment | null) => {
          if (event) {
            const datetime = event.format();
            personalInformationFormOnChange({
              ...personalInformation,
              dateOfBirth: datetime,
            });
          }
        }}
        className="w-full"
        maxDate={moment(moment().subtract(1, 'years'))}
      />
      <hr />
      <InputLabel text="Email Address" customClassName={`${styles.title} mt3`} />
      {!validate[9].isValid && isContinueClicked && validate[9].message && (
        <>
          <div ref={(el) => (itemsRef.current[9] = el)} />
          <ErrorMessage message={validate[9].message} />
        </>
      )}
      <Input
        type="text"
        name="email"
        size="large"
        value={personalInformation.emailAddress}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          personalInformationFormOnChange({ ...personalInformation, emailAddress: event.target.value.trim() })
        }
        customClassName="mb4"
        placeholder="Write here"
      />
      <hr />
      <InputLabel text="Phone Number" customClassName={`${styles.title} mt3`} />
      {!validate[10].isValid && isContinueClicked && validate[10].message && (
        <>
          <div ref={(el) => (itemsRef.current[10] = el)} />
          <ErrorMessage message={validate[10].message} />
        </>
      )}
      <PhoneInput
        placeholder="Write here"
        countries={countryListAllIsoData.map((c) => c.code) as CountryCode[]}
        defaultCountry={countryListAllIsoData.find((c) => c.name === personalInformation.departureDestination)?.code as CountryCode}
        value={personalInformation.phoneNumber}
        initialValueFormat="national"
        international
        readOnly={countryCode === undefined}
        countryCallingCodeEditable={false}
        className={`${styles.phoneInput} ${!countryCode && styles.notAllowed}`}
        onCountryChange={(country) => setCountryCode(country)}
        onChange={(value: string) => personalInformationFormOnChange({ ...personalInformation, phoneNumber: value })}
      />
      <hr />
    </div>
  );
};
