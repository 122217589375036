import { api } from '@tripian/core';
import Model from '@tripian/model';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { ordinalSuffix } from '../../../components/CouponApplicationForm/helper';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';
import { warningMessage, successMessage, errorMessage } from '../../../helper/toastr';
import { RSelect } from '../../../components/RSelect/RSelect';
import { APPLICATIONS } from '../../../const/ROUTER_PATH_TITLE';
import { Modal } from '../../../components/Base/Modal/Modal';
import { TextArea } from '../../../components/Base/TextArea/TextArea';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { Button } from '../../../components/Base/Button/Button';
import NumberInput from '../../../components/Base/NumberInput/NumberInput';
import styles from './ApplicationDetailsPage.module.scss';

export const ApplicationDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  //States
  const [application, setApplication] = useState<Model.CouponApplication>();
  const [campaigns, setCampaigns] = useState<Model.Campaign[]>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState<number>();
  const [maxAmount, setMaxAmount] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);

  const [approveModalOpenTravelerId, setApproveModalOpenTravelerId] = useState<number>(0);
  const [rejectModalOpenTravelerId, setRejectModalOpenTravelerId] = useState<number>(0);
  const [rejectionReason, setRejectionReason] = useState<string>('');
  const [bookingDate, setBookingDate] = useState<string>();

  useEffect(() => {
    api
      .businessCampaigns(1, 1000)
      .then((campaigns) => {
        setCampaigns(campaigns.data);
        setSelectedCampaignId(campaigns.data[0].id);
      })
      .then(() => {
        return api.businessCouponApplication(Number(id)).then((application) => setApplication(application));
      })
      .finally(() => setLoading(false));
  }, [id]);

  const approveOnclick = (travelerId: number) => {
    if (application?.id === undefined) {
      console.error('Application id is undefined');
    } else if (selectedCampaignId === undefined) {
      console.error('Please select a campaign first');
    } else {
      setApproveModalOpenTravelerId(travelerId);
    }
  };
  const approveOnSubmit = (travelerId: number, bookingDate: string) => {
    setApproveModalOpenTravelerId(0);
    if (application?.id === undefined) {
      console.error('Application id is undefined');
    } else if (selectedCampaignId === undefined) {
      console.error('Please select a campaign first');
    } else {
      setLoading(true);
      api
        .businessCouponApplicationUserApprove(application.id, travelerId, selectedCampaignId, bookingDate, maxAmount)
        .then((application) => {
          if (application) {
            setLoading(false);
            setApplication(application);
            successMessage('Application successfully approved');
            setBookingDate(undefined);
          }
        })
        .catch((err) => {
          errorMessage(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const rejectOnClick = (travelerId: number) => {
    if (application?.id === undefined) {
      console.error('Application id is undefined');
    } else if (selectedCampaignId === undefined) {
      console.error('Please select a campaign first');
    } else {
      setRejectModalOpenTravelerId(travelerId);
    }
  };
  const rejectOnSubmit = (travelerId: number, bookingDate: string) => {
    setRejectModalOpenTravelerId(0);
    if (application?.id === undefined) {
      console.error('Application id is undefined');
    } else {
      setLoading(true);
      api
        .businessCouponApplicationUserReject(application.id, travelerId, selectedCampaignId ?? 0, bookingDate, rejectionReason)
        .then((application) => {
          if (application) {
            setLoading(false);
            setApplication(application);
            warningMessage('Application rejected');
            setBookingDate(undefined);
            setRejectionReason('');
          }
        })
        .catch((err) => {
          errorMessage(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const selectedCampaign = useMemo(() => campaigns.find((campaign) => campaign.id === selectedCampaignId), [campaigns, selectedCampaignId]);

  return (
    <FullCenter centerY={false} customClassName={styles.fullCenter} loading={loading}>
      <Modal
        show={approveModalOpenTravelerId > 0}
        message="Please select booking date before confirming approval"
        onConfirm={() => approveOnSubmit(approveModalOpenTravelerId, bookingDate ?? '')}
        confirmText={'Confirm'}
        onClose={() => {
          setApproveModalOpenTravelerId(0);
          setBookingDate(undefined);
          setBookingDate(undefined);
        }}
      >
        <div className={styles.approveModalContent}>
          <div className={styles.bookingDatePicker}>
            <DatePicker
              label="Booking Date"
              value={bookingDate ? moment(bookingDate) : null}
              sx={{
                '& .MuiInputLabel-root': {
                  '&.Mui-focused': {
                    borderColor: 'var(--text-primary-color)',
                    color: 'var(--text-primary-color) !important',
                  },
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'var(--text-primary-color)',
                    color: 'var(--text-primary-color) !important',
                  },
                },
                label: { color: 'var(--text-primary-color)' },
                svg: { color: 'var(--text-primary-color)' },
                input: { color: 'var(--text-primary-color)' },
              }}
              onChange={(value: moment.Moment | null) => {
                if (value) {
                  const date = value.format('YYYY-MM-DD');
                  setBookingDate(date);
                }
              }}
            />
          </div>
          {selectedCampaign?.isAmountMax && (
            <div className={styles.maxAmountContent}>
              <div className={styles.maxAmountTitle}>
                Max amount: {selectedCampaign.amount} {selectedCampaign.currency}
              </div>
              <NumberInput
                customClassName={styles.maxAmountInput}
                name="maxAmount"
                placeholder="Enter amount"
                minValue={0}
                value={maxAmount}
                maxValue={selectedCampaign.amount}
                onChange={(value) => setMaxAmount(value)}
              />
            </div>
          )}
        </div>
      </Modal>

      <Modal
        show={rejectModalOpenTravelerId > 0}
        message="Please state the reason why you rejected the application"
        onConfirm={() => rejectOnSubmit(rejectModalOpenTravelerId, bookingDate ?? '')}
        confirmText={'Confirm'}
        onClose={() => {
          setRejectModalOpenTravelerId(0);
          setBookingDate(undefined);
          setRejectionReason('');
          setBookingDate(undefined);
        }}
        disabledConfirmButton={rejectionReason.trim() === ''}
      >
        <div className={styles.bookingDatePicker}>
          <DatePicker
            label="Booking Date"
            value={bookingDate ? moment(bookingDate) : null}
            onChange={(value: moment.Moment | null) => {
              if (value) {
                const date = value.format('YYYY-MM-DD');
                setBookingDate(date);
              }
            }}
          />
        </div>
        <TextArea
          rows={3}
          customClassName={styles.rejectReason}
          charLimit={1000}
          value={rejectionReason}
          onChange={(text) => setRejectionReason(text.target.value)}
          placeholder="Type Here..."
          name={'Rejectionreason'}
        />
      </Modal>

      <>
        <div className={styles.detailsWrapper}>
          {application !== undefined ? (
            <>
              <div className={styles.detailsHeader}>
                {application?.personalInformations[0].firstName} {application?.personalInformations[0].lastName}
                {application?.travelInformation.adults === 1 ? null : "'s group"} staying in {application?.travelInformation.nameOfProperty}
              </div>

              <div className={styles.campaignDropdownContent}>
                <div className={styles.campaignHeader}>Please Select a Campaign</div>
                <div className={styles.campaignDropdown}></div>

                <RSelect
                  options={campaigns.map((campaign) => ({
                    id: campaign.id,
                    label: campaign.title,
                    payload: campaign,
                    isSelected: selectedCampaignId === campaign.id,
                  }))}
                  defaultOptionId={selectedCampaignId}
                  onSelectedChanged={(selectedOption) => setSelectedCampaignId(selectedOption.id)}
                />
              </div>
            </>
          ) : null}

          <div className={styles.tableWrapper}>
            {application?.personalInformations.map((personalInformation, index) => {
              return (
                <div key={index} className={styles.accordionRow}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography className={styles.travelerCount}>{`${ordinalSuffix(index + 1)} Traveler`}</Typography>
                      {personalInformation.status === 1 ? (
                        <div className={styles.statusApproved}>Approved on {moment(personalInformation.issueDate).format('DD.MM.YYYY hh:mm')}</div>
                      ) : personalInformation.status === -1 ? (
                        <>
                          <div className={styles.statusRejected}>Rejected on {moment(personalInformation.issueDate).format('DD.MM.YYYY hh:mm')}</div>
                        </>
                      ) : null}
                    </AccordionSummary>
                    <AccordionDetails>
                      <li>
                        Full Name: {personalInformation.title}. {personalInformation.firstName} {personalInformation.lastName}
                      </li>
                      <li>Arrival Date: {moment(personalInformation.arrivalDate).format('MMM D YYYY')}</li>
                      <li>Departure Date: {moment(personalInformation.departureDate).format('MMM D YYYY')}</li>
                      <li>Arriving From: {personalInformation.departureDestination}</li>
                      <li>Airline: {personalInformation.airline}</li>
                      <li>Flight Number: {personalInformation.flightNumber}</li>
                      <li>Date of Birth: {moment(personalInformation.dateOfBirth).format('MMM D YYYY')}</li>
                      <li>E-mail: {personalInformation.emailAddress}</li>
                      <li>Phone Number: {personalInformation.phoneNumber}</li>

                      {personalInformation.status === 0 ? (
                        <div className={styles.buttonsWrapper}>
                          {selectedCampaignId === undefined ? (
                            <div className={styles.approveButtonDisabled}>Select a campaign to approve</div>
                          ) : (
                            <div className={styles.approveButton} onClick={() => approveOnclick(personalInformation.id)}>
                              Approve credit {!selectedCampaign?.isAmountMax && `for ${selectedCampaign?.amount}$`}
                            </div>
                          )}
                          <div className={styles.rejectButton} onClick={() => rejectOnClick(personalInformation.id)}>
                            Reject
                          </div>
                        </div>
                      ) : null}
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </div>
          {!loading && <Button onClick={() => history.push(APPLICATIONS.PATH)} label="Back" type="secondary" customClassName={styles.backButton} />}
        </div>
      </>
    </FullCenter>
  );
};
