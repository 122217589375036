import { useCallback, useEffect, useState } from 'react';
import { api } from '@tripian/core';
import Model from '@tripian/model';

export type OfferStatusType = 'Active' | 'Inactive' | 'Archived';

export const useOffers = (offerStatusType: OfferStatusType, active: boolean) => {
  const [statuses] = useState<Model.OFFER_STATUS[]>(
    offerStatusType === 'Active'
      ? [Model.OFFER_STATUS.ACTIVE]
      : offerStatusType === 'Inactive'
      ? [Model.OFFER_STATUS.EXPIRED, Model.OFFER_STATUS.INACTIVE]
      : [Model.OFFER_STATUS.ARCHIVE],
  );

  const [firstShown, setFirstShown] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [offers, setOffers] = useState<Model.Offer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);

  useEffect(() => {
    setFirstShown(true);
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (firstShown === true && active === true && page === 1 && statuses.length > 0) {
      // console.log('useOffers.useEffect', firstShown, active, page, statuses);

      setLoading(true);

      api
        .businessOffers(statuses, page)
        .then((os: Model.DataPayload<Model.Offer[]>) => {
          if (!unmounted) {
            setFirstShown(false);
            setOffers((prev) => [...prev, ...os.data]);
            setPage((prev) => prev + 1);
            if (20 <= os.data.length) setLoadMoreActive(true);
            else setLoadMoreActive(false);
          }
        })
        .catch((er) => {
          console.error(er);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      unmounted = true;
    };
  }, [active, firstShown, page, statuses]);

  const onLoadMore = useCallback(() => {
    if (loadMoreActive) {
      setLoading(true);

      api
        .businessOffers(statuses, page)
        .then((os: Model.DataPayload<Model.Offer[]>) => {
          setPage((prev) => prev + 1);

          setOffers((prev) => [...prev, ...os.data]);
          console.log('os.length', os.data.length);
          if (20 <= os.data.length) setLoadMoreActive(true);
          else setLoadMoreActive(false);
        })
        .catch((er) => {
          console.error(er);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loadMoreActive, page, statuses]);

  const reset = useCallback(() => {
    // console.log(offerStatusType, 'reset all');
    setFirstShown(true);
    setPage(1);
    setOffers([]);
    // setLoading(true);
    setLoadMoreActive(false);
  }, []);

  return { loading, offers, loadMoreActive, onLoadMore, reset };
};
