import { TableCell, TableRow } from '@mui/material';
import Model from '@tripian/model';
import moment from 'moment';
import { DatePicker } from '../../../../../components/DatePicker/DatePicker';
import styles from './ReportTableFilter.module.scss';

type Props = {
  filters: Partial<Model.CampaignReportRequest>;
  setFilters: (newFilter: Partial<Model.CampaignReportRequest>) => void;
};
export const ReportTableFilter: React.FC<Props> = ({ filters, setFilters }) => {
  const onFreetextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'businessName') setFilters({ ...filters, businessName: e.target.value });
    else if (e.target.name === 'offerTitle') setFilters({ ...filters, offerTitle: e.target.value });
    else if (e.target.name === 'fullName') setFilters({ ...filters, fullName: e.target.value });
    else if (e.target.name === 'amount') {
      if (e.target.value === '') {
        setFilters({ ...filters, amount: undefined });
      } else {
        setFilters({ ...filters, amount: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) });
      }
    }
  };

  /**
   * Business name => freetext
   * Offer title => freetext
   * fullname => freetext
   * arrival date => calender (exact date)
   * departure date => calender (exact date)
   * claim date => calender (exact date)
   * redeem date => calender (same date)
   * amount => number input (exact amount)
   * reimbursed date => calender (exact date) or empty (sadece Pay default)
   */
  return (
    <TableRow className={styles.filterRow}>
      <TableCell className={styles.tableHeader}>Filters:</TableCell>
      <TableCell>
        <input
          className={styles.customInput}
          name="businessName"
          placeholder="Business Name"
          type="text"
          value={filters.businessName}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <input
          className={styles.customInput}
          name="offerTitle"
          placeholder="Offer Title"
          type="text"
          value={filters.offerTitle}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <input
          className={styles.customInput}
          name="fullName"
          placeholder="Full Name"
          type="text"
          value={filters.fullName}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Arrival Date"
          value={filters.arrivalDate ? moment(filters.arrivalDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, arrivalDate: date });
            } else {
              setFilters({ ...filters, arrivalDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Departure Date"
          value={filters.departureDate ? moment(filters.departureDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, departureDate: date });
            } else {
              setFilters({ ...filters, departureDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Claim Date"
          value={filters.claimDate ? moment(filters.claimDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, claimDate: date });
            } else {
              setFilters({ ...filters, claimDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Redeem Date"
          value={filters.redeemDate ? moment(filters.redeemDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, redeemDate: date });
            } else {
              setFilters({ ...filters, redeemDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
      <TableCell>
        <input
          className={`${styles.customInput} ${styles.amountInput}`}
          name="amount"
          placeholder="Amount"
          type="text"
          value={filters.amount}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Reimbursed Date"
          value={filters.reimbursedDate ? moment(filters.reimbursedDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, reimbursedDate: date });
            } else {
              setFilters({ ...filters, reimbursedDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
    </TableRow>
  );
};
