import { CircularProgress, Paper, Table, TableContainer, TableHead, TablePagination } from '@mui/material';
import { CSVLink } from 'react-csv';
import Model from '@tripian/model';
import { ReportTableHeader } from './ReportTableHeader/ReportTableHeader';
import { ReportTableFilter } from './ReportTableFilter/ReportTableFilter';
import { memo, useEffect, useState } from 'react';
import { ReportTableBody } from './ReportTableBody/ReportTableBody';
import { Button } from '../../../../components/Base/Button/Button';
import styles from './ReportTable.module.scss';
import { useDebounce } from '../../../../hooks/useDebounce';

const initialBusinessPreRegisterReportRequest: Partial<Model.BusinessPreRegisterReportRequest> = {
  limit: 10,
  page: 1,
};

type Props = {
  loading: boolean;
  data: Model.DataPayload<Model.BusinessReport>;
  fetch: (query: Partial<Model.BusinessPreRegisterReportRequest>) => void;
  exportAllOnClick: () => void;
};

const ReportTable: React.FC<Props> = ({ loading, data, fetch, exportAllOnClick }) => {
  const [query, setQuery] = useState<Partial<Model.BusinessPreRegisterReportRequest>>(initialBusinessPreRegisterReportRequest);
  const debouncedQuery = useDebounce(query, 400);

  const handleChangeFilter = (filter: Partial<Model.BusinessPreRegisterReportRequest>) => {
    setQuery({ ...filter, page: 1 });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setQuery({ ...query, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({ ...query, page: 1, limit: +event.target.value });
  };

  useEffect(() => {
    if (debouncedQuery) fetch(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  /**
   * Order
   */
  const [orderColumnIndex, setOrderColumnIndex] = useState<number>(1);
  const [orderAsc, setOrderAsc] = useState<boolean>(true);

  return (
    <>
      <Paper component={Paper}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <ReportTableHeader
                orderColumnIndex={orderColumnIndex}
                setOrderColumnIndex={setOrderColumnIndex}
                orderAsc={orderAsc}
                setOrderAsc={setOrderAsc}
                query={query}
                setQuery={handleChangeFilter}
              />
              <ReportTableFilter filters={query} setFilters={handleChangeFilter} />
            </TableHead>

            {loading ? (
              <div className={styles.reportLoadingContent}>
                <CircularProgress className={styles.reportLoading} color="primary" size={28} />
              </div>
            ) : (
              <ReportTableBody data={data.data} />
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.pagination?.total ?? 10}
          rowsPerPage={data.pagination?.perPage ?? 10}
          page={(data.pagination?.currentPage ?? 1) - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {data.data.length > 0 && (
        <div className={`col col12 ${styles.buttonsGroupDiv}`}>
          <CSVLink data={data.data} filename="business-pre-register-report.csv" className={styles.exportCsv}>
            Export Current Page
          </CSVLink>
          <Button onClick={exportAllOnClick} label="Export" />
        </div>
      )}
    </>
  );
};

export default memo(ReportTable);
