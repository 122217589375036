import { TableCell, TableRow } from '@mui/material';
import Model from '@tripian/model';
import moment from 'moment';
import { DatePicker } from '../../../../../components/DatePicker/DatePicker';
import styles from './ReportTableFilter.module.scss';

type Props = {
  filters: Partial<Model.BusinessOfferReportItem>;
  setFilters: (newFilter: Partial<Model.BusinessOfferReportItem>) => void;
};
export const ReportTableFilter: React.FC<Props> = ({ filters, setFilters }) => {
  const onFreetextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'businessName') setFilters({ ...filters, businessName: e.target.value });
    else if (e.target.name === 'offerTitle') setFilters({ ...filters, offerTitle: e.target.value });
    else if (e.target.name === 'optinCount') {
      if (e.target.value === '') {
        setFilters({ ...filters, optinCount: undefined });
      } else {
        setFilters({ ...filters, optinCount: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) });
      }
    }
  };

  return (
    <TableRow className={styles.reportFilterRow}>
      <TableCell className={styles.reportTableHeader}>Filters:</TableCell>
      <TableCell>
        <input
          className={styles.reportCustomInput}
          name="businessName"
          placeholder="Business Name"
          type="text"
          value={filters.businessName}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <input
          className={styles.reportCustomInput}
          name="offerTitle"
          placeholder="Offer Title"
          type="text"
          value={filters.offerTitle}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <input
          className={styles.reportCustomInput}
          name="optinCount"
          placeholder="Opt In Count"
          type="text"
          value={filters.optinCount}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="Start Date"
          value={filters.startDate ? moment(filters.startDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, startDate: date });
            } else {
              setFilters({ ...filters, startDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
      <TableCell>
        <DatePicker
          className={styles.filterRowDatePicker}
          label="End Date"
          value={filters.endDate ? moment(filters.endDate) : null}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setFilters({ ...filters, endDate: date });
            } else {
              setFilters({ ...filters, endDate: undefined });
            }
          }}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </TableCell>
    </TableRow>
  );
};
