import React, { useState } from 'react';
import Model from '@tripian/model';
import { api } from '@tripian/core';
import { CouponApplicationForm } from '../../../components/CouponApplicationForm/CouponApplicationForm';
import { initialCouponApplicationFormData } from '../../../helper/constants';
import { Validate } from '../../../model/Validate';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
// import { successMessage } from '../../helper/toastr';
// import { InputLabel } from '../../../components/Base/InputLabel/InputLabel';
import styles from './CouponApplicationFormPage.module.scss';

export const CouponApplicationFormPage = () => {
  const [formData, setFormData] = useState<Model.CouponApplicationRequest>(initialCouponApplicationFormData);
  const [loading, setLoading] = useState<boolean>(false);
  const [validate, setValidate] = useState<Validate>({ isValid: true, message: undefined });
  const [success, setSuccess] = useState<boolean>(false);

  const uploadImage = (imageFile: string): Promise<{ url: string }> => {
    return api.businessCouponApplicationFileUpload(imageFile);
  };

  const submitForm = () => {
    api
      .businessCouponApplicationAdd(formData)
      .then((id: number) => {
        setSuccess(true);
        // successMessage(`Your record number ${id} has been successfully created.`);
      })
      .catch((err) => {
        setLoading(false);
        setValidate({ ...validate, message: err });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FullCenter loading={loading} centerY={false} maxWidth={1088} loaderFixed={true}>
      <div className={styles.form}>
        {success ? (
          <div className={styles.bg}>
            <div className={styles.confirmationMessage}>
              <div className={styles.mainText}>Your Feels Like Summer Application details have been submitted for verification.</div>
              <div className={styles.subText}>Look out for emails from BookBarbados Island Expert within the next 24-48 hours.</div>
              <p>(If this is the first time we are emailing you, keep an eye on your spam folder as well!)</p>
            </div>
          </div>
        ) : (
          <CouponApplicationForm
            formData={formData}
            formCallBack={() => {
              setLoading(true);
              submitForm();
            }}
            formOnChange={(formData) => setFormData(formData)}
            uploadImage={uploadImage}
          />
        )}
      </div>
    </FullCenter>
  );
};
