import { Paper, Table, TableContainer, TableHead, TablePagination } from '@mui/material';
import Model from '@tripian/model';
import { ReportTableHeader } from './ReportTableHeader/ReportTableHeader';
import { ReportTableFilter } from './ReportTableFilter/ReportTableFilter';
import { useMemo, useState } from 'react';
import { ReportTableBody } from './ReportTableBody/ReportTableBody';

const compareString = (asc: boolean, a?: string | null, b?: string | null) => {
  if (a === null || a === undefined) return asc ? -1 : 1;
  if (b === null || b === undefined) return asc ? 1 : -1;
  if (a < b) return asc ? -1 : 1;
  if (a > b) return asc ? 1 : -1;
  return 0;
};

type Props = {
  fullRows: Model.BusinessOfferReport;
};

export const ReportTable: React.FC<Props> = ({ fullRows }) => {
  /**
   * Filter
   */
  const [filters, setFilters] = useState<Partial<Model.BusinessOfferReportItem>>({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredRows = useMemo(() => {
    let filteredData = [...fullRows];
    if (filters.businessName !== undefined && filters.businessName !== '')
      filteredData = filteredData.filter(
        (x) => filters.businessName !== undefined && x.businessName.toLowerCase().includes(filters.businessName.toLowerCase()),
      );

    if (filters.offerTitle !== undefined && filters.offerTitle !== '')
      filteredData = filteredData.filter(
        (x) => filters.offerTitle !== undefined && x.offerTitle.toLowerCase().includes(filters.offerTitle.toLowerCase()),
      );

    if (filters.optinCount !== undefined && filters.optinCount !== '')
      filteredData = filteredData.filter((x) => filters.optinCount !== undefined && x.optinCount === filters.optinCount);

    if (filters.startDate !== undefined && filters.startDate !== '') {
      filteredData = filteredData.filter((x) => filters.startDate !== undefined && x.startDate === filters.startDate);
    }

    if (filters.endDate !== undefined && filters.endDate !== '')
      filteredData = filteredData.filter((x) => filters.endDate !== undefined && x.endDate === filters.endDate);

    return filteredData;
  }, [filters.businessName, filters.endDate, filters.offerTitle, filters.optinCount, filters.startDate, fullRows]);

  /**
   * Order
   */
  const [orderColumnIndex, setOrderColumnIndex] = useState<number>(-1);
  const [orderAsc, setOrderAsc] = useState<boolean>(true);
  const displayRows = useMemo(() => {
    return [...filteredRows].sort((a, b) => {
      if (orderColumnIndex === 0) return compareString(orderAsc, b.businessName, a.businessName);
      else if (orderColumnIndex === 1) return compareString(orderAsc, b.offerTitle, a.offerTitle);
      else if (orderColumnIndex === 2) return orderAsc ? Number(a.optinCount) - Number(b.optinCount) : Number(b.optinCount) - Number(a.optinCount);
      else if (orderColumnIndex === 3) return compareString(orderAsc, b.startDate, a.startDate);
      else if (orderColumnIndex === 4) return compareString(orderAsc, b.endDate, a.endDate);

      return orderAsc ? a.id - b.id : b.id - a.id;
    });
  }, [filteredRows, orderAsc, orderColumnIndex]);

  return (
    <>
      <Paper component={Paper}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <ReportTableHeader
                orderColumnIndex={orderColumnIndex}
                setOrderColumnIndex={setOrderColumnIndex}
                orderAsc={orderAsc}
                setOrderAsc={setOrderAsc}
              />
              <ReportTableFilter filters={filters} setFilters={setFilters} />
            </TableHead>
            <ReportTableBody displayRows={displayRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage)} />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={displayRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};
