import { useParams } from 'react-router';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { ReportTable } from './ReportTable/ReportTable';
import { useCampaignCustomerReport } from './useCampaignCustomerReport';
import classes from './CampaignCustomerReportPage.module.scss';

export const CampaignCustomerReportPage = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, data, fetchCampaignCustomerReport, onExportAll } = useCampaignCustomerReport(Number(id));

  return (
    <FullCenter centerY={false} customClassName={classes.reportContainer}>
      <ReportTable data={data} fetch={fetchCampaignCustomerReport} loading={loading} exportAllOnClick={onExportAll} />
    </FullCenter>
  );
};
