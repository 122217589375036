import { TableCell, TableRow } from '@mui/material';
import Model from '@tripian/model';
import styles from './ReportTableFilter.module.scss';

type Props = {
  filters: Partial<Model.BusinessPreRegisterReportRequest>;
  setFilters: (newFilter: Partial<Model.BusinessPreRegisterReportRequest>) => void;
};
export const ReportTableFilter: React.FC<Props> = ({ filters, setFilters }) => {
  const onFreetextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'businessUserEmail') setFilters({ ...filters, email: e.target.value });
    else if (e.target.name === 'businessUserFullname') setFilters({ ...filters, fullName: e.target.value });
    else if (e.target.name === 'businessPoiName') setFilters({ ...filters, businessName: e.target.value });
  };

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.name === 'status')
      setFilters({
        ...filters,
        status: e.target.value === 'All' ? undefined : (e.target.value as 'Pre Registered' | 'Waiting For Pre Registered' | 'Rejected'),
      });
  };

  return (
    <TableRow className={styles.reportFilterRow}>
      <TableCell className={styles.reportTableHeader}>Filters:</TableCell>
      <TableCell>
        <input
          className={styles.reportCustomInput}
          name="businessUserEmail"
          placeholder="Email"
          type="text"
          value={filters.email}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <input
          className={styles.reportCustomInput}
          name="businessUserFullname"
          placeholder="Fullname"
          type="text"
          value={filters.fullName}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <input
          className={styles.reportCustomInput}
          name="businessPoiName"
          placeholder="Business Name"
          type="text"
          value={filters.businessName}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <select className={styles.reportSelectStatus} name="status" value={filters.status} onChange={onSelectChange}>
          <option value="All">All</option>
          <option value="Pre Registered">Pre Registered</option>
          <option value="Waiting For Pre Registered">Waiting For Pre Registered</option>
          <option value="Rejected">Rejected</option>
        </select>
      </TableCell>
    </TableRow>
  );
};
