import moment from 'moment';
import { BUSINESS_PROFILE, OFFERS, USER_SETTINGS } from '../const/ROUTER_PATH_TITLE';
import Model from '@tripian/model';
import { SignUpFormData } from '../model/SignUpFormData';

interface INavItem {
  tabIndex: number;
  text: string;
  routePath: string;
}

const initialFormData: SignUpFormData = {
  email: '',
  email2: '',
  password: '',
  // password2: '',
  firstName: '',
  lastName: '',
  googlePlaceId: undefined,
  tripianPoiId: undefined,
  poi: undefined,
  cityId: undefined,
  termsOfUse: false,
};

const initialPreRegisterFormData: Model.PreRegister = {
  name: '',
  email: '',
  cityId: 0,
  businessName: '',
  googlePlaceId: '',
};

/* const montrealBounds: IBounds = {
  lat_max: 45.41008,
  lat_min: 45.72301,
  lng_max: -73.97416,
  lng_min: -73.32323,
}; */

const PECCityBounds = {
  lat_max: 43.71984,
  lat_min: 44.26372,
  lng_max: -77.70171,
  lng_min: -76.51089,
};

const initialOfferFormData: Model.OfferAddRequest = {
  cityId: 0, // TO DO
  title: '',
  offerType: Model.OFFER_TYPE.PERCENTAGE,
  currency: Model.OFFER_CURRENCY.CAD,
  threshold: 50,
  discount: 5,
  quantity: 9999,
  timeframe: {
    start: moment().format(),
    end: moment().add(1, 'days').format(),
    blackouts: [],
    recurrent: [],
  },
  imageUrl: undefined,
  productName: undefined,
  giftName: undefined,
  discountedProductCount: 1,
  productTypeId: 1,
};

const initialVoucherOfferFormData: Model.VoucherOfferAddRequest = {
  cityId: 0,
  title: '',
  offerType: Model.OFFER_TYPE.VOUCHER,
  campaignId: 0,
  discount: 5,
  quantity: 9999,
  timeframe: {
    start: moment().format(),
    end: moment().add(1, 'days').format(),
    blackouts: [],
    recurrent: [],
  },
  description: '',
  imageUrl: '',
  status: Model.OFFER_STATUS.ACTIVE,
  productTypeId: 1,
};

const initialCampaignFormData: Model.CampaignAddRequest = {
  title: '',
  category: 'Voucher',
  limited: true,
  startDate: '',
  endDate: '',
  recurrent: [0, 1, 2, 3, 4, 5, 6],
  blackouts: [],
  bonus: [],
  // currency: `${Model.OFFER_CURRENCY.BBD}$`,
  isAmountMax: false,
  amount: 1,
  maximumRecipients: 1,
  maximumOfferAmount: 1,
  description: '',
};

const initialCouponApplicationFormData: Model.CouponApplicationRequest = {
  travelInformation: {
    adults: 1,
    nameOfProperty: '',
    proofOfHolidayBookingUrl: '',
  },
  personalInformations: [],
  marketingQuestions: {
    howDidYouHear: '',
    howDidYouBookYourTrip: '' as 'Online' | 'Travel Agent/ Tour Operator' | 'Direct with hotel',
  },
};

const navItemList: Array<INavItem> = [
  { text: OFFERS.TITLE, tabIndex: 0, routePath: OFFERS.PATH },
  // { text: OFFER_CREATE.TITLE, tabIndex: 1, routePath: OFFER_CREATE.PATH },
  // { text: OFFERS_HISTORY.TITLE, tabIndex: 2, routePath: OFFERS_HISTORY.PATH },
  { text: BUSINESS_PROFILE.TITLE, tabIndex: 1, routePath: BUSINESS_PROFILE.PATH },
  { text: USER_SETTINGS.TITLE, tabIndex: 2, routePath: USER_SETTINGS.PATH },
];

const initialUserState: Model.User = {
  id: 0,
  email: '',
  firstName: '',
  lastName: '',
  userType: Model.USER_TYPE.NORMAL,
  timezone: '',
  dateOfBirth: '',
  answers: [],
  businessId: 0,

  // emailVerified: false,
  // signupDate: '',
};

const currencies: string[] = ['CAD', 'USD'];

const timeFrame = {
  today: new Date(moment().format()),
  tomorrow: new Date(moment().add(1, 'days').format()),
  aweek: new Date(moment().add(1, 'week').format()),
  amonth: new Date(moment().add(1, 'month').format()),
};

type IconName =
  | 'MENU_OFFERS'
  | 'MENU_BUSINESS'
  | 'MENU_USER'
  | 'MENU_LOGOUT'
  | 'FOOD'
  | 'CART'
  | 'MENU'
  | 'CLOSE'
  | 'VECTOR'
  | 'CLOCK'
  | 'CAR'
  | 'COMPONENT'
  | 'USER_TICK'
  | 'MENU_CARD'
  | 'EYE'
  | 'GALLERY'
  | 'COPY'
  | 'BOOK_SAVED'
  | 'TRASH'
  | 'EYE_SLASH'
  | 'CLOSE_THEME'
  | 'LOCATION'
  | 'FOOD_THEME'
  | 'DRINKS'
  | 'CALL'
  | 'HOURS'
  | 'CALL2'
  | 'LINK'
  | 'PROFILE'
  | 'PASSWORD'
  | 'DINEIN'
  | 'DOLLAR'
  | 'PERCENT'
  | 'MULTIBUY'
  | 'GIFT'
  | 'BOOK_ONLINE'
  | 'LIMITED'
  | 'UNLIMITED';

export {
  currencies,
  initialFormData,
  initialOfferFormData,
  initialVoucherOfferFormData,
  initialCampaignFormData,
  initialCouponApplicationFormData,
  /* montrealBounds, */ PECCityBounds,
  navItemList,
  initialUserState,
  timeFrame,
  initialPreRegisterFormData,
};

export type { INavItem, IconName };
