/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../context/User/UserContext';
import { api } from '@tripian/core';
import Model from '@tripian/model';
import USER_ACTION_TYPES from '../../../context/User/USER_ACTION_TYPES';
import { initialUserState } from '../../../helper/constants';
import { USER_SETTINGS } from '../../../const/ROUTER_PATH_TITLE';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { FormContainer } from '../../../components/Base/FormContainer/FormContainer';
import { Input } from '../../../components/Base/Input/Input';
import { Button } from '../../../components/Base/Button/Button';
import { successMessage } from '../../../helper/toastr';
import styles from './UserSettingsPage.module.scss';
import { InputLabel } from '../../../components/Base/InputLabel/InputLabel';
import { Icon } from '../../../components/Base/Icon/Icon';
import { passwordValidation, validateProfile } from '../../../helper/validate';
import { PasswordControl } from '../../../components/PasswordControl/PasswordControl';

export const UserSettingsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { payloadData } = useContext(UserContext);
  const [userProfileData, setUserProfileData] = useState<Model.User>(payloadData.user || initialUserState);
  const [passwordData, setPasswordData] = useState<{ password: string; password2: string }>({ password: '', password2: '' });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [focusPassword, setFocusPassword] = useState<boolean>(false);
  const { dispatch } = useContext(UserContext);

  const history = useHistory();
  document.title = USER_SETTINGS.TITLE;

  const submitProfileForm = () => {
    if (payloadData.user?.firstName === userProfileData.firstName && payloadData.user?.lastName === userProfileData.lastName) {
      successMessage('Profile Successfully Updated');
      setLoading(false);
      return Promise.resolve(true);
    }

    const userUpdateData = {
      firstName: userProfileData.firstName,
      lastName: userProfileData.lastName,
      dateOfBirth: userProfileData.dateOfBirth ? userProfileData.dateOfBirth : undefined,
      answers: [],
      password: undefined,
    };

    return api
      .userUpdate(userUpdateData)
      .then((updatedUser: Model.User) => {
        successMessage('Profile Successfully Updated');
        setErrorMessage('');
        dispatch({
          type: USER_ACTION_TYPES.SET_USER,
          payload: { user: updatedUser },
        });
        return true;
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err);
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitPasswordForm = () => {
    if (passwordData.password !== passwordData.password2) {
      setErrorMessage('Your password different from confirm password.');
      setLoading(false);
      return Promise.resolve(true);
    } else if (passwordData.password.trim() === '') {
      setLoading(false);
      return Promise.resolve(true);
    } else {
      return api
        .userUpdate({ password: passwordData.password })
        .then(() => {
          successMessage('Password Successfully Updated');
          setErrorMessage('');
          return Promise.resolve(true);
        })
        .catch((err) => {
          setErrorMessage(err);
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    switch (inputName) {
      case 'email':
        setUserProfileData((prevData) => ({ ...prevData, email: inputValue }));
        break;

      case 'password':
        setPasswordData((prevData) => ({
          ...prevData,
          password: inputValue,
        }));
        break;

      case 'password2':
        setPasswordData((prevData) => ({
          ...prevData,
          password2: inputValue,
        }));
        break;

      case 'firstName':
        setUserProfileData((prevData) => ({
          ...prevData,
          firstName: inputValue,
        }));
        break;

      case 'lastName':
        setUserProfileData((prevData) => ({
          ...prevData,
          lastName: inputValue,
        }));
        break;

      default:
        break;
    }
  };

  return (
    <FullCenter centerY={false} loading={loading} maxWidth={700} customClassName={styles.fullCenter}>
      <h4 className="center mb4">Settings</h4>
      <FormContainer customClassName={styles.container}>
        <div className="row">
          <div className={`col col12 ${styles.flex}`}>
            <Icon name="PROFILE" />
            <h6 className="pl2">Profile</h6>
          </div>
          <div className="col col12 col6-m">
            <InputLabel text="First Name" />
            <Input size="large" type="text" name="firstName" placeholder="First Name" value={userProfileData.firstName} onChange={onChangeForm} />
          </div>
          <div className="col col12 col6-m">
            <InputLabel text="Last Name" />
            <Input size="large" type="text" name="lastName" placeholder="Last Name" value={userProfileData.lastName} onChange={onChangeForm} />
          </div>
          <div className="col col12 mt5">
            <div className={`col col12 ${styles.buttonsGroupDiv}`}>
              <Button
                label="Update Profile"
                type="primary"
                disabled={!validateProfile(userProfileData.firstName, userProfileData.lastName)}
                onClick={() => {
                  setLoading(true);
                  submitProfileForm();
                }}
              />
            </div>
          </div>
          <div className={`col col12 ${styles.flex}`}>
            <Icon name="PASSWORD" />
            <h6 className="pl2">Password</h6>
          </div>
          <div className={`${styles.passwordDiv} col col12 col6-m`}>
            <InputLabel text="Password" />
            <Input
              size="large"
              type="password"
              name="password"
              placeholder="Password"
              onFocus={() => setFocusPassword(true)}
              onBlur={() => setFocusPassword(false)}
              value={passwordData.password}
              onChange={onChangeForm}
            />
            {focusPassword && <PasswordControl password={passwordData.password} />}
          </div>
          <div className="col col12 col6-m">
            <InputLabel text="Confirm Password" />
            <Input
              size="large"
              type="password"
              name="password2"
              placeholder="Confirm Password"
              value={passwordData.password2}
              onChange={onChangeForm}
            />
          </div>
          <div className="col col12">{errorMessage.length > 0 ? <div>{errorMessage}</div> : null}</div>
          <div className={`col col12 ${styles.buttonsGroupDiv}`}>
            <Button onClick={() => history.goBack()} label="Back" type="secondary" />
            <Button
              label="Update Password"
              type="primary"
              disabled={passwordValidation(passwordData.password, passwordData.password2) !== ''}
              onClick={() => {
                setLoading(true);
                submitPasswordForm();
              }}
            />
          </div>
        </div>
      </FormContainer>
    </FullCenter>
  );
};
