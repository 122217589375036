/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button } from '../Button/Button';
import styles from './Modal.module.scss';

export interface ModalProps {
  show: boolean;
  message: string;
  confirmText: string;
  cancelText?: string;
  children?: React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  disabledConfirmButton?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  show = false,
  message,
  confirmText,
  cancelText = 'Back',
  onConfirm,
  onClose,
  children,
  disabledConfirmButton = false,
}) => {
  return (
    <div className={`${styles.modalOverlay} ${show ? styles.active : null}`} onClick={onClose}>
      <div className={`${styles.modal} ${show ? styles.active : null}`} onClick={(e) => e.stopPropagation()}>
        <a className={styles.closeModal} onClick={onClose}>
          <svg viewBox="0 0 20 20">
            <path
              fill="#000000"
              d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
            ></path>
          </svg>
        </a>

        <div>
          <div className={styles.modalContent}>
            <h6 className="pt8 pb4">{message}</h6>
            {children}
          </div>

          <div className={`${styles.buttonsDiv} row`}>
            <div className="col col12 col6-m">
              <Button fluid type="secondary" label="Back" onClick={onClose} />
            </div>
            <div className="col col12 col6-m">
              <Button fluid type="primary" disabled={disabledConfirmButton} label={confirmText} onClick={onConfirm} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
