import React from 'react';
import { ReactComponent as MENU_OFFERS } from '../../../assets/img/menu/offers.svg';
import { ReactComponent as MENU_BUSINESS } from '../../../assets/img/menu/business_profile.svg';
import { ReactComponent as MENU_BUSINESS_REPORT } from '../../../assets/img/menu/business_report.svg';
import { ReactComponent as MENU_USER } from '../../../assets/img/menu/user_settings.svg';
import { ReactComponent as MENU_LOGOUT } from '../../../assets/img/menu/logout.svg';

import { ReactComponent as FOOD } from '../../../assets/img/icons/food.svg';
import { ReactComponent as CART } from '../../../assets/img/icons/cart.svg';
import { ReactComponent as MENU } from '../../../assets/img/icons/menu.svg';
import { ReactComponent as CLOSE } from '../../../assets/img/icons/close_white.svg';
import { ReactComponent as VECTOR } from '../../../assets/img/icons/vector.svg';

import { ReactComponent as CLOCK } from '../../../assets/img/icons/clock.svg';
import { ReactComponent as CAR } from '../../../assets/img/icons/car.svg';
import { ReactComponent as COMPONENT } from '../../../assets/img/icons/component.svg';
import { ReactComponent as USER_TICK } from '../../../assets/img/icons/user-tick.svg';
import { ReactComponent as MENU_CARD } from '../../../assets/img/icons/menu-card.svg';

import { ReactComponent as EYE } from '../../../assets/img/icons/eye.svg';
import { ReactComponent as GALLERY } from '../../../assets/img/icons/gallery.svg';
import { ReactComponent as COPY } from '../../../assets/img/icons/copy.svg';
import { ReactComponent as BOOK_SAVED } from '../../../assets/img/icons/book-saved.svg';
import { ReactComponent as TRASH } from '../../../assets/img/icons/trash.svg';
import { ReactComponent as EYE_SLASH } from '../../../assets/img/icons/eye-slash.svg';
import { ReactComponent as CLOSE_THEME } from '../../../assets/img/icons/close.svg';
import { ReactComponent as LOCATION } from '../../../assets/img/icons/location.svg';
import { ReactComponent as FOOD_THEME } from '../../../assets/img/icons/food_theme.svg';
import { ReactComponent as DRINKS } from '../../../assets/img/icons/drinks.svg';
import { ReactComponent as CALL } from '../../../assets/img/icons/call-calling.svg';
import { ReactComponent as HOURS } from '../../../assets/img/icons/hours.svg';
import { ReactComponent as CALL2 } from '../../../assets/img/icons/call.svg';
import { ReactComponent as LINK } from '../../../assets/img/icons/link.svg';

import { ReactComponent as PROFILE } from '../../../assets/img/icons/profile.svg';
import { ReactComponent as PASSWORD } from '../../../assets/img/icons/password.svg';
import { ReactComponent as DINEIN } from '../../../assets/img/icons/dinein.svg';
import { ReactComponent as DOLLAR } from '../../../assets/img/icons/dollar.svg';
import { ReactComponent as PERCENT } from '../../../assets/img/icons/percent.svg';
import { ReactComponent as MULTIBUY } from '../../../assets/img/icons/multibuy.svg';
import { ReactComponent as GIFT } from '../../../assets/img/icons/gift.svg';

import { ReactComponent as DONE } from '../../../assets/img/icons/done.svg';
import { ReactComponent as UNDONE } from '../../../assets/img/icons/undone.svg';

import { ReactComponent as EDIT } from '../../../assets/img/icons/edit.svg';
import { ReactComponent as CAMPAIGN_LIMIT } from '../../../assets/img/icons/campaign-limit.svg';
import { ReactComponent as AVAILABLE } from '../../../assets/img/icons/available.svg';
import { ReactComponent as BOOK_ONLINE } from '../../../assets/img/icons/book-online.svg';
import { ReactComponent as LIMITED } from '../../../assets/img/icons/limited.svg';
import { ReactComponent as UNLIMITED } from '../../../assets/img/icons/unlimited.svg';
import { ReactComponent as EXCLAMATION } from '../../../assets/img/icons/exclamation.svg';

import { ReactComponent as ARROW_UP } from '../../../assets/img/icons/arrow-up.svg';
import { ReactComponent as ARROW_DOWN } from '../../../assets/img/icons/arrow-down.svg';

import { ReactComponent as RECURRENT } from '../../../assets/img/icons/recurrent.svg';
import { ReactComponent as BLACKOUT_DAY } from '../../../assets/img/icons/blackout-day.svg';
import { ReactComponent as IS_FLEXIBLE } from '../../../assets/img/icons/is-flexible.svg';
import { ReactComponent as BONUS } from '../../../assets/img/icons/bonus.svg';

import styles from './Icon.module.scss';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  name:
    | 'MENU_OFFERS'
    | 'MENU_BUSINESS'
    | 'MENU_BUSINESS_REPORT'
    | 'MENU_USER'
    | 'MENU_LOGOUT'
    | 'FOOD'
    | 'CART'
    | 'MENU'
    | 'CLOSE'
    | 'VECTOR'
    | 'CLOCK'
    | 'CAR'
    | 'COMPONENT'
    | 'USER_TICK'
    | 'MENU_CARD'
    | 'EYE'
    | 'GALLERY'
    | 'COPY'
    | 'BOOK_SAVED'
    | 'TRASH'
    | 'EYE_SLASH'
    | 'CLOSE_THEME'
    | 'LOCATION'
    | 'FOOD_THEME'
    | 'DRINKS'
    | 'CALL'
    | 'HOURS'
    | 'CALL2'
    | 'LINK'
    | 'PROFILE'
    | 'PASSWORD'
    | 'DINEIN'
    | 'DOLLAR'
    | 'PERCENT'
    | 'MULTIBUY'
    | 'GIFT'
    | 'DONE'
    | 'UNDONE'
    | 'EDIT'
    | 'CAMPAIGN_LIMIT'
    | 'AVAILABLE'
    | 'BOOK_ONLINE'
    | 'LIMITED'
    | 'UNLIMITED'
    | 'EXCLAMATION'
    | 'ARROW_UP'
    | 'ARROW_DOWN'
    | 'RECURRENT'
    | 'BLACKOUT_DAY'
    | 'IS_FLEXIBLE'
    | 'BONUS';
}

export const Icon: React.FC<IconProps> = ({ name, ...props }) => {
  // props.fill = props.fill || '#571BCE';
  // props.color = props.color || '#571BCE';
  // props.stroke = props.stroke || '#571BCE';

  if (name === 'MENU_OFFERS') return <MENU_OFFERS className={styles.MENU_OFFERS} {...props} />;
  if (name === 'MENU_BUSINESS') return <MENU_BUSINESS className={styles.MENU_BUSINESS} {...props} />;
  if (name === 'MENU_BUSINESS_REPORT') return <MENU_BUSINESS_REPORT className={styles.MENU_BUSINESS_REPORT} {...props} />;
  if (name === 'MENU_USER') return <MENU_USER className={styles.MENU_USER} {...props} />;
  if (name === 'MENU_LOGOUT') return <MENU_LOGOUT className={styles.MENU_LOGOUT} {...props} />;

  if (name === 'FOOD') return <FOOD className={styles.FOOD} {...props} />;
  if (name === 'CART') return <CART className={styles.CART} {...props} />;
  if (name === 'MENU') return <MENU className={styles.MENU} {...props} />;
  if (name === 'CLOSE') return <CLOSE className={styles.CLOSE} {...props} />;
  if (name === 'VECTOR') return <VECTOR className={styles.VECTOR} {...props} />;

  if (name === 'CLOCK') return <CLOCK className={styles.CLOCK} {...props} />;
  if (name === 'CAR') return <CAR className={styles.CAR} {...props} />;
  if (name === 'COMPONENT') return <COMPONENT className={styles.COMPONENT} {...props} />;
  if (name === 'USER_TICK') return <USER_TICK className={styles.USER_TICK} {...props} />;

  if (name === 'MENU_CARD') return <MENU_CARD className={styles.MENU_CARD} {...props} />;

  if (name === 'EYE') return <EYE className={styles.EYE} {...props} />;
  if (name === 'GALLERY') return <GALLERY className={styles.GALLERY} {...props} />;
  if (name === 'COPY') return <COPY className={styles.COPY} {...props} />;
  if (name === 'BOOK_SAVED') return <BOOK_SAVED className={styles.BOOK_SAVED} {...props} />;
  if (name === 'TRASH') return <TRASH className={styles.TRASH} {...props} />;
  if (name === 'EYE_SLASH') return <EYE_SLASH className={styles.EYE_SLASH} {...props} />;

  if (name === 'CLOSE_THEME') return <CLOSE_THEME className={styles.CLOSE_THEME} {...props} />;
  if (name === 'LOCATION') return <LOCATION className={styles.LOCATION} {...props} />;
  if (name === 'FOOD_THEME') return <FOOD_THEME className={styles.FOOD_THEME} {...props} />;
  if (name === 'DRINKS') return <DRINKS className={styles.DRINKS} {...props} />;
  if (name === 'CALL') return <CALL className={styles.CALL} {...props} />;

  if (name === 'HOURS') return <HOURS className={styles.HOURS} {...props} />;
  if (name === 'CALL2') return <CALL2 className={styles.CALL2} {...props} />;
  if (name === 'LINK') return <LINK className={styles.LINK} {...props} />;

  if (name === 'PROFILE') return <PROFILE className={styles.PROFILE} {...props} />;
  if (name === 'PASSWORD') return <PASSWORD className={styles.PASSWORD} {...props} />;

  if (name === 'DINEIN') return <DINEIN className={styles.DINEIN} {...props} />;
  if (name === 'DOLLAR') return <DOLLAR className={styles.DOLLAR} {...props} />;
  if (name === 'PERCENT') return <PERCENT className={styles.PERCENT} {...props} />;
  if (name === 'MULTIBUY') return <MULTIBUY className={styles.MULTIBUY} {...props} />;
  if (name === 'GIFT') return <GIFT className={styles.GIFT} {...props} />;

  if (name === 'DONE') return <DONE className={styles.DONE} {...props} />;
  if (name === 'UNDONE') return <UNDONE className={styles.UNDONE} {...props} />;

  if (name === 'EDIT') return <EDIT className={styles.EDIT} {...props} />;
  if (name === 'CAMPAIGN_LIMIT') return <CAMPAIGN_LIMIT className={styles.CAMPAIGN_LIMIT} {...props} />;
  if (name === 'AVAILABLE') return <AVAILABLE className={styles.AVAILABLE} {...props} />;
  if (name === 'BOOK_ONLINE') return <BOOK_ONLINE className={styles.BOOK_ONLINE} {...props} />;
  if (name === 'LIMITED') return <LIMITED className={styles.LIMITED} {...props} />;
  if (name === 'UNLIMITED') return <UNLIMITED className={styles.UNLIMITED} {...props} />;
  if (name === 'EXCLAMATION') return <EXCLAMATION className={styles.EXCLAMATION} {...props} />;

  if (name === 'ARROW_UP') return <ARROW_UP className={styles.ARROW_UP} {...props} />;
  if (name === 'ARROW_DOWN') return <ARROW_DOWN className={styles.ARROW_DOWN} {...props} />;

  if (name === 'RECURRENT') return <RECURRENT className={styles.ARROW_UP} {...props} />;
  if (name === 'BLACKOUT_DAY') return <BLACKOUT_DAY className={styles.ARROW_DOWN} {...props} />;
  if (name === 'IS_FLEXIBLE') return <IS_FLEXIBLE className={styles.ARROW_UP} {...props} />;
  if (name === 'BONUS') return <BONUS className={styles.ARROW_DOWN} {...props} />;

  return <CART className={styles.svg} {...props} />;
};
