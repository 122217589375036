import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { OFFERS, NOT_FOUND } from '../../../const/ROUTER_PATH_TITLE';

// import styles from './NotFoundPage.module.scss';

export const NotFoundPage = () => {
  const history = useHistory();

  document.title = NOT_FOUND.TITLE;

  useEffect(() => {
    const timeOut = setTimeout(() => {
      history.push(OFFERS.PATH);
    }, 2000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [history]);

  return (
    <>
      <span>Page Not Found. Redirecting to home page...</span>
    </>
  );
};
