import React, { ChangeEvent, useEffect, useState } from 'react';
import Model from '@tripian/model';
import { InputLabel } from '../../../Base/InputLabel/InputLabel';
import { Input } from '../../../Base/Input/Input';
import { Select } from '../../../Base/Select/Select';
import { Button } from '../../../Base/Button/Button';
import { Validate } from '../../../../model/Validate';
import { initialValidate, validateMarketingQuestions } from '../../helper';
import { ErrorMessage } from '../../../Base/ErrorMessage/ErrorMessage';
import styles from './MarketingQuestions.module.scss';

type Props = {
  marketingQuestions: Model.MarketingQuestions;
  marketingQuestionsOnChange: (marketingQuestions: Model.MarketingQuestions) => void;
  backButtonClick: () => void;
  formCallBack: () => void;
};

export const MarketingQuestions: React.FC<Props> = ({ marketingQuestions, marketingQuestionsOnChange, backButtonClick, formCallBack }) => {
  const [showInputText, setShowInputText] = useState<boolean>(false);
  const [selected, setSelected] = useState(marketingQuestions.howDidYouHear);
  const [tosAccepted, setTosAccepted] = useState<boolean>(false);
  const [validate, setValidate] = useState<Validate[]>([initialValidate, initialValidate]);
  const [isContinueCLicked, setIsContinueClicked] = useState<boolean>(false);

  useEffect(() => {
    const validate = validateMarketingQuestions(marketingQuestions);
    setValidate(validate);
  }, [marketingQuestions]);

  return (
    <>
      <InputLabel text="How did you hear about the promotion?" customClassName={`${styles.title} mt3`} />
      {!validate[0].isValid && isContinueCLicked && validate[0].message && <ErrorMessage message={validate[0].message} />}
      <Select
        id="marketingQuestion1"
        name="marketingQuestion1"
        options={['Advertisement', 'Travel Agent', 'Operator', 'Airline', 'Other']}
        defaultEmpty
        selectedValue={selected}
        onChange={(event) => {
          setSelected(event.target.value);
          marketingQuestionsOnChange({
            ...marketingQuestions,
            howDidYouHear: event.target.value,
          });
          if (event.target.value === 'Other') {
            setShowInputText(true);
          } else {
            setShowInputText(false);
          }
        }}
        size="large"
        customClassName={styles.applicationSelect}
      />
      {showInputText && (
        <Input
          type="text"
          name="other"
          size="large"
          value={marketingQuestions.howDidYouHear}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            marketingQuestionsOnChange({
              ...marketingQuestions,
              howDidYouHear: event.target.value,
            });
          }}
          customClassName={`${styles.title} mt2`}
          placeholder="Write here"
        />
      )}
      <hr />
      <InputLabel text="How did you book your trip to Barbados?" customClassName={`${styles.title} mt3`} />
      {!validate[1].isValid && isContinueCLicked && validate[1].message && <ErrorMessage message={validate[1].message} />}
      <Select
        id="marketingQuestion2"
        name="marketingQuestion2"
        options={['Online', 'Travel Agent/ Tour Operator', 'Direct with hotel']}
        defaultEmpty
        selectedValue={marketingQuestions.howDidYouBookYourTrip}
        onChange={(event) => {
          marketingQuestionsOnChange({
            ...marketingQuestions,
            howDidYouBookYourTrip: event.target.value as 'Online' | 'Travel Agent/ Tour Operator' | 'Direct with hotel',
          });
        }}
        size="large"
        customClassName={styles.applicationSelect}
      />
      <hr />
      <div className={`col col12 col8-m ${styles.tou}`}>
        <Input
          type="checkbox"
          name="tou"
          size="large"
          checked={tosAccepted}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setTosAccepted(event.target.checked);
          }}
        />
        <span className={styles.label}>
          I accept the{' '}
          <a className={styles.link} href={window.tconfig.TOS_URL} target="_blank" rel="noreferrer">
            Terms of Use
          </a>
        </span>
      </div>
      <div className={`${styles.stepButtonsDiv} row`}>
        <div className="col col12 col6-m">
          <Button fluid label="Back" type="secondary" onClick={backButtonClick} />
        </div>
        <div className="col col12 col6-m">
          <Button
            fluid
            label="Send"
            disabled={!tosAccepted}
            onClick={() => {
              setIsContinueClicked(true);
              if (validate.find((valid) => valid.isValid === false) === undefined) {
                formCallBack();
              }
            }}
          />
        </div>
      </div>
    </>
  );
};
