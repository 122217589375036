import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import Model from '@tripian/model';
import { easy } from '@tripian/core';
import { Button } from '../../components/Base/Button/Button';
import { Input } from '../../components/Base/Input/Input';
import { InputLabel } from '../Base/InputLabel/InputLabel';
import { timeFrame } from '../../helper/constants';
import { LineStep } from '../Base/LineStep/LineStep';
import { FormContainer } from '../Base/FormContainer/FormContainer';
import { FullCenter } from '../Base/FullCenter/FullCenter';
import { scrollTop } from '../../helper/common';
import { SelectField } from '../Base/SelectField/SelectField';
import { CampaignAmount } from './steps/1/CampaignAmount/CampaignAmount';
import { DatePicker } from '../DatePicker/DatePicker';
import { TextArea } from '../Base/TextArea/TextArea';
import NumberInput from '../Base/NumberInput/NumberInput';
import { CampaignSummary } from './steps/2/CampaignSummary/CampaignSummary';
import { CN } from '../className';
import { validateCampaignForFirstStep } from './helper';
import { RecurrentDays } from './steps/1/RecurrentDays/RecurrentDays';
import { BlackoutDays } from './steps/1/BlackoutDays/BlackoutDays';
import DayPickerSingleDateControllerWrapper from '../DayPickerSingleDateControllerWrapper/DayPickerSingleDateControllerWrapper';
import styles from './CampaignForm.module.scss';
import { Bonuses } from './steps/1/Bonuses/Bonuses';

const formatPrice = (price: number) => {
  if (price < 1000) return price.toString();

  let priceString = price.toString().split('').reverse();

  let result = '';
  for (let i = 0; i < priceString.length; i++) {
    if (i !== 0 && i % 3 === 0) result = ',' + result;
    result = priceString[i] + result;
  }
  return result;
};

type Props = {
  formData: Model.CampaignAddRequest;
  formCallBack: () => void;
  formOnChange: (campaign: Model.CampaignAddRequest) => void;
  goBack: () => void;
  isCampaignEdit?: boolean;
};

export const CampaignForm: React.FC<Props> = ({ formData, formCallBack, formOnChange, goBack, isCampaignEdit }) => {
  const [step, setStep] = useState<number>(1);
  const [showBlackOutDays, setShowBlackOutDays] = useState<boolean>(isCampaignEdit && formData.blackouts.length > 0 ? true : false);
  const [showRecurrentDays, setShowRecurrentDays] = useState<boolean>(isCampaignEdit && formData.recurrent.length < 7 ? true : false);

  const history = useHistory();
  /* const tdate = useRef(
    new easy.TDate({ startDate: formData.startDate, endDate: formData.endDate, recurrent: formData.recurrent, blackouts: formData.blackouts }),
  ); */
  const [tdate, setTdate] = useState(
    new easy.TDate({ startDate: formData.startDate, endDate: formData.endDate, recurrent: formData.recurrent, blackouts: formData.blackouts }),
  );

  useEffect(() => {
    setTdate(
      new easy.TDate({ startDate: formData.startDate, endDate: formData.endDate, recurrent: formData.recurrent, blackouts: formData.blackouts }),
    );
  }, [formData.startDate, formData.endDate, formData.recurrent, formData.blackouts]);

  useEffect(() => {
    scrollTop();
  }, [step]);

  const backButtonClick = () => {
    if (step === 1) {
      history.goBack();
    } else {
      setStep(step - 1);
    }
  };

  const continueButtonClick = () => {
    setStep(step + 1);
  };

  const stepHeader = step === 1 ? 'Campaign Details' : 'Campaign Summary';

  const renderItems = () => {
    if (step === 1) {
      return (
        <>
          <InputLabel textUppercase={false} text="Title" customClassName="mt3" />
          <Input
            type="text"
            name="campaignTitle"
            size="large"
            value={formData.title}
            onChange={(event: ChangeEvent<HTMLInputElement>) => formOnChange({ ...formData, title: event.target.value })}
            placeholder="Write here"
            disabled={isCampaignEdit}
          />
          <hr />
          <InputLabel textUppercase={false} text="Category" customClassName="mt3" />
          <SelectField
            customClassName={styles.category}
            label={formData.category}
            onSelect={() => {}}
            checked
            icon="BOOK_ONLINE"
            disabled={isCampaignEdit}
          />
          <hr />
          <InputLabel textUppercase={false} text="Amount" customClassName="mt3" />
          <CampaignAmount
            selectedCategory={formData.limited === true ? Model.AMOUNT_TYPE.LIMITED : Model.AMOUNT_TYPE.UNLIMITED}
            onChange={(amount_type: Model.AMOUNT_TYPE) => {
              if (amount_type === Model.AMOUNT_TYPE.LIMITED) {
                formOnChange({ ...formData, limited: true });
              } else {
                formOnChange({ ...formData, limited: false });
              }
            }}
            disabled={isCampaignEdit}
          />
          <hr />
          <InputLabel textUppercase={false} text="Campaign redemption eligibility period" customClassName="mt3" />
          <div className="row mb0">
            <div className="col col5 p0 pr1">
              <DatePicker
                label="Start Date"
                slotProps={{ textField: { color: 'info', focused: false } }}
                typeable={false}
                value={moment(formData.startDate)}
                minDate={moment(timeFrame.today)}
                onChange={(value: moment.Moment | null) => {
                  if (value) {
                    const date = value.format('YYYY-MM-DD');
                    formOnChange({ ...formData, startDate: date });
                  }
                }}
                disabled={isCampaignEdit}
                disablePast
              />
            </div>
            <div className="col col5 p0">
              <DatePicker
                label="End Date"
                slotProps={{
                  textField: { color: 'info', focused: false },
                }}
                typeable={false}
                value={moment(formData.endDate)}
                minDate={moment(formData.startDate).add(1, 'days')}
                onChange={(value: moment.Moment | null) => {
                  if (value) {
                    const date = value.format('YYYY-MM-DD');
                    formOnChange({ ...formData, endDate: date });
                  }
                }}
                disablePast
              />
            </div>
          </div>

          <div className={styles.titleWithCheckbox}>
            <Input
              id="recurrent"
              type="checkbox"
              name="recurrent"
              size="default"
              checked={showRecurrentDays}
              customClassName={styles.checkBoxInput}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setShowRecurrentDays(event.target.checked);
                if (event.target.checked === false) {
                  formOnChange({ ...formData, recurrent: [0, 1, 2, 3, 4, 5, 6] });
                }
              }}
            />
            <InputLabel htmlFor="recurrent" textUppercase={false} text="Recurrent" customClassName="mt3" />
          </div>

          {showRecurrentDays && <RecurrentDays recurrents={formData.recurrent} onChange={(recurrent) => formOnChange({ ...formData, recurrent })} />}

          <div className={styles.titleWithCheckbox}>
            <Input
              id="blackouts"
              type="checkbox"
              name="blackouts"
              size="default"
              checked={showBlackOutDays}
              customClassName={styles.checkBoxInput}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setShowBlackOutDays(event.target.checked);
                if (event.target.checked === false) {
                  formOnChange({ ...formData, blackouts: [] });
                }
              }}
            />
            <InputLabel htmlFor="blackouts" textUppercase={false} text="Blackout dates" customClassName="mt3" />
          </div>
          {showBlackOutDays && (
            <BlackoutDays
              blackouts={formData.blackouts}
              campaignRange={{ startDate: formData.startDate, endDate: formData.endDate }}
              isEdit={isCampaignEdit}
              onChange={(blackoutsDates: string[]) => formOnChange({ ...formData, blackouts: blackoutsDates })}
            />
          )}
          <hr />
          <div className={styles.titleWithCheckbox}>
            <Input
              id="amountMax"
              type="checkbox"
              name="amountMax"
              size="default"
              checked={formData.isAmountMax}
              customClassName={styles.checkBoxInput}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                formOnChange({ ...formData, isAmountMax: event.target.checked });
              }}
            />
            <InputLabel htmlFor="amountMax" textUppercase={false} text="Is flexible amount per voucher?" customClassName="mb3" />
          </div>
          <InputLabel
            htmlFor="amountMax"
            textUppercase={false}
            text={formData.isAmountMax ? 'Max amount per voucher' : 'Amount per voucher'}
            customClassName="mt3"
          />
          <div className={styles.formItemsCenter}>
            <div className={CN(styles.currency, [[styles.disabled, isCampaignEdit === true]])}>{Model.OFFER_CURRENCY.BBD}$</div>
            <NumberInput
              customClassName={styles.numberInput}
              name="quantity"
              placeholder="Enter per voucher"
              minValue={0}
              value={formData.amount > 0 ? formData.amount : undefined}
              onChange={(value) => formOnChange({ ...formData, amount: value })}
              disabled={isCampaignEdit}
            />
          </div>

          {formData.limited === true && (
            <>
              <InputLabel textUppercase={false} text="Set maximum # of recipients" customClassName="mt3" />
              <NumberInput
                customClassName={styles.numberInput}
                name="quantity"
                placeholder="Enter maximum #"
                minValue={0}
                value={formData.maximumRecipients > 0 ? formData.maximumRecipients : undefined}
                onChange={(value) => formOnChange({ ...formData, maximumRecipients: value })}
              />

              <div className={styles.totalBudget}>
                {formData.maximumRecipients > 0 && formData.amount > 0 && (
                  <>
                    Total budget of the campaign is{' '}
                    <span className={styles.totalBudgetAmount}>
                      {Model.OFFER_CURRENCY.BBD}$ {formatPrice(formData.maximumRecipients * formData.amount)}
                    </span>
                  </>
                )}
              </div>
              <hr />
            </>
          )}
          {/* <hr /> */}
          <InputLabel textUppercase={false} text="Maximum offer amount" customClassName="mt3" />
          <div className={styles.formItemsCenter}>
            <div className={CN(styles.currency, [[styles.disabled, isCampaignEdit === true]])}>{Model.OFFER_CURRENCY.BBD}$</div>
            <NumberInput
              customClassName={styles.numberInput}
              name="quantity"
              placeholder="Enter amount"
              minValue={0}
              value={formData.maximumOfferAmount > 0 ? formData.maximumOfferAmount : undefined}
              onChange={(value) => formOnChange({ ...formData, maximumOfferAmount: value })}
              disabled={isCampaignEdit}
            />
          </div>
          <hr />
          <InputLabel textUppercase={false} text="Bonuses" customClassName="mt3" />
          <Bonuses bonuses={formData.bonus} onChange={(newBonuses) => formOnChange({ ...formData, bonus: newBonuses })} />
          <hr />
          <InputLabel textUppercase={false} text="Campaign Description" customClassName="mt3" />
          <TextArea
            rows={3}
            name="description"
            value={formData.description}
            placeholder="Write here"
            charLimit={100}
            onChange={(event) => formOnChange({ ...formData, description: event.target.value })}
          />
          <InputLabel textUppercase={false} text="Campaign Available Days" customClassName="mt3 mb3" />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {/* {tdate.toList().map((x) => (
              <div key={x} style={{ paddingRight: 20 }}>
                {x}
              </div>
            ))} */}
            <DayPickerSingleDateControllerWrapper
              isDayHighlighted={(day) => {
                const dayFormatted = day.format('YYYY-MM-DD');
                return tdate.toList().includes(dayFormatted);
                // return day.format('YYYY-MM-DD') === '2024-01-14' || day.format('YYYY-MM-DD') === '2024-01-15';
                // return tdate.toList().includes(day.format('dddd').toLowerCase());
              }}
            />
          </div>
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <CampaignSummary campaign={formData} />
        </>
      );
    }
  };

  const renderButtons = () => {
    if (step === 1) {
      return (
        <div className={`${styles.stepButtonsDiv} row`}>
          <div className="col col12 col6-m">
            <Button fluid label="Cancel" type="secondary" onClick={backButtonClick} />
          </div>
          <div className="col col12 col6-m">
            <Button fluid label="Continue" onClick={continueButtonClick} disabled={!validateCampaignForFirstStep(formData)} />
          </div>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className={`${styles.stepButtonsDiv} row`}>
          <div className="col col12 col6-m">
            <Button fluid label="Back" type="secondary" onClick={backButtonClick} />
          </div>
          <div className="col col12 col6-m">
            <Button fluid onClick={formCallBack} label="Launch" />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <LineStep stepCount={2} step={step} customClassName={styles.lineStep} />
      <FullCenter centerY={false} maxWidth={700}>
        <h4 className="center pt12">{stepHeader}</h4>
        <FormContainer>
          {renderItems()}
          {renderButtons()}
        </FormContainer>
      </FullCenter>
    </>
  );
};
