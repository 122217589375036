import moment from 'moment';

export const blackouts2Ranges = (blackouts: string[]): { startDate: string; endDate: string }[] => {
  if (blackouts.length > 0) {
    const ranges: { startDate: string; endDate: string }[] = [];

    const dates: moment.Moment[] = [];

    const sortedBlackouts = blackouts.sort((a: string, b: string) => moment(a).diff(moment(b)));

    for (const blackout of sortedBlackouts) {
      const date = moment(blackout, 'YYYY-MM-DD');
      dates.push(date);
    }

    let startDate = dates[0];
    let endDate = dates[0];
    for (let i = 1; i < dates.length; i++) {
      const date = dates[i];
      if (date.diff(endDate, 'days') > 1) {
        ranges.push({ startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') });
        startDate = date;
      }
      endDate = date;
    }

    ranges.push({ startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') });

    return ranges;
  } else {
    return [];
  }
};
