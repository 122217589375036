import React from 'react';
import appLoaderData from '../../assets/img/tripian-loader.gif';

import styles from './AppLoader.module.scss';

export interface AppLoaderProps {
  active: boolean;
  className?: string;
  fixed?: boolean;
  minHeight?: string | number;
  transparent?: boolean;
  children?: React.ReactNode;
}

export const AppLoader: React.FC<AppLoaderProps> = ({ active, children, className, fixed = false, minHeight, transparent = false }) => {
  /*   const [state, setState] = useState({ isStopped: false, isPaused: false });
   */
  const loader = active ? (
    <div
      className={styles.container}
      style={{
        position: fixed ? 'fixed' : 'inherit',
        minHeight: minHeight === undefined ? 'inherit' : minHeight,
        backgroundColor: transparent ? 'transparent' : '#222222aa',
      }}
    >
      <div className={styles.loader}>
        <img src={appLoaderData} alt="Loading.." width="100%" height="100%" />
      </div>
    </div>
  ) : null;

  if (children === undefined) return loader;

  if (loader === null) return <>{children}</>;

  return (
    <div className={className} style={{ position: 'relative', height: '100%' }}>
      {children}
      {loader}
    </div>
  );
};
