import { useEffect, useState } from 'react';
import { AppLoader } from '../../components/AppLoader/AppLoader';
import { setConfigList } from './init';
import App from '../App';

const AppWrapper = () => {
  const [configReady, setConfigReady] = useState(false);

  useEffect(() => {
    setConfigList().then((configList) => {
      setConfigReady(true);
    });
  }, []);

  return <>{configReady ? <App /> : <AppLoader active />}</>;
};

export default AppWrapper;
