import toastr from 'toastr';

export const successMessage = (message: string, timeOut: number = 5000) => {
  toastr.success(message, undefined, {
    timeOut,
    positionClass: 'toast-bottom-full-width',
  });
};

export const errorMessage = (message: string, timeOut: number = 2000) => {
  toastr.error(message, undefined, {
    timeOut,
    positionClass: 'toast-bottom-full-width',
  });
};

export const warningMessage = (message: string, timeOut: number = 2000) => {
  toastr.warning(message, undefined, {
    timeOut,
    positionClass: 'toast-bottom-full-width',
  });
};
