/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { imgUrl } from '../../../helper/common';
import { offerTimeFormat } from '../../../helper/offerFormat';
import { getDaysOfWeek } from '../../../helper/getDaysOfWeek';
import { blackouts2Ranges } from '../../../helper/blackouts2Ranges';
import Model from '@tripian/model';
import { Icon } from '../../Base/Icon/Icon';
import { IconRow } from '../../IconRow/IconRow';
import { CN } from '../../className';

import styles from './OfferItem.module.scss';

export interface OfferItemProps {
  offer: Model.Offer;
  businessName: string;
  customerCount: number;
  onClick: (offer: Model.Offer) => void;

  // for active
  // onPreview: (offer: Offer) => void;
  onChangePhoto: (offer: Model.Offer) => void;
  onDuplicate: (offer: Model.Offer) => void;
  onInactivate: (offer: Model.Offer) => void;

  // for inactive
  onActivate: (offer: Model.Offer) => void;
  // onEdit: (offer: Offer) => void;
  // onChangePhoto: (offer: Offer) => void;
  // onDuplicate: (offer: Offer) => void;
  onArchive: (offer: Model.Offer) => void;

  // for archive
  // onInactive: (offer: Offer) => void;
  onDelete: (offer: Model.Offer) => void;
}

export const OfferItem: React.FC<OfferItemProps> = ({
  offer,
  businessName,
  customerCount,
  onClick,
  /* active */
  // onPreview,
  onChangePhoto,
  onDuplicate,
  onInactivate,
  /* inactive */
  onActivate,
  // onEdit,
  // onChangePhoto,
  // onDuplicate,
  onArchive,
  /* archive */
  // onInactivate,
  onDelete,
}) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const dateRanges = blackouts2Ranges(offer.timeframe.blackouts);
  const days = getDaysOfWeek(offer.timeframe.recurrent);

  const formattedDate = useMemo(() => {
    return offerTimeFormat(offer.timeframe);
  }, [offer.timeframe]);

  /* const formattedAccepted = useMemo(() => {
    return offerAcceptedFormat(offer.timeframe, customers);
  }, [customers, offer.timeframe]); */

  /* const onClickMenu = () => {
    console.log('onClickMenu');
  }; */

  const menu = useMemo(() => {
    if (offer.status === Model.OFFER_STATUS.ACTIVE)
      return (
        <div className={`${styles.menuList} ${openMenu ? styles.menuListOpen : ''}`}>
          {/* <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onPreview(offer);
            }}
          >
            <IconRow icon="CAR" header="Preview" text="" />
          </div> */}
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onChangePhoto(offer);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onChangePhoto(offer);
            }}
          >
            <IconRow icon="GALLERY" header="Change photo" text="" />
          </div>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onDuplicate(offer);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onDuplicate(offer);
            }}
          >
            <IconRow icon="COPY" header="Duplicate" text="" />
          </div>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onInactivate(offer);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onInactivate(offer);
            }}
          >
            <IconRow icon="EYE_SLASH" header="Make Inactive" text="" />
          </div>
        </div>
      );
    else if (offer.status === Model.OFFER_STATUS.INACTIVE || offer.status === Model.OFFER_STATUS.EXPIRED)
      return (
        <div className={`${styles.menuList} ${openMenu ? styles.menuListOpen : ''}`}>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onActivate(offer);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onActivate(offer);
            }}
          >
            <IconRow icon="EYE" header="Activate" text="" />
          </div>
          {/* <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onEdit(offer);
            }}
          >
            Edit
          </div> */}
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onChangePhoto(offer);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onChangePhoto(offer);
            }}
          >
            <IconRow icon="GALLERY" header="Change photo" text="" />
          </div>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onDuplicate(offer);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onDuplicate(offer);
            }}
          >
            <IconRow icon="COPY" header="Duplicate" text="" />
          </div>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onArchive(offer);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onArchive(offer);
            }}
          >
            <IconRow icon="BOOK_SAVED" header="Archive" text="" />
          </div>
        </div>
      );
    // if (offer.status === OFFER_STATUS.ARCHIVED)
    else
      return (
        <div className={`${styles.menuList} ${openMenu ? styles.menuListOpen : ''}`}>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onInactivate(offer);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onInactivate(offer);
            }}
          >
            <IconRow icon="EYE_SLASH" header="Move to Inactive" text="" />
          </div>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(offer);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onDelete(offer);
            }}
          >
            <IconRow icon="TRASH" header="Delete" text="" />
          </div>
        </div>
      );
  }, [offer, onActivate, onArchive, onChangePhoto, onDelete, onDuplicate, onInactivate, openMenu]);

  return (
    <div className={styles.offerItem} onClick={() => onClick(offer)}>
      <div className="row">
        <div className={`${styles.imageContainer} col col12 col5-m`}>
          <img
            className={CN(styles.image, [[styles.archived, offer.status === Model.OFFER_STATUS.ARCHIVE]])}
            src={imgUrl(offer.imageUrl, 800, 600)}
            alt={offer.title}
          />
        </div>
        <div className="col col12 col7-m">
          <div className="row">
            <div className="col col12">
              <div className={styles.businessName}>
                <Icon name="VECTOR" /> {businessName}
                <div
                  className={styles.menu}
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenMenu(true);
                  }}
                  onBlur={() => setOpenMenu(false)}
                >
                  <Icon name="MENU_CARD" />
                  {menu}
                </div>
              </div>
              <h5>{offer.title}</h5>
              <div className={styles.offerCaption}>{offer.caption}</div>
              <hr className="mt4 mb2" />
            </div>
            <div className="col col12">
              <IconRow icon="CLOCK" header="Eligibility period" text={formattedDate} />
              {/* <IconRow icon="COMPONENT" header="Available number of recipients" text={offer.quantity.toString()} /> */}
              <IconRow icon="USER_TICK" header="Accepted Offer" text={customerCount.toString()} />
              <IconRow icon="BOOK_SAVED" header="Description" text={offer.description} />
              {days.length > 0 && (
                <IconRow
                  icon="RECURRENT"
                  header="Recurrent"
                  text={days.map((d, index) => (
                    <span>
                      {' '}
                      {d}
                      {index < days.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                />
              )}
              {dateRanges.length > 0 && (
                <IconRow
                  icon="BLACKOUT_DAY"
                  header="Blackout Days"
                  text={dateRanges.map((d) => (
                    <div>
                      <span>{moment(d.startDate).format('MMM D YYYY')}</span> - <span>{moment(d.endDate).format('MMM D YYYY')}</span>
                    </div>
                  ))}
                />
              )}

              {/* <IconRow icon="USER_TICK" header="Accepted Offer" text={formattedAccepted} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
