import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../../components/Base/Button/Button';
import { FormContainer } from '../../../components/Base/FormContainer/FormContainer';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { Icon } from '../../../components/Base/Icon/Icon';
import { LinkButton } from '../../../components/Base/LinkButton/LinkButton';
import { LOGIN, PREREGISTER, SIGNUP } from '../../../const/ROUTER_PATH_TITLE';
import { scrollTop } from '../../../helper/common';
import styles from './WelcomePage.module.scss';

export const WelcomePage = () => {
  const [showDescription, setShowDescription] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    scrollTop();
  }, [showDescription]);

  if (!showDescription)
    return (
      <FullCenter centerY={false} maxWidth={575} customClassName={styles.fullCenter}>
        <div className={styles.welcomeMain}>
          <div className={styles.hContainer}>
            <h4>Make sure your business qualifies</h4>
            <div className={styles.question}>Already have a business account?</div>
            <LinkButton text="Log in" onClick={() => history.push(LOGIN.PATH)} bold />
          </div>
          <div className={styles.content}>
            {/* <span className={styles.header5}>You're in:</span>
            <FormContainer>
              <div className={styles.iconRow}>
                <Icon name="LOCATION" width={32} height={32} />
                <span className={styles.header6}>Prince Edward County, ON</span>
              </div>
            </FormContainer> */}

            <div className="mt8" style={{ width: '100%' }}>
              <span className={styles.header5}>You sell one or more of:</span>
              <div className="row mt5 px2">
                <div className="col col4">
                  <FormContainer customClassName={styles.iconFormContainer}>
                    <Icon name="FOOD_THEME" width={32} height={32} />
                    <div className={styles.boxGrid}>Food</div>
                  </FormContainer>
                </div>
                <div className="col col4">
                  <FormContainer customClassName={styles.iconFormContainer}>
                    <Icon name="DRINKS" width={32} height={32} />
                    <div className={styles.boxGrid}>Drinks</div>
                  </FormContainer>
                </div>
                <div className="col col4">
                  <FormContainer customClassName={styles.iconFormContainer}>
                    <Icon name="CART" width={32} height={32} />
                    <div className={styles.boxGrid}>Retail</div>
                  </FormContainer>
                </div>
              </div>
            </div>

            <div className={styles.buttonsGroupDiv}>
              <Button onClick={() => history.push(PREREGISTER.PATH)} label="Don’t qualify yet?" type="secondary" />
              <Button onClick={() => setShowDescription(true)} label="My business qualifies" />
            </div>
          </div>
        </div>
      </FullCenter>
    );

  return (
    <FullCenter centerY={false} maxWidth={575} customClassName="pt12">
      <div className={styles.welcomeMain}>
        <div className={styles.hContainer}>
          <h4>Can you answer your business phone during sign-up?</h4>
        </div>

        <div className={styles.content}>
          <FormContainer>
            <Icon name="CALL" width={32} height={32} />
            <div className={styles.callText}>
              We’ll call or test (mobile only) the business phone number in your Google Maps profile for validation during sign-up.
            </div>
          </FormContainer>

          <div className={styles.buttonsGroupDiv}>
            <Button onClick={() => setShowDescription(false)} label="Back" type="secondary" />
            <Button onClick={() => history.push(SIGNUP.PATH)} label="I can answer my business phone" type="primary" />
          </div>
        </div>
      </div>
    </FullCenter>
  );
};
