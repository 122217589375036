import React from 'react';
import TripianLogo from '../../assets/img/tripian_logo_light.png';

interface AppLogoProps {
  dark?: boolean;
  height?: string | number;
  width?: string | number;
  className?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({ dark = false, height, width, className }) => (
  <img
    src={(dark ? window.tconfig.IMAGE_PATHS.LOGO_PATH_DARK : window.tconfig.IMAGE_PATHS.LOGO_PATH_LIGHT) || TripianLogo}
    alt="logo"
    style={{ width, height }}
    className={className}
  />
);

export default AppLogo;
