import React from 'react';
import { CN } from '../../className';
import { AppLoader } from '../../AppLoader/AppLoader';
import styles from './FullCenter.module.scss';

export interface FullCenterProps {
  loading?: boolean;
  maxWidth?: string | number;
  centerY?: boolean;
  customClassName?: string;
  loaderFixed?: boolean;
  children: React.ReactNode;
}

export const FullCenter: React.FC<FullCenterProps> = ({
  loading = false,
  centerY = true,
  customClassName,
  maxWidth,
  loaderFixed = false,
  children,
}) => {
  return (
    <AppLoader active={loading} fixed={loaderFixed}>
      <div
        className={CN(styles.fullCenter, [
          [styles.centerY, centerY],
          [customClassName || '', customClassName !== undefined],
        ])}
        style={{ maxWidth }}
      >
        {children}
      </div>
    </AppLoader>
  );
};
