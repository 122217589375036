import IConfig from './IConfig';
import { staticLocalConfig } from './local';

const initialStaticLocalConfig: IConfig = staticLocalConfig;

declare global {
  interface Window {
    tconfig: IConfig;
  }
}

window.tconfig = window.tconfig || initialStaticLocalConfig;
