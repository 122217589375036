/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { Button } from '../../../../Base/Button/Button';
import { useDropzone } from 'react-dropzone';
import { AppLoader } from '../../../../AppLoader/AppLoader';
import UploadIcon from './UploadIcon.svg';
import styles from './BookingUpload.module.scss';

type Props = {
  fileUrl: string;
  upload: (file: string) => Promise<{ url: string }>;
  onChange: (fileUrl: string) => void;
  uploading: (loading: boolean) => void;
};

export const BookingUpload: React.FC<Props> = ({ fileUrl, upload, onChange, uploading }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string | undefined>(fileUrl);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [file, setFile] = useState<
    File & {
      preview: string;
    }
  >();

  const onChangeImage = (newFileUrl: string) => {
    onChange(newFileUrl);
    setUrl(newFileUrl);
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      let baseURL = '';
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result as string;
        resolve(baseURL);
      };
    });
  };

  const onChangeFile = async (files: File[]) => {
    setLoading(true);
    uploading(true);
    setErrorMessage(undefined);

    const file = await getBase64(files[0]);

    upload(file)
      .then((image: { url: string }) => onChangeImage(image.url))
      .catch((err) => {
        setErrorMessage(err);
        // if (uploadError) uploadError(err);
      })
      .finally(() => {
        setLoading(false);
        uploading(false);
      });
  };

  const maxSize = 5242880;

  const { isDragActive, getRootProps, getInputProps, isDragReject, fileRejections, open } = useDropzone({
    onDrop: useCallback((acceptedFiles: File[]) => {
      const newFile = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      });

      setFile(newFile);
    }, []),
    accept: { 'image/png': ['.png'], 'image/jpeg': ['.jpeg'], 'image/jpg': ['.jpg'], 'application/pdf': ['.pdf'] },
    minSize: 0,
    maxSize,
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: onChangeFile,
  });

  const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > maxSize;
  const splittedUrl = url?.split('.');
  const urlFormat = splittedUrl ? splittedUrl[splittedUrl?.length - 1] : undefined;

  return (
    <>
      {url !== '' ? (
        <div className={styles.imageDiv}>
          <a
            className={styles.closeDiv}
            onClick={() => {
              onChangeImage('');
              setFile(undefined);
            }}
          >
            <i className={`fa fa-times ${styles.closeIcon}`}></i>
          </a>
          {urlFormat === 'pdf' ? (
            <iframe title="s" src={url} id="docpdf" style={{ height: '15.625rem', width: '100%', borderRadius: '1.875rem' }} />
          ) : (
            <img alt="alt" src={url}></img>
          )}
        </div>
      ) : (
        <AppLoader active={loading} className={styles.uploadLoading} transparent>
          {file && !errorMessage ? (
            <div className={styles.offerUploadedImageDiv}>
              <img className={styles.offerUploadedImage} src={file?.preview} alt={file?.name} />
            </div>
          ) : (
            <section className={styles.fileUploader}>
              <div className={styles.fileContainer} {...getRootProps()}>
                <img className={styles.uploadIcon} src={UploadIcon} alt="" />
                <p className={styles.uploadLabel}>5MB max. JPEG, PNG or PDF</p>
                <Button customClassName={styles.chooseFileButton} label="Upload" onClick={open} />
                <input {...getInputProps()} />
                {isDragActive && !isDragReject && "Drop it like it's hot!"}
                {isDragReject && 'File type not accepted, sorry!'}
                {isFileTooLarge && <div>File is too large.</div>}
              </div>
            </section>
          )}
          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        </AppLoader>
      )}
    </>
  );
};
