/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { helper } from '@tripian/model';
import ReactSelect, { InputActionMeta } from 'react-select';
import { ValueType } from 'react-select/src/types';
import { AutoCompletePropsOption } from '../Base/AutoComplete/AutoComplete';
import './RSelect.module.scss';

interface IRSelectOption {
  id: number;
  label: string;
  payload?: any;
  isSelected?: boolean;
}

interface IRSelect {
  options: Array<IRSelectOption>;
  onSelectedChanged: (selectedOption: IRSelectOption) => void;
  defaultOptionId?: number;
  disabled?: boolean;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  placeHolder?: string;
}

export const RSelect: React.FC<IRSelect> = ({
  options,
  onSelectedChanged,
  defaultOptionId,
  disabled = false,
  onInputChange,
  placeHolder = 'Please Select',
}) => {
  const [selectedOption, setSelectedOption] = useState<ValueType<IRSelectOption, false>>(options.find((option) => option.id === defaultOptionId));

  useEffect(() => {
    const hasIsSelectedOption = options.findIndex((option) => option.id === defaultOptionId);
    if (hasIsSelectedOption < 0) {
      setSelectedOption(undefined);
    } else {
      setSelectedOption(options[hasIsSelectedOption]);
    }
  }, [defaultOptionId, options]);

  const handleChange = (newSelectedOption: ValueType<IRSelectOption, false>) => {
    setSelectedOption(newSelectedOption);
    onSelectedChanged(newSelectedOption as IRSelectOption);
  };

  const customFilter = (option: { label: string; value: string; data: any }, searchText: string) =>
    helper.toEngChars(option.label).toLowerCase().includes(helper.toEngChars(searchText).toLowerCase());

  const formatOptionLabel = (option: AutoCompletePropsOption) => (
    <div className="labelDiv">
      <div style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>{option.label}</div>
      <div style={{ color: '#ccc', lineHeight: '1.375rem' }}>{option.extra}</div>
    </div>
  );

  const noOptionsMessage = (obj: { inputValue: string }) => {
    if (obj.inputValue.length > 2) {
      if (options.length === 0) {
        return <span>No Option Found.</span>;
      }
      return <span>Loading...</span>;
    }
    return null;
  };

  return (
    <>
      <ReactSelect
        options={options}
        value={selectedOption}
        onChange={handleChange}
        onInputChange={onInputChange}
        filterOption={customFilter}
        placeholder={placeHolder}
        className="trpReactSelect"
        classNamePrefix="trpReactSelect"
        isDisabled={disabled}
        isOptionDisabled={(option) => option.isSelected === true}
        isOptionSelected={(option) => option.isSelected === true}
        formatOptionLabel={formatOptionLabel}
        noOptionsMessage={noOptionsMessage}
      />
    </>
  );
};
