import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as SingleDatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

type Props = DatePickerProps<moment.Moment> &
  React.RefAttributes<HTMLDivElement> & {
    typeable?: boolean;
  };

export const DatePicker: React.FC<Props> = ({ typeable = true, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {typeable ? (
        <DemoContainer components={['DatePicker']}>
          <SingleDatePicker {...props} />
        </DemoContainer>
      ) : (
        <DemoContainer components={['MobileDatePicker']}>
          <MobileDatePicker {...props} />
        </DemoContainer>
      )}
    </LocalizationProvider>
  );
};
