import React from 'react';
import { offerTypeName } from '../../../../../core/data';
import Model from '@tripian/model';
import styles from './OfferTypeCombobox.module.scss';

type OfferTypeComboboxProps = {
  selectedOfferType?: Model.OFFER_TYPE;
  onChange: (offerType: Model.OFFER_TYPE) => void;
};

export const OfferTypeCombobox: React.FC<OfferTypeComboboxProps> = ({ selectedOfferType, onChange }) => {
  return (
    <div className={styles.content}>
      <select id="offerTypes" name="offerTypes" value={selectedOfferType} onChange={(event) => onChange(event.target.value as Model.OFFER_TYPE)}>
        {window.tconfig.SBT.OFFER_TYPES.map((ot) => {
          return (
            <option key={`combo-option-${ot}`} className={styles.option} value={ot}>
              {offerTypeName(ot)}
            </option>
          );
        })}
      </select>
    </div>
  );
};
