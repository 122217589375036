// id 4 = dine - in
// id 5 = pick - up
// id 8 = retail - pick up

import moment from 'moment';
import Model from '@tripian/model';

const validateOfferForFirstStep = (offerData: Model.OfferAddRequest): boolean => {
  if (offerData.title.trim() === '') return false;
  else if (offerData.productTypeId < 1) return false;
  return true;
};

const validateOfferForSecondStep = (offerData: Model.OfferAddRequest, voucherOfferData: Model.VoucherOfferAddRequest): boolean => {
  if (offerData.offerType === Model.OFFER_TYPE.VOUCHER) {
    if (voucherOfferData.discount < 1) return false;
    if (voucherOfferData.description.trim() === '') return false;
  } else {
    if (offerData.threshold <= 0) return false;
    else if (offerData.discount < 1) return false;
    else if (!offerData.quantity || offerData.quantity < 1) return false;
    else if (offerData.offerType === Model.OFFER_TYPE.QUANTITY && (!offerData.productName || offerData.productName.trim() === '')) return false;
    else if (
      (offerData.offerType === Model.OFFER_TYPE.QUANTITY || offerData.offerType === Model.OFFER_TYPE.SPEND_FOR_GIFT) &&
      (!offerData.giftName || offerData.giftName.trim() === '')
    )
      return false;
    // else if (offerData.offerType === Model.OFFER_TYPE.SPEND_FOR_GIFT && (!offerData.giftName || offerData.giftName.trim() === '')) return false;
    else if (moment(offerData.timeframe.end).diff(moment(offerData.timeframe.start), 'days') >= 31) return false;
  }

  return true;
};

const captionCreater = (
  offerType: Model.OFFER_TYPE,
  threshold: number,
  discount: number,
  discountedProductCount: number,
  productName?: string,
): string => {
  if (offerType === Model.OFFER_TYPE.AMOUNT) {
    return `Get $${discount} off your purchase when you spend $${threshold} or more.`;
  } else if (offerType === Model.OFFER_TYPE.PERCENTAGE) {
    return `Get ${discount}% off any purchase of you spend $${threshold}.`;
  } else if (offerType === Model.OFFER_TYPE.QUANTITY) {
    const getString = discount >= 100 ? 'FREE' : `with ${discount}% off.`;
    return `Buy ${threshold} ${productName} Get ${discountedProductCount} ${getString}`;
  }

  return '';
};

export { captionCreater, validateOfferForFirstStep, validateOfferForSecondStep };
