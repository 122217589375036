import moment from 'moment';
import { TableBody, TableCell, TableRow } from '@mui/material';
import Model from '@tripian/model';
// import classes from './ReportTableBody.module.scss';

type Props = {
  data: Model.CampaignCustomerReport;
};

export const ReportTableBody: React.FC<Props> = ({ data }) => {
  return (
    <TableBody>
      {data.map((data, i) => (
        <TableRow key={`${data.applicationCustomerId}-${i}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {data.applicationCustomerId}
          </TableCell>
          <TableCell>{data.customerFullName}</TableCell>
          <TableCell>{data.customerEmail}</TableCell>
          <TableCell>{data.customerEmail === data.leadCustomerEmail ? data.numberOfTravellers : ''}</TableCell>
          <TableCell>{moment(data.applicationDate).format('MMM D')}</TableCell>
          <TableCell>{data.status === 0 ? 'Waiting' : data.status === -1 ? 'Rejected' : 'Approved'}</TableCell>
          <TableCell>{moment(data.customerArrivalDate).format('MMM D')}</TableCell>
          <TableCell>{moment(data.customerDepartureDate).format('MMM D')}</TableCell>
          <TableCell>{data.lengthOfStay}</TableCell>
          <TableCell>{data.typeOfProperty}</TableCell>
          <TableCell>{data.nameOfProperty}</TableCell>
          <TableCell>{data.bookingDate && data.bookingDate !== '' ? moment(data.bookingDate).format('MMM D') : ''}</TableCell>
          <TableCell>{data.arrivalFrom}</TableCell>
          <TableCell>{data.airline}</TableCell>
          <TableCell>{data.optInOfferCount}</TableCell>
          <TableCell>{data.redeemedOfferCount}</TableCell>
          <TableCell>{data.totalRedeemedAmount}</TableCell>
          <TableCell>
            {data.claimedDatetimes.map((x) => (
              <div>{moment(x).format('MMM D - hh:mmA')}</div>
            ))}
          </TableCell>
          <TableCell>
            {data.redeemedAts.map((x) => (
              <div>{x}</div>
            ))}
          </TableCell>
          <TableCell>
            {data.redeemedDatetimes.map((x) => (
              <div>{moment(x).format('MMM D - hh:mmA')}</div>
            ))}
          </TableCell>
          <TableCell>{data.howDidYouBookYourTrip}</TableCell>
          <TableCell>{data.howDidYouHear}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
