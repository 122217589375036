/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { imgUrl } from '../../helper/common';
import { offerTimeFormat } from '../../helper/offerFormat';
import Model from '@tripian/model';
import { FormContainer } from '../Base/FormContainer/FormContainer';

import styles from './OfferCard.module.scss';

export interface OfferCardProps {
  businessName: string;
  offer: Model.Offer;
  voucherOffer: Model.VoucherOfferAddRequest;
  onClick?: (offer: Model.Offer) => void;
}

export const OfferCard: React.FC<OfferCardProps> = ({ businessName, offer, voucherOffer, onClick }) => {
  const formattedDate = useMemo(() => {
    return offerTimeFormat(offer.timeframe);
  }, [offer.timeframe]);

  const offMessage: string = useMemo(() => {
    if (offer.offerType === Model.OFFER_TYPE.PERCENTAGE) return `${offer.discount}% OFF`;
    if (offer.offerType === Model.OFFER_TYPE.AMOUNT) return `$${offer.discount} OFF`;
    if (offer.offerType === Model.OFFER_TYPE.VOUCHER) return `$${voucherOffer.discount} CASHBACK`;
    return `${offer.discount}% OFF`;
  }, [offer.discount, offer.offerType, voucherOffer.discount]);

  return (
    <div
      className={styles.offerCard}
      onClick={() => {
        if (onClick !== undefined) onClick(offer);
      }}
    >
      <div className={styles.imageContainer}>
        <div className={styles.off}>{offMessage}</div>
        <img
          className={styles.image}
          ref={(node) => {
            if (node) {
              node.style.setProperty('width', '100%', 'important');
            }
          }}
          src={imgUrl(offer.imageUrl, 800, 600)}
          alt={offer.title}
        />
        <img style={{ width: 0, height: 0 }} src={imgUrl(offer.imageUrl, 128, 128)} alt={offer.title} />
      </div>

      <div className={styles.content}>
        <FormContainer customClassName="px4 py5">
          <div className={styles.titleDiv}>
            <div className={styles.businessName}>{businessName}</div>
          </div>
          <div className={styles.rowc}>{offer.title}</div>
          <div className={styles.rowc}>{formattedDate}</div>

          <div className={styles.rowc}>{offer.caption}</div>
          {/* <div className={styles.rowc}>{offer.description}</div> */}
          {/*
        <div>
          <div className={styles.tagsContainer}>
            {offer.tags?.split(',').map((tag) => (
              <div key={`${offer.id}-tag-${tag}`} className={styles.tag}>
                {tag}
              </div>
            ))}
          </div>
        </div> */}
        </FormContainer>
      </div>
    </div>
  );
};
