import { useState } from 'react';
import Model from '@tripian/model';

export const useConfirm = (onInactivate: (offer: Model.Offer) => Promise<Model.Offer>) => {
  const [confirm, setConfirm] = useState<{ type: 'activate' | 'inactivate' | 'delete'; message: string; offer: Model.Offer } | undefined>(undefined);

  const confirmOnInactivate = (offer: Model.Offer): Promise<Model.Offer | undefined> => {
    if (offer.status === Model.OFFER_STATUS.ARCHIVE) return onInactivate(offer);
    setConfirm({ type: 'inactivate', message: 'Are you sure you want to make inactive this offer?', offer });
    return Promise.resolve(undefined);
  };

  const confirmOnActivate = (offer: Model.Offer) => setConfirm({ type: 'activate', message: 'Are you sure you want to activate this offer?', offer });

  const confirmOnDelete = (offer: Model.Offer) => setConfirm({ type: 'delete', message: 'Are you sure you want to delete this offer?', offer });

  const resetConfirmState = () => setConfirm(undefined);

  return { confirm, setConfirm, confirmOnInactivate, confirmOnActivate, confirmOnDelete, resetConfirmState };
};
