import React, { useCallback } from 'react';
import { PRODUCT_TYPE_NAMES } from '../../../../../core/data';
import { IconName } from '../../../../../helper/constants';
import Model from '@tripian/model';
import { SelectField } from '../../../../Base/SelectField/SelectField';

import styles from './OfferCategory.module.scss';

export interface OfferCategoryProps {
  selectedCategory?: Model.PRODUCT_TYPE_NAME;
  onChange: (category: Model.PRODUCT_TYPE_NAME) => void;
}

export const OfferCategory: React.FC<OfferCategoryProps> = ({ selectedCategory = PRODUCT_TYPE_NAMES[0], onChange }) => {
  const memoizedIcon = useCallback((category: Model.PRODUCT_TYPE_NAME): IconName => {
    if (category === Model.PRODUCT_TYPE_NAME.EXPERIENCES) {
      return 'BOOK_ONLINE';
    } else if (category === Model.PRODUCT_TYPE_NAME.DINING) {
      return 'FOOD_THEME';
    } else if (category === Model.PRODUCT_TYPE_NAME.SHOPPING) {
      return 'CART';
    }
    return 'CART';
  }, []);

  return (
    <div className={styles.content}>
      {PRODUCT_TYPE_NAMES.map((c) => {
        return (
          <div key={`product-types-${c}`} className={styles.offerCategoryItem}>
            <SelectField
              label={c}
              onSelect={() => {
                onChange(c);
              }}
              checked={selectedCategory === c}
              icon={memoizedIcon(c)}
            />
          </div>
        );
      })}
    </div>
  );
};
