import { useCallback, useState } from 'react';
import Model from '@tripian/model';
import { api } from '@tripian/core';

export const useCampaignReport = (campaignId: number) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Model.DataPayload<Model.CampaignReport>>({ data: [] });

  const fetchCampaignReport = useCallback(
    (query: Partial<Model.CampaignReportRequest>) => {
      setLoading(true);
      api
        .campaignReport(campaignId, query)
        .then((data) => {
          setData(data);
        })
        .finally(() => setLoading(false));
    },
    [campaignId],
  );

  const onPay = useCallback(
    (id: number, query: Partial<Model.CampaignReportRequest>) => {
      setLoading(true);
      api.combo
        .campaignReportPayCombo(campaignId, id, query)
        .then((data) => {
          setData(data);
        })
        .finally(() => setLoading(false));
    },
    [campaignId],
  );

  const onExportAll = useCallback(() => {
    api.businessExport('campaign-report', campaignId).then((data) => {
      window.open(data.url);
    });
  }, [campaignId]);

  return { loading, fetchCampaignReport, data, onPay, onExportAll };
};
