import { TableCell, TableRow } from '@mui/material';
import Model from '@tripian/model';
import styles from './ReportTableFilter.module.scss';

type Props = {
  filters: Partial<Model.BusinessReportRequest>;
  setFilters: (newFilter: Partial<Model.BusinessReportRequest>) => void;
};
export const ReportTableFilter: React.FC<Props> = ({ filters, setFilters }) => {
  const onFreetextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'businessUserEmail') setFilters({ ...filters, email: e.target.value });
    else if (e.target.name === 'businessUserFullname') setFilters({ ...filters, fullName: e.target.value });
    else if (e.target.name === 'businessPoiName') setFilters({ ...filters, businessName: e.target.value });
    else if (e.target.name === 'activeVoucherOfferIds') {
      if (e.target.value === '') {
        setFilters({ ...filters, offerCount: undefined });
      } else {
        setFilters({ ...filters, offerCount: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) });
      }
    } else if (e.target.name === 'totalRedeemedAmount') {
      if (e.target.value === '') {
        setFilters({ ...filters, totalRedeemed: undefined });
      } else {
        setFilters({ ...filters, totalRedeemed: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) });
      }
    } else if (e.target.name === 'totalReimbursedAmount') {
      if (e.target.value === '') {
        setFilters({ ...filters, totalReimbursed: undefined });
      } else {
        setFilters({ ...filters, totalReimbursed: isNaN(Number(e.target.value)) ? 0 : Number(e.target.value) });
      }
    }
  };

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.name === 'status')
      setFilters({
        ...filters,
        status: e.target.value === 'All' ? undefined : (e.target.value as 'Waiting For Validation' | 'Active'),
      });
  };

  return (
    <TableRow className={styles.reportFilterRow}>
      <TableCell className={styles.reportTableHeader}>Filters:</TableCell>
      <TableCell>
        <input
          className={styles.reportCustomInput}
          name="businessUserEmail"
          placeholder="Email"
          type="text"
          value={filters.email}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <input
          className={styles.reportCustomInput}
          name="businessUserFullname"
          placeholder="Fullname"
          type="text"
          value={filters.fullName}
          onChange={onFreetextChange}
        />
      </TableCell>
      {/* <TableCell>
        <input
          className={`${styles.reportCustomInput} ${styles.reportAmountInput}`}
          name="businessPoiId"
          placeholder="Poi Id"
          type="text"
          value={filters.businessPoiId}
          onChange={onFreetextChange}
        />
      </TableCell> */}
      <TableCell>
        <input
          className={styles.reportCustomInput}
          name="businessPoiName"
          placeholder="Business Name"
          type="text"
          value={filters.businessName}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell>
        <select className={styles.reportSelectStatus} name="status" value={filters.status} onChange={onSelectChange}>
          <option value="All">All</option>
          <option value="Active">Active</option>
          <option value="Waiting For Validation">Waiting For Validation</option>
        </select>
      </TableCell>
      <TableCell align="center">
        <input
          className={`${styles.reportCustomInput} ${styles.reportAmountInput}`}
          name="activeVoucherOfferIds"
          placeholder="Count"
          type="text"
          value={filters.offerCount}
          /* value={filters.activeVoucherOfferIds?.length ?? ''} */
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell align="center">
        <input
          className={`${styles.reportCustomInput} ${styles.reportAmountInput}`}
          name="totalRedeemedAmount"
          placeholder="Amount"
          type="text"
          value={filters.totalRedeemed}
          onChange={onFreetextChange}
        />
      </TableCell>
      <TableCell align="center">
        <input
          className={`${styles.reportCustomInput} ${styles.reportAmountInput}`}
          name="totalReimbursedAmount"
          placeholder="Amount"
          type="text"
          value={filters.totalReimbursed}
          onChange={onFreetextChange}
        />
      </TableCell>
    </TableRow>
  );
};
