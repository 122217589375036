import React, { useState } from 'react';
import styles from './BlackoutDays.module.scss';
import DateRangePicker from '../../../../DateRangePicker/DateRangePicker';
import moment from 'moment';
import { Icon } from '../../../../Base/Icon/Icon';
import { blackouts2Ranges } from '../../../../../helper/blackouts2Ranges';

interface Props {
  blackouts: string[];
  campaignRange: { startDate: string; endDate: string };
  isEdit?: boolean;
  onChange: (indexes: string[]) => void;
  extendedBlackouts?: string[];
}

export const BlackoutDays: React.FC<Props> = ({ blackouts, campaignRange, isEdit, onChange, extendedBlackouts = [] }) => {
  const [extendedDateRanges] = useState<{ startDate: string; endDate: string }[]>(blackouts2Ranges(extendedBlackouts));
  const [dateRanges, setDateRanges] = useState<{ startDate: string; endDate: string }[]>(isEdit ? blackouts2Ranges(blackouts) : []);

  const enumerateDaysBetweenDates = (startDate: moment.Moment, endDate: moment.Moment) => {
    const now = startDate,
      dates = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {
      dates.push(now.format('YYYY-MM-DD'));
      now.add(1, 'days');
    }
    return dates;
  };

  const callbackDateRangePickerAdd = (startDate: string, endDate: string) => {
    const newDateRanges = [...dateRanges];
    newDateRanges.push({ startDate: startDate, endDate: endDate });
    setDateRanges(newDateRanges);

    const blackoutsDates = enumerateDaysBetweenDates(moment(startDate), moment(endDate));
    const sortedBlackouts = blackoutsDates.sort((a: string, b: string) => moment(a).diff(moment(b)));
    onChange([...blackouts, ...sortedBlackouts]);
  };

  const callbackDateRangePickerRemove = (index: number) => {
    const newDateRanges = [...dateRanges];
    newDateRanges.splice(index, 1);
    setDateRanges(newDateRanges);

    const blackoutsDates = enumerateDaysBetweenDates(moment(dateRanges[index].startDate), moment(dateRanges[index].endDate));
    const newBlackOuts = [...blackouts];
    const newArray = newBlackOuts.filter((v) => !blackoutsDates.includes(v));
    onChange(newArray);
  };

  return (
    <div className={styles.content}>
      <DateRangePicker onchanged={callbackDateRangePickerAdd} range={{ startDate: campaignRange.startDate, endDate: campaignRange.endDate }} />
      <div>
        {extendedDateRanges.map((range) => (
          <div key={`extended-${range.startDate}-${range.endDate}`} className={styles.rangeItem}>
            <span className={styles.rangeBox}>{range.startDate}</span>
            <span className={styles.to}>To</span>
            <span className={styles.rangeBox}>{range.endDate}</span>
            {/* <button className={styles.closeButton} disabled>
            <Icon name="CLOSE" color="#571BCE" />
          </button> */}
          </div>
        ))}
        {dateRanges.map((range, i) => (
          <div key={`${range.startDate}-${range.endDate}`} className={styles.rangeItem}>
            <span className={styles.rangeBox}>{range.startDate}</span>
            <span className={styles.to}>To</span>
            <span className={styles.rangeBox}>{range.endDate}</span>
            <button className={styles.closeButton} onClick={() => callbackDateRangePickerRemove(i)}>
              <Icon name="CLOSE" color="#571BCE" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
