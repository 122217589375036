import moment from 'moment';
import { useState, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import Model from '@tripian/model';
import { api } from '@tripian/core';
import { OfferForm } from '../../../components/OfferForm/OfferForm';
import { AppLoader } from '../../../components/AppLoader/AppLoader';
import { OFFER_CREATE, OFFERS } from '../../../const/ROUTER_PATH_TITLE';
import UserContext from '../../../context/User/UserContext';
import { initialOfferFormData, initialVoucherOfferFormData } from '../../../helper/constants';
import { successMessage, errorMessage } from '../../../helper/toastr';

// import styles from './CreateOfferPage.module.scss';

export const CreateOfferPage = () => {
  const history = useHistory();
  const { payloadData } = useContext(UserContext);

  const offerToFormData = (offer: Model.Offer): Model.OfferAddRequest => ({
    ...initialOfferFormData,
    cityId: payloadData.business?.cityId || initialOfferFormData.cityId,
    currency: offer.currency,
    description: offer.description,
    discount: offer.discount,
    discountedProductCount: offer.discountedProductCount,
    imageUrl: offer.imageUrl,
    offerType: offer.offerType,
    productName: offer.productName,
    giftName: offer.giftName,
    productTypeId: offer.productType.id,
    quantity: offer.quantity,
    threshold: offer.threshold,
    placeId: offer.tripianPoiId,
    title: `Copy of ${offer.title}`,
  });

  const voucherOfferToFormData = (voucherOffer: Model.VoucherOffer): Model.VoucherOfferAddRequest => ({
    ...initialVoucherOfferFormData,
    cityId: payloadData.business?.cityId || initialOfferFormData.cityId,
    title: `Copy of ${voucherOffer.title}`,
    offerType: Model.OFFER_TYPE.VOUCHER,
    campaignId: 0,
    placeId: voucherOffer.tripianPoiId,
    discount: voucherOffer.discount,
    quantity: voucherOffer.quantity,
    description: voucherOffer.description || '',
    imageUrl: voucherOffer.imageUrl,
    status: voucherOffer.status,
  });

  const [formData, setFormData] = useState<Model.OfferAddRequest>(
    history.location.state
      ? offerToFormData(history.location.state as Model.Offer)
      : { ...initialOfferFormData, cityId: payloadData.business?.cityId || initialOfferFormData.cityId },
  );

  const [voucherOfferFormData, setVoucherOfferFormData] = useState<Model.VoucherOfferAddRequest>(
    history.location.state
      ? voucherOfferToFormData(history.location.state as Model.VoucherOffer)
      : { ...initialVoucherOfferFormData, cityId: payloadData.business?.cityId || initialOfferFormData.cityId },
  );

  const [campaigns, setCampaigns] = useState<Model.Campaign[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [campaignsLoading, setCampaignsLoading] = useState<boolean>(false);
  const [customPoisLoading, setCustomPoisLoading] = useState<boolean>(false);

  const [poisToSelect, setPoisToSelect] = useState<Model.Poi[]>([]);
  const [customPoiQuery, setCustomPoiQuery] = useState<string>('');

  const timerRef = useRef<number | undefined>(undefined);

  const businessImageUrl = payloadData.business?.image.url || '';

  document.title = OFFER_CREATE.TITLE;

  const uploadImage = (imageFile: string): Promise<{ url: string }> => {
    return api.businessOfferFileUpload(imageFile);
  };

  useEffect(() => {
    if (window.tconfig.SBT.OFFER_TYPES.includes(Model.OFFER_TYPE.VOUCHER)) {
      setFormData((prev) => ({ ...prev, offerType: Model.OFFER_TYPE.VOUCHER }));
    }
  }, []);

  useEffect(() => {
    if (customPoiQuery.length > 2) {
      if (timerRef.current) window.clearTimeout(timerRef.current);
      setCustomPoisLoading(true);
      setPoisToSelect([]);
      const searchCustomPoi = async () =>
        api
          .customPois(customPoiQuery)
          .then((pois) => {
            setPoisToSelect(pois);
            const customPoi = pois.find((poi) => poi.id === formData.placeId);
            if (customPoi) {
              if (voucherOfferFormData.cityId === 0) {
                setVoucherOfferFormData({ ...voucherOfferFormData, cityId: customPoi.cityId });
              }
              if (formData.cityId === 0) {
                setFormData({ ...formData, cityId: customPoi.cityId });
              }
            }
          })
          .finally(() => {
            setCustomPoisLoading(false);
          });

      timerRef.current = window.setTimeout(() => {
        searchCustomPoi();
      }, 400);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customPoiQuery]);

  useEffect(() => {
    if (window.tconfig.SBT.OFFER_TYPES.includes(Model.OFFER_TYPE.VOUCHER) && campaigns.length === 0) {
      setCampaignsLoading(true);
      api
        .businessCampaigns(1, 100)
        .then((cs) => {
          if (0 < cs.data.length) {
            const defaultCampaign = cs.data[0];
            setVoucherOfferFormData((prev) => ({ ...prev, campaignId: defaultCampaign.id }));
            setFormData((prev) => ({
              ...prev,
              timeframe: {
                start: defaultCampaign.timeframe.start,
                end: defaultCampaign.timeframe.end,
                recurrent: defaultCampaign.timeframe.recurrent,
                blackouts: defaultCampaign.timeframe.blackouts,
              },
            }));
          }
          setCampaigns(cs.data);
        })
        .finally(() => setCampaignsLoading(false));
    }
  }, [campaigns.length, formData.offerType, payloadData.user?.userType]);

  const createNewOffer = async (passive?: boolean) => {
    const newOffer: Model.OfferAddRequest = { ...formData };
    const newVoucherOffer: Model.VoucherOfferAddRequest = { ...voucherOfferFormData };

    if (passive) {
      newOffer.status = Model.OFFER_STATUS.INACTIVE;
      newVoucherOffer.status = Model.OFFER_STATUS.INACTIVE;
    }

    if (newOffer.imageUrl === undefined) {
      newOffer.imageUrl = businessImageUrl;
      newVoucherOffer.imageUrl = businessImageUrl;
    }

    if (newOffer.description?.trim() === '') newOffer.description = undefined;

    newOffer.timeframe = {
      start: moment(newOffer.timeframe?.start).format('YYYY-MM-DD HH:mm'),
      end: moment(newOffer.timeframe?.end).format('YYYY-MM-DD HH:mm'),
      recurrent: [...newOffer.timeframe.recurrent],
      blackouts: [...newOffer.timeframe.blackouts],
    };

    newVoucherOffer.timeframe = {
      start: moment(newOffer.timeframe?.start).format('YYYY-MM-DD HH:mm'),
      end: moment(newOffer.timeframe?.end).format('YYYY-MM-DD HH:mm'),
      recurrent: [...newOffer.timeframe.recurrent],
      blackouts: [...newOffer.timeframe.blackouts],
    };

    if (newOffer.offerType === Model.OFFER_TYPE.VOUCHER && newVoucherOffer.offerType === Model.OFFER_TYPE.VOUCHER) {
      api
        .businessVoucherOfferAdd(newVoucherOffer)
        .then(() => {
          successMessage('Offer created successfuly !');
          history.push(OFFERS.PATH);
        })
        .catch((err) => {
          errorMessage(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api
        .businessOfferAdd(newOffer)
        .then(() => {
          successMessage('Offer created successfuly !');
          history.push(OFFERS.PATH);
        })
        .catch((err) => {
          errorMessage(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading ? (
        <div>
          <AppLoader active={loading || campaignsLoading || customPoisLoading} fixed />
        </div>
      ) : null}

      <OfferForm
        poiName={payloadData.business?.name ?? ''}
        defaultImageUrl={businessImageUrl}
        formData={formData}
        formOnChange={(offerData) => setFormData(offerData)}
        voucherOfferFormData={voucherOfferFormData}
        voucherOfferFormOnChange={(voucherOfferData) => setVoucherOfferFormData(voucherOfferData)}
        formCallBack={(passive?: boolean) => {
          setLoading(true);
          createNewOffer(passive);
        }}
        goBack={() => history.push(OFFERS.PATH)}
        uploadImage={uploadImage}
        campaigns={campaigns}
        poisToSelect={poisToSelect}
        setCustomPoiQuery={setCustomPoiQuery}
      />

      {/* errorMessageText ? <div>{errorMessageText}</div> : null */}
    </>
  );
};
