/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { getLocalStorageToken, saveLocalStorageToken } from '../../../helper/localStorage';
import { SignUpFormData } from '../../../model/SignUpFormData';
import { Validate } from '../../../model/Validate';
import { api } from '@tripian/core';
import Model, { helper } from '@tripian/model';

import UserContext from '../../../context/User/UserContext';

import { OFFERS, LOGIN, SIGNUP, PREREGISTER } from '../../../const/ROUTER_PATH_TITLE';
import USER_ACTION_TYPES from '../../../context/User/USER_ACTION_TYPES';
import IPayloadData from '../../../context/User/IPayloadData';

import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { Input } from '../../../components/Base/Input/Input';
import { Button } from '../../../components/Base/Button/Button';
import { InputLabel } from '../../../components/Base/InputLabel/InputLabel';
import { LinkButton } from '../../../components/Base/LinkButton/LinkButton';
import { ModalPage } from '../../../components/Base/ModalPage/ModalPage';
import { ErrorMessage } from '../../../components/Base/ErrorMessage/ErrorMessage';
import { AutoComplete } from '../../../components/Base/AutoComplete/AutoComplete';
import { PasswordControl } from '../../../components/PasswordControl/PasswordControl';
import { RSelect } from '../../../components/RSelect/RSelect';

import { usePrevious } from '../../../hooks/usePrevious';
import { initialFormData } from '../../../helper/constants';
import { getId, scrollTop, wordTrim } from '../../../helper/common';
import { validateSignUp } from '../../../helper/validate';
import { LineStep } from '../../../components/Base/LineStep/LineStep';
import { FormContainer } from '../../../components/Base/FormContainer/FormContainer';
import styles from './SignUpPage.module.scss';

export const SignUpPage = () => {
  const [formData, setFormData] = useState<SignUpFormData>(initialFormData);
  const [validate, setValidate] = useState<Validate>({ isValid: true, message: undefined });
  const [loading, setLoading] = useState<boolean>(false);
  const [businessResultArray, setBusinessResultArray] = useState<Model.BusinessSearch[]>([]);
  const [queryLoader, setQueryLoader] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [focusPassword, setFocusPassword] = useState<boolean>(false);
  const [tosAccepted, setTosAccepted] = useState<boolean>(false);

  const [needHelp, setNeedHelp] = useState(false);
  const [cities, setCities] = useState<Model.City[]>([]);

  const { dispatch } = useContext(UserContext);

  const history = useHistory();
  document.title = SIGNUP.TITLE;

  const myRef = React.createRef<HTMLDivElement>();

  const timerRef = useRef<number | undefined>(undefined);

  const prevFormData = usePrevious(formData);

  useEffect(() => {
    let unmonted = false;

    if (!unmonted) {
      if (getLocalStorageToken()) {
        history.replace(OFFERS.PATH);
      }
    }
    return () => {
      unmonted = false;
    };
  }, [history]);

  useEffect(() => {
    let unmonted = false;

    if (!unmonted) {
      api.citiesAll().then((cities) => {
        setCities(cities);
      });
    }
    return () => {
      unmonted = true;
    };
  }, []);

  useEffect(() => {
    if (prevFormData?.cityId !== formData.cityId) {
      setFormData({
        ...formData,
        poi: undefined,
        tripianPoiId: undefined,
        googlePlaceId: undefined,
      });
    }
  }, [formData, formData.cityId, prevFormData?.cityId]);

  const onChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    switch (inputName) {
      case 'mobileNumber':
        setFormData((prevData: SignUpFormData) => ({ ...prevData, mobileNumber: inputValue }));
        break;

      case 'email':
        setFormData((prevData: SignUpFormData) => ({ ...prevData, email: wordTrim(inputValue) }));
        break;

      case 'email2':
        setFormData((prevData: SignUpFormData) => ({ ...prevData, email2: wordTrim(inputValue) }));
        break;

      case 'password':
        setFormData((prevData: SignUpFormData) => ({ ...prevData, password: wordTrim(inputValue) }));
        break;

      // case 'password2':
      //   setFormData((prevData) => ({ ...prevData, password2: inputValue }));
      //   break;

      case 'firstName':
        setFormData((prevData: SignUpFormData) => ({ ...prevData, firstName: inputValue }));
        break;

      case 'lastName':
        setFormData((prevData: SignUpFormData) => ({ ...prevData, lastName: inputValue }));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    let unmounted = false;
    setQueryLoader(true);

    if (query.length > 2) {
      if (timerRef.current) window.clearTimeout(timerRef.current);
      const search = async () => {
        if (formData.cityId) {
          setBusinessResultArray([]);
          api
            .businessSearch(query, formData.cityId)
            .then((businessList: Model.BusinessSearch[]) => {
              if (!unmounted) {
                setBusinessResultArray(businessList);
              }
            })
            .finally(() => setQueryLoader(false)); // TODO cityId
          // setQueryLoader(false);
        } else {
          setQueryLoader(false);
        }
      };
      timerRef.current = window.setTimeout(() => {
        search();
      }, 400);
    } else {
      setQueryLoader(false);
      if (query.length === 0) setBusinessResultArray([]);
    }

    return () => {
      unmounted = true;
    };
  }, [formData.cityId, query]);

  useEffect(() => {
    scrollTop();
  }, []);

  const onSelectPoi = (value: any) => {
    const currentBusiness = businessResultArray.find((business) => getId(business).joinedId === value.id);
    setFormData({
      ...formData,
      poi: currentBusiness,
      tripianPoiId: currentBusiness?.tripianPoiId,
      googlePlaceId: currentBusiness?.googlePlaceId,
    });
  };

  const onSelectCity = (value: any) => {
    setFormData({
      ...formData,
      cityId: value.id,
    });
  };

  // const login = async (loginUser: Model.LoginRequest) => {
  //   return api
  //     .loginEmail(loginUser.email, loginUser.password)
  //     .then((token: Model.Token) => {
  //       localStorage.setItem(localStorageTokenName, JSON.stringify(token));
  //       return fetchPayloadData(token);
  //     })
  //     .catch((err) => {
  //       setValidate({ ...validate, message: err.response.data.message });
  //       setLoading(false);
  //     });
  // };

  const fetchPayloadData = async (token: Model.Token) => {
    saveLocalStorageToken(token);

    return api.combo
      .businessPayloadData()
      .then((payloadData: IPayloadData) => {
        dispatch({
          type: USER_ACTION_TYPES.SET_PAYLOAD,
          payload: payloadData,
        });
        history.replace(OFFERS.PATH);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitForm = () => {
    const formValidate = validateSignUp(formData);
    setValidate(formValidate);

    if (formValidate.isValid) {
      const newUserRequest: Model.RegisterRequest = {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        password: formData.password,
        dateOfBirth: formData.dateOfBirth,
        tripianPoiId: formData.tripianPoiId,
        googlePlaceId: formData.googlePlaceId,
      };

      api
        .register(newUserRequest)
        .then((token: Model.Token) => {
          api.setToken(token);
          fetchPayloadData(token);
          // return login({ email: formData.email, password: formData.password });
        })
        .catch((err) => {
          setLoading(false);
          setValidate({ ...validate, message: err });
        });
    } else {
      setLoading(false);
      myRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  return (
    <FullCenter loading={loading} centerY={false} maxWidth={1088} customClassName={styles.fullCenter} loaderFixed={true}>
      <LineStep stepCount={3} step={1} customClassName={styles.lineStep} />
      <div className={styles.hContainer}>
        <h4>Business Sign Up</h4>
        <div className={styles.question}>Already have a business account?</div>
        <LinkButton text="Log in" onClick={() => history.push(LOGIN.PATH)} bold />
      </div>

      <FormContainer customClassName={styles.formContainer}>
        <form onSubmit={submitForm}>
          <div ref={myRef}>
            <ErrorMessage message={validate.message} />
            <div className="row">
              <div className="col col12 col6-m">
                <InputLabel text="City" />
                <RSelect
                  options={cities
                    .sort((a, b) => helper.compareStringForSort(a.name, b.name))
                    .map((city) => ({
                      id: city.id,
                      label: city.name,
                      payload: city,
                      isSelected: formData.cityId === city.id,
                    }))}
                  defaultOptionId={formData.cityId}
                  onSelectedChanged={onSelectCity}
                />
              </div>
              <div className="col col12 col6-m">
                <InputLabel text="Business">
                  <LinkButton text="Need help?" onClick={() => setNeedHelp(true)} />
                </InputLabel>
                <AutoComplete
                  onChange={(qr) => {
                    setQuery(qr);
                  }}
                  options={businessResultArray.map((businessList) => {
                    const ids = getId(businessList);
                    return {
                      id: ids.joinedId,
                      label: businessList.business.name,
                      extra: businessList.business.address,
                    };
                  })}
                  value={
                    formData.poi
                      ? {
                          id: 0,
                          label: formData.poi.business.name,
                        }
                      : null
                  }
                  onSelect={onSelectPoi}
                  loading={queryLoader}
                  placeholder="Search your business"
                />
              </div>
            </div>
            <div className="row">
              <div className="col col12 col6-m">
                <InputLabel text="First Name" />
                <Input type="text" name="firstName" size="large" value={formData.firstName} onChange={onChangeForm} />
              </div>
              <div className="col col12 col6-m">
                <InputLabel text="Last Name" />
                <Input type="text" name="lastName" size="large" value={formData.lastName} onChange={onChangeForm} />
              </div>
            </div>

            <div className="row">
              <div className="col col12 col6-m">
                <InputLabel text="Email" />
                <Input type="text" name="email" size="large" value={formData.email} onChange={onChangeForm} />
              </div>
              <div className="col col12 col6-m">
                <InputLabel text="Confirm Email" />
                <Input type="text" name="email2" size="large" value={formData.email2} onChange={onChangeForm} />
              </div>
            </div>
            <div className="row">
              <div className="col col12 col6-m" style={{ position: 'relative' }}>
                <InputLabel text="Create Password">
                  <LinkButton text={showPassword ? 'Hide' : 'Show'} onClick={() => setShowPassword(!showPassword)} />
                </InputLabel>
                <Input
                  type={`${showPassword ? 'text' : 'password'}`}
                  name="password"
                  size="large"
                  value={formData.password}
                  autoComplete="anystring"
                  onChange={onChangeForm}
                  onFocus={() => setFocusPassword(true)}
                  onBlur={() => setFocusPassword(false)}
                />
                {focusPassword && <PasswordControl password={formData.password} />}
              </div>
              <div className={`col col12 col8-m ${styles.tou}`}>
                <Input
                  type="checkbox"
                  name="tou"
                  size="large"
                  checked={tosAccepted}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setTosAccepted(event.target.checked);
                    setFormData((prevData: SignUpFormData) => ({ ...prevData, termsOfUse: event.target.checked }));
                  }}
                />
                <span className={styles.label}>
                  I accept the{' '}
                  <a className={styles.link} href={window.tconfig.TOS_URL} target="_blank" rel="noreferrer">
                    Terms of Use
                  </a>
                </span>
              </div>
            </div>
            <Button
              onClick={() => {
                setLoading(true);
                submitForm();
              }}
              label="Sign up"
              type="primary"
              // disabled={!validateSignUp(formData).isValid || !tosAccepted}
            />
          </div>
        </form>
      </FormContainer>

      <ModalPage show={showHelpModal} onClose={() => setShowHelpModal(false)}>
        <div className={styles.helpModal}>
          <h3>Business Sign-up FAQ</h3>
          <div className={styles.modalParts}>
            <h3>Can't find your business?</h3>
            <span>Lorem ipsum</span>
          </div>
          <div className={styles.modalParts}>
            <h3>Have more than one business or location?</h3>
            <span>Lorem ipsum</span>
          </div>
        </div>
      </ModalPage>

      <ModalPage size="small" show={needHelp} onClose={() => setNeedHelp(false)}>
        <div style={{ padding: '2rem 0', fontSize: '1.5rem', textAlign: 'center' }}>
          If your business does not appear in the search results,
          <br />
          you may go to
          <LinkButton
            customClassName={styles.preRegisterButton}
            onClick={() => {
              setNeedHelp(false);
              history.push(PREREGISTER.PATH);
            }}
            text="Pre Register"
          />
          to register your business for inclusion in Tripian.
        </div>
        <div style={{ padding: '2rem 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              setNeedHelp(false);
            }}
            label="OK"
            type="primary"
          />
        </div>
      </ModalPage>
    </FullCenter>
  );
};
