import React, { ChangeEvent, useEffect, useState } from 'react';
import Model, { helper } from '@tripian/model';
import { RSelect } from '../../../RSelect/RSelect';
import { InputLabel } from '../../../Base/InputLabel/InputLabel';
import { Input } from '../../../Base/Input/Input';
import { Button } from '../../../Base/Button/Button';
import { Validate } from '../../../../model/Validate';
import { initialValidate, validateTravelInformation } from '../../helper';
import { ErrorMessage } from '../../../Base/ErrorMessage/ErrorMessage';
import { BookingUpload } from './BookingUpload/BookingUpload';
import { nameOfProperty } from '../../constant';
import styles from './TravelInformation.module.scss';

type Props = {
  travelInformation: Model.TravelInformation;
  travelInformationOnChange: (travelInformation: Model.TravelInformation) => void;
  continueButtonClick: () => void;
  uploadImage: (imageFile: string) => Promise<{ url: string }>;
};

export const TravelInformation: React.FC<Props> = ({ travelInformation, travelInformationOnChange, continueButtonClick, uploadImage }) => {
  const [validate, setValidate] = useState<Validate[]>([initialValidate, initialValidate, initialValidate]);
  const [isContinueCLicked, setIsContinueClicked] = useState<boolean>(false);

  useEffect(() => {
    const validate = validateTravelInformation(travelInformation);
    setValidate(validate);
  }, [travelInformation]);

  return (
    <>
      <InputLabel text="Size of Travel Group" customClassName={`${styles.title} mt3`} />
      {!validate[0].isValid && isContinueCLicked && validate[0].message && <ErrorMessage message={validate[0].message} />}
      <Input
        type="number"
        name="sizeOfTraveGroup"
        size="large"
        min={1}
        value={travelInformation.adults.toString().replace(/^0+/, '')}
        onChange={(event: ChangeEvent<HTMLInputElement>) => travelInformationOnChange({ ...travelInformation, adults: Number(event.target.value) })}
        customClassName="mb4"
        placeholder="Write here"
      />
      <hr />

      <InputLabel text="Where will you be staying?" customClassName={`${styles.title} mt3`} />
      {!validate[1].isValid && isContinueCLicked && validate[1].message && <ErrorMessage message={validate[1].message} />}
      <RSelect
        options={nameOfProperty
          .sort((a, b) => helper.compareStringForSort(a.name, b.name))
          .map((hotel) => ({
            id: hotel.id,
            label: hotel.name,
            payload: hotel,
            isSelected: hotel.name === travelInformation.nameOfProperty,
          }))}
        defaultOptionId={nameOfProperty.find((n) => n.name === travelInformation.nameOfProperty)?.id}
        placeHolder="Start typing..."
        onSelectedChanged={(selectedOption) =>
          travelInformationOnChange({
            ...travelInformation,
            nameOfProperty: selectedOption.payload.name,
          })
        }
      />
      <hr />

      <InputLabel
        text="Please upload an image of your accommodation confirmation letter clearly showing the date the booking was made and the names of all persons travelling"
        customClassName={`${styles.title} mt3`}
      />
      {!validate[2].isValid && isContinueCLicked && validate[2].message && <ErrorMessage message={validate[2].message} />}
      <BookingUpload
        fileUrl={travelInformation.proofOfHolidayBookingUrl}
        onChange={(imageUrl?: string) => travelInformationOnChange({ ...travelInformation, proofOfHolidayBookingUrl: imageUrl || '' })}
        upload={uploadImage}
        uploading={() => {}}
      />
      <hr />
      <div className={`${styles.stepButtonsDiv} row`}>
        <div className="col col12 col12-m"></div>
        <Button
          fluid
          label="Continue"
          onClick={() => {
            setIsContinueClicked(true);
            if (validate.find((valid) => valid.isValid === false) === undefined) {
              continueButtonClick();
            }
          }}
          /* disabled={validate.some((x) => x.isValid === false)} */
        />
      </div>
    </>
  );
};
