/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from 'react';
import { useHistory } from 'react-router';
import Model from '@tripian/model';
import { api } from '@tripian/core';
import { CAMPAIGNS, CAMPAIGN_CREATE } from '../../../const/ROUTER_PATH_TITLE';
import { errorMessage } from '../../../helper/toastr';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { initialCampaignFormData } from '../../../helper/constants';
import { CampaignForm } from '../../../components/CampaignForm/CampaignForm';
import styles from './CampaignCreatePage.module.scss';

export const CampaignCreatePage = () => {
  const history = useHistory();

  const campaignToFormData = (campaign: Model.Campaign): Model.CampaignAddRequest => ({
    ...initialCampaignFormData,
    title: `Copy of ${campaign.title}`,
    category: campaign.category,
    limited: campaign.limited,
    // currency: campaign.currency,
    bonus: campaign.bonus,
    isAmountMax: campaign.isAmountMax,
    amount: campaign.amount,
    maximumOfferAmount: campaign.maximumOfferAmount,
    maximumRecipients: campaign.maximumRecipients,
    description: campaign.description,
  });

  const [campaignFormData, setCampaignFormData] = useState<Model.CampaignAddRequest>(
    history.location.state ? campaignToFormData(history.location.state as Model.Campaign) : { ...initialCampaignFormData },
  );
  const [loading, setLoading] = useState<boolean>(false);

  document.title = CAMPAIGN_CREATE.TITLE;

  const onSubmit = () => {
    setLoading(true);
    api
      .businessCampaignAdd(campaignFormData)
      .then((d) => {
        history.push(CAMPAIGNS.PATH);
      })
      .catch((err) => {
        errorMessage(err);
        setLoading(false);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FullCenter centerY={false} customClassName={styles.fullCenter} loading={loading}>
      <CampaignForm
        formData={campaignFormData}
        formOnChange={(offerData) => setCampaignFormData(offerData)}
        formCallBack={() => {
          setLoading(true);
          onSubmit();
        }}
        goBack={() => history.push(CAMPAIGNS.PATH)}
      />
    </FullCenter>
  );
};
