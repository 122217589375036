import { useEffect, useState } from 'react';
import Model from '@tripian/model';
import { api } from '@tripian/core';

export const useBusinessOfferReport = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Model.BusinessOfferReport>([]);

  useEffect(() => {
    api
      .businessOfferReport()
      .then((data) => {
        setData(data.data);
      })
      .finally(() => setLoading(false));
  }, []);

  return { loading, data };
};
