import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Model from '@tripian/model';
import { LineStep } from '../Base/LineStep/LineStep';
import { FormContainer } from '../Base/FormContainer/FormContainer';
import { FullCenter } from '../Base/FullCenter/FullCenter';
import { scrollTop } from '../../helper/common';
import { TravelInformation } from './steps/TravelInformation/TravelInformation';
import { PersonalInformations } from './steps/PersonalInformations/PersonalInformations';
import { MarketingQuestions } from './steps/MarketingQuestions/MarketingQuestions';
import styles from './CouponApplicationForm.module.scss';
import AppLogo from '../AppLogo/AppLogo';

type Props = {
  formData: Model.CouponApplicationRequest;
  formCallBack: () => void;
  formOnChange: (application: Model.CouponApplicationRequest) => void;
  uploadImage: (imageFile: string) => Promise<{ url: string }>;
};

export const CouponApplicationForm: React.FC<Props> = ({ formData, formCallBack, formOnChange, uploadImage }) => {
  const [step, setStep] = useState<number>(1);
  const history = useHistory();

  useEffect(() => {
    scrollTop();
  }, [step]);

  const backButtonClick = () => {
    if (step === 1) {
      history.goBack();
    } else {
      setStep(step - 1);
    }
  };

  const continueButtonClick = () => {
    setStep(step + 1);
  };

  const stepHeader = step === 1 ? 'Travel Information' : step === 2 ? 'Personal Information' : 'Marketing Questions';

  const renderItems = () => {
    if (step === 1) {
      return (
        <TravelInformation
          travelInformation={formData.travelInformation}
          travelInformationOnChange={(travelInformation) => {
            formOnChange({
              ...formData,
              travelInformation: {
                ...formData.travelInformation,
                adults: travelInformation.adults,
                nameOfProperty: travelInformation.nameOfProperty,
                proofOfHolidayBookingUrl: travelInformation.proofOfHolidayBookingUrl,
              },
            });
          }}
          continueButtonClick={continueButtonClick}
          uploadImage={uploadImage}
        />
      );
    } else if (step === 2) {
      return (
        <PersonalInformations
          adult={formData.travelInformation.adults}
          personalInformations={formData.personalInformations}
          personalInformationsOnChange={(personalInformations) => {
            formOnChange({
              ...formData,
              personalInformations: [...personalInformations],
            });
          }}
          backButtonClick={backButtonClick}
          continueButtonClick={continueButtonClick}
        />
      );
    } else if (step === 3) {
      return (
        <MarketingQuestions
          marketingQuestions={formData.marketingQuestions}
          marketingQuestionsOnChange={(marketingQuestions) => {
            formOnChange({
              ...formData,
              marketingQuestions: {
                ...formData.marketingQuestions,
                howDidYouHear: marketingQuestions.howDidYouHear,
                howDidYouBookYourTrip: marketingQuestions.howDidYouBookYourTrip,
              },
            });
          }}
          backButtonClick={backButtonClick}
          formCallBack={formCallBack}
        />
      );
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.container}>
          <AppLogo height="4rem" />
        </div>
        <LineStep stepCount={3} step={step} customClassName={styles.lineStep} />
      </div>

      <FullCenter centerY={false} maxWidth={700} customClassName={styles.background}>
        {step === 1 && <h4 className={`${styles.mainTitle} center pt12`}>Feels Like Summer Application</h4>}
        <h5 className={`${styles.title} center pt12  mb5`}>{stepHeader}</h5>
        <FormContainer>{renderItems()}</FormContainer>
      </FullCenter>
      <div className={styles.backgroundImage} />
    </div>
  );
};
