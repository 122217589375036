import React, { useContext } from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import Model from '@tripian/model';
import * as ROUTER_PATH_TITLE from '../../const/ROUTER_PATH_TITLE';
import UserContext from '../../context/User/UserContext';
import { WaitingVerifyPage } from '../../pages/business/WaitingVerifyPage/WaitingVerifyPage';
import { BusinessAssignPage } from '../../pages/business/BusinessAssignPage/BusinessAssignPage';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateCampaignAdminRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const { payloadData } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(routeProps: RouteComponentProps) => {
        // console.log('payloadData', payloadData);

        if (payloadData.user) {
          if (payloadData.user.userType === Model.USER_TYPE.COUPON_ADMIN) {
            return <Component {...routeProps} />;
          } else {
            if (payloadData.user.userType === Model.USER_TYPE.SUPER_ADMIN)
              return <Redirect to={{ pathname: ROUTER_PATH_TITLE.BUSINESS_OFFER_REPORT.PATH }} />;

            if (payloadData.business) {
              if (payloadData.business.isVerified) {
                return <Redirect to={{ pathname: ROUTER_PATH_TITLE.OFFERS.PATH }} />;
              }
              return <WaitingVerifyPage />;
            }
            return <BusinessAssignPage />;
          }
        }

        if (routeProps.location.pathname === '/') return <Redirect to={{ pathname: ROUTER_PATH_TITLE.WELCOME.PATH }} />;

        return <Redirect to={{ pathname: ROUTER_PATH_TITLE.LOGIN.PATH }} />;
      }}
    />
  );
};

export default PrivateCampaignAdminRoute;
