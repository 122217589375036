/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from 'react';
import Model from '@tripian/model';
import { Button } from '../Base/Button/Button';
import { FormContainer } from '../Base/FormContainer/FormContainer';
import { MessageModal } from '../Base/MessageModal/MessageModal';
import { Modal } from '../Base/Modal/Modal';
import { AppLoader } from '../AppLoader/AppLoader';
import { OfferItem } from './OfferItem/OfferItem';

import styles from './OfferItemList.module.scss';
import { useConfirm } from './useConfirm';
import { useOffers } from './useOffers';
import { useOffersMenuEvents } from './useOffersMenuEvents';

type OfferStatusType = 'Active' | 'Inactive' | 'Archived';

export interface OfferItemListProps {
  status: OfferStatusType;
  myStatus: OfferStatusType;
  businessName: string;
  refreshCursor: number;
  refreshOffers: () => void;
}

export const OfferItemList: React.FC<OfferItemListProps> = ({ myStatus, status, businessName, refreshCursor, refreshOffers }) => {
  const meActive = useMemo(() => myStatus === status, [myStatus, status]);

  const { loading, offers, loadMoreActive, onLoadMore, reset } = useOffers(myStatus, meActive);
  const {
    loading: loadingEvent,
    warningMessage,
    setWarningMessage,
    /* errorMessage, */
    onItemClick,
    onChangePhoto,
    onDuplicate,
    onInactivate,
    onActivate,
    onArchive,
    onDelete,
  } = useOffersMenuEvents();
  const { confirm, confirmOnInactivate, confirmOnActivate, confirmOnDelete, resetConfirmState } = useConfirm(onInactivate);

  useEffect(() => {
    if (0 < refreshCursor) reset();
  }, [refreshCursor, reset]);

  return (
    <div className={`${styles.offerItemList} ${meActive ? '' : styles.offerItemListHide}`}>
      <AppLoader active={loading || loadingEvent} fixed={loadingEvent} minHeight="20rem" transparent={!loadingEvent}>
        {loading === false && offers.length === 0 ? (
          <div className={styles.emptyMessageDiv}>
            <div className={styles.emptyMessage}>No {status} Offers</div>
            {status ? <div className={styles.emptyMessage}>Select + below to create an offer</div> : null}
          </div>
        ) : null}
        {offers.map((offer: Model.Offer) => (
          <FormContainer key={`offer-item-form-con-${offer.id}`} customClassName={styles.formContainer}>
            <OfferItem
              offer={offer}
              businessName={businessName}
              onClick={() => onItemClick(offer)}
              customerCount={offer.usage}
              onChangePhoto={onChangePhoto}
              onDuplicate={onDuplicate}
              onInactivate={(o: Model.Offer) =>
                confirmOnInactivate(o).then((o) => {
                  if (o) refreshOffers();
                })
              }
              onActivate={confirmOnActivate}
              onArchive={(o: Model.Offer) => onArchive(o).then(() => refreshOffers())}
              onDelete={confirmOnDelete}
            />
          </FormContainer>
        ))}
        {loadMoreActive ? (
          <AppLoader active={loading} transparent={true} className="pt4">
            <div className="pt4">
              <Button type="secondary" onClick={() => onLoadMore()} label={'Load More'} customClassName={styles.loadMoreBtn} />
            </div>
          </AppLoader>
        ) : null}
      </AppLoader>

      {confirm !== undefined ? (
        <Modal
          show={confirm !== undefined}
          message={confirm.message}
          confirmText={confirm.type === 'activate' ? 'Activate' : confirm.type === 'inactivate' ? 'Make Inactive' : 'Delete'}
          cancelText="Cancel"
          onConfirm={() => {
            if (confirm.type === 'activate')
              onActivate(confirm.offer).then((o) => {
                if (o) {
                  resetConfirmState();
                  refreshOffers();
                }
              });
            else if (confirm.type === 'inactivate')
              onInactivate(confirm.offer).then(() => {
                resetConfirmState();
                refreshOffers();
              });
            else
              onDelete(confirm.offer).then(() => {
                resetConfirmState();
                reset();
              });
          }}
          onClose={() => resetConfirmState()}
        />
      ) : null}

      <MessageModal
        show={warningMessage !== ''}
        message={warningMessage}
        onClose={() => {
          setWarningMessage('');
          resetConfirmState();
        }}
      />
    </div>
  );
};
