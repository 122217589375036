import React, { useEffect, useState } from 'react';
import styles from './Bonuses.module.scss';
import { Icon } from '../../../../Base/Icon/Icon';
import { RSelect } from '../../../../RSelect/RSelect';
import { nameOfProperty } from '../../../../CouponApplicationForm/constant';
import { helper } from '@tripian/model';
import NumberInput from '../../../../Base/NumberInput/NumberInput';
import { Button } from '../../../../Base/Button/Button';

interface Props {
  bonuses: { name: string; bonus: number }[];
  onChange: (newBonuses: { name: string; bonus: number }[]) => void;
}

export const Bonuses: React.FC<Props> = ({ bonuses, onChange }) => {
  const [options, setOptions] = useState<{ id: number; label: string }[]>(
    nameOfProperty
      .sort((a, b) => helper.compareStringForSort(a.name, b.name))
      .map((hotel) => ({
        id: hotel.id,
        label: hotel.name,
        payload: hotel,
      })),
  );
  const [bonus, setBonus] = useState<{ name: string | undefined; bonus: number }>({ name: undefined, bonus: -1 });

  useEffect(() => {
    setOptions(
      nameOfProperty
        .filter((hotel) => !bonuses.find((bonus) => bonus.name === hotel.name))
        .map((hotel) => ({
          id: hotel.id,
          label: hotel.name,
        })),
    );
  }, [bonuses]);

  const callbackBonusAdd = (name: string, bonus: number) => {
    setBonus({ name: undefined, bonus: 0 });
    const newBonuses = [...bonuses, { name, bonus }];
    onChange(newBonuses);
  };

  const callbackBonusRemove = (index: number) => {
    const newBonuses = [...bonuses];
    newBonuses.splice(index, 1);
    onChange(newBonuses);
  };

  return (
    <div className={styles.content}>
      <div className="row mb0">
        <div className="col col5  p0 pr1">
          <RSelect
            options={options}
            defaultOptionId={bonus.name ? options.find((n) => n.label === bonus.name)?.id : undefined}
            placeHolder="Please select"
            onSelectedChanged={(selectedOption) =>
              setBonus((prev) => ({
                ...prev,
                name: selectedOption.label,
              }))
            }
          />
        </div>
        <div className="col col5 p0">
          <NumberInput
            customClassName={styles.numberInput}
            name="bonusAmount"
            placeholder="Enter bonus amount"
            minValue={0}
            value={bonus.bonus > -1 ? bonus.bonus : undefined}
            onChange={(value) => setBonus((prev) => ({ ...prev, bonus: value }))}
          />
        </div>
        {/* <input type="number" value={bonus.bonus} onChange={(e) => setBonus((prev) => ({ ...prev, bonus: Number(e.target.value) }))} /> */}
        <div className="col col12 col2-m p0">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              if (bonus.name) callbackBonusAdd(bonus.name, bonus.bonus);
            }}
            label="Add"
            disabled={bonus.name === undefined || bonus.bonus < 1}
            customClassName={styles.customButton}
          />
          {/* <button onClick={() => callbackBonusAdd(bonus.name, bonus.bonus)}>Add</button> */}
        </div>
      </div>
      <div>
        {bonuses.map((bonus, i) => (
          <div key={`${bonus.name}`} className={styles.rangeItem}>
            <span className={styles.rangeBox}>{bonus.name}</span>
            <span className={styles.to}>:</span>
            <span className={styles.rangeBox}>{bonus.bonus} BBD$</span>
            <button className={styles.closeButton} onClick={() => callbackBonusRemove(i)}>
              <Icon name="CLOSE" color="#571BCE" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
