import React from 'react';
import Model from '@tripian/model';
import { IconRow } from '../../../../IconRow/IconRow';
import moment from 'moment';
import { blackouts2Ranges } from '../../../../../helper/blackouts2Ranges';
import { getDaysOfWeek } from '../../../../../helper/getDaysOfWeek';
import styles from './CampaignSummary.module.scss';

type Props = {
  campaign: Model.CampaignAddRequest;
};

export const CampaignSummary: React.FC<Props> = ({ campaign }) => {
  const dateRanges = blackouts2Ranges(campaign.blackouts);
  const days = getDaysOfWeek(campaign.recurrent);

  return (
    <div className={styles.campaignSummary}>
      <div className="row">
        <div className="col col12">
          <h5>{campaign.title}</h5>
          <hr className="mt4 mb2" />
        </div>
        <div className="col col12">
          <IconRow
            icon="CLOCK"
            header="Eligibility period"
            text={`${moment(campaign.startDate).format('MMM D YYYY')} - ${moment(campaign.endDate).format('MMM D YYYY')}`}
          />
          <IconRow icon="RECURRENT" header="Recurrent" text={days.join(',')} />
          <IconRow
            icon="BLACKOUT_DAY"
            header="Blackout Days"
            text={dateRanges.map((d) => (
              <div>
                <span>{moment(d.startDate).format('MMM D YYYY')}</span>-<span>{moment(d.endDate).format('MMM D YYYY')}</span>
              </div>
            ))}
          />
          <IconRow icon="IS_FLEXIBLE" header="Is flexible" text={campaign.isAmountMax ? 'Yes' : 'No'} />
          <IconRow
            icon="BONUS"
            header="Bonus"
            text={campaign.bonus.map((b) => (
              <div>
                <span>{b.name}</span>-<span>BBD$ {b.bonus}</span>
              </div>
            ))}
          />
          <IconRow icon="CAMPAIGN_LIMIT" header="Campaign Limit" text={`${Model.OFFER_CURRENCY.BBD}$${campaign.maximumOfferAmount.toString()}`} />
          {campaign.limited === false && (
            <IconRow icon="AVAILABLE" header="Available number of recipients" text={campaign.maximumRecipients.toString()} />
          )}
        </div>
      </div>
    </div>
  );
};
