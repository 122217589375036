import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as ROUTER_PATH_TITLE from '../../const/ROUTER_PATH_TITLE';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import PrivateCampaignAdminRoute from '../PrivateRoute/PrivateCampaignAdminRoute';
/**
 * Pages
 */
import { PageLayout } from '../PageLayout/PageLayout';
// public
import { WelcomePage } from '../../pages/public/WelcomePage/WelcomePage';
import { PreRegisterPage } from '../../pages/public/PreRegisterPage/PreRegisterPage';
import { SignUpPage } from '../../pages/public/SignUpPage/SignUpPage';
import { LoginPage } from '../../pages/public/LoginPage/LoginPage';
import { ForgotPasswordPage } from '../../pages/public/ForgotPasswordPage/ForgotPasswordPage';
import { NotFoundPage } from '../../pages/public/NotFoundPage/NotFoundPage';
import { CouponApplicationFormPage } from '../../pages/campaign/CouponApplicationFormPage/CouponApplicationFormPage';
// user
import { NewPasswordPage } from '../../pages/user/NewPasswordPage/NewPasswordPage';
import { UserSettingsPage } from '../../pages/user/UserSettingsPage/UserSettingsPage';
// business
import { BusinessProfilePage } from '../../pages/business/BusinessProfilePage/BusinessProfilePage';
import { OffersPage } from '../../pages/business/OffersPage/OffersPage';
import { CreateOfferPage } from '../../pages/business/CreateOfferPage/CreateOfferPage';
import { OfferPreviewPage } from '../../pages/business/OfferPreviewPage/OfferPreviewPage';
import { ChangePohotoPage } from '../../pages/business/ChangePhotoPage/ChangePhotoPage';
import { BusinessAssignPage } from '../../pages/business/BusinessAssignPage/BusinessAssignPage';
import { QrReaderPage } from '../../pages/business/QrReaderPage/QrReaderPage';
import { OfferPaymentPage } from '../../pages/business/OfferPaymentPage/OfferPaymentPage';
// campaign
import { CampaignsPage } from '../../pages/campaign/CampaignsPage/CampaignsPage';
import { CampaignCreatePage } from '../../pages/campaign/CampaignCreatePage/CampaignCreatePage';
import { CampaignUpdatePage } from '../../pages/campaign/CampaignUpdatePage/CampaignUpdatePage';
import { CampaignReportPage } from '../../pages/campaign/CampaignReportPage/CampaignReportPage';
import { CampaignCustomerReportPage } from '../../pages/campaign/CampaignCustomerReportPage/CampaignCustomerReportPage';
import { ApplicationsPage } from '../../pages/campaign/ApplicationsPage/ApplicationsPage';
import { ApplicationDetailsPage } from '../../pages/campaign/ApplicationDetailsPage/ApplicationDetailsPage';
import { BusinessPage } from '../../pages/campaign/BusinessPage/BusinessPage';
// Super Admin
import { BusinessOfferReportPage } from '../../pages/business/BusinessOfferReportPage/BusinessOfferReportPage';
import PrivateSuperAdminRoute from '../PrivateRoute/PrivateSuperAdminRoute';

const AppRouter = () => {
  return (
    <Router>
      <PageLayout>
        <Switch>
          <PrivateRoute exact path="/" component={OffersPage} />
          <PrivateRoute exact path={ROUTER_PATH_TITLE.BUSINESS_ASSIGN.PATH} component={BusinessAssignPage} />
          <PrivateRoute exact path={ROUTER_PATH_TITLE.OFFERS.PATH} component={OffersPage} />
          <PrivateRoute exact path={ROUTER_PATH_TITLE.OFFER_CREATE.PATH} component={CreateOfferPage} />
          <PrivateRoute exact path={`${ROUTER_PATH_TITLE.OFFER_PREVIEW.PATH}/:id`} component={OfferPreviewPage} />
          <PrivateRoute exact path={ROUTER_PATH_TITLE.BUSINESS_PROFILE.PATH} component={BusinessProfilePage} />
          <PrivateRoute exact path={ROUTER_PATH_TITLE.USER_SETTINGS.PATH} component={UserSettingsPage} />
          <PrivateRoute exact path={`${ROUTER_PATH_TITLE.CHANGE_PHOTO.PATH}/:id`} component={ChangePohotoPage} />
          <PrivateRoute exact path={ROUTER_PATH_TITLE.QR_READER.PATH} component={QrReaderPage} />
          <PrivateRoute exact path={`${ROUTER_PATH_TITLE.OFFER_PAYMENT.PATH}/:offerId/:userId`} component={OfferPaymentPage} />

          <PrivateSuperAdminRoute exact path={`${ROUTER_PATH_TITLE.BUSINESS_OFFER_REPORT.PATH}`} component={BusinessOfferReportPage} />

          <PrivateCampaignAdminRoute exact path={ROUTER_PATH_TITLE.CAMPAIGNS.PATH} component={CampaignsPage} />
          <PrivateCampaignAdminRoute exact path={ROUTER_PATH_TITLE.CAMPAIGN_CREATE.PATH} component={CampaignCreatePage} />
          <PrivateCampaignAdminRoute exact path={ROUTER_PATH_TITLE.CAMPAIGN_UPDATE.PATH} component={CampaignUpdatePage} />
          <PrivateCampaignAdminRoute exact path={`${ROUTER_PATH_TITLE.CAMPAIGN_REPORT.PATH}/:id`} component={CampaignReportPage} />
          <PrivateCampaignAdminRoute exact path={`${ROUTER_PATH_TITLE.CAMPAIGN_CUSTOMER_REPORT.PATH}/:id`} component={CampaignCustomerReportPage} />
          <PrivateCampaignAdminRoute exact path={ROUTER_PATH_TITLE.BUSINESS_REPORT.PATH} component={BusinessPage} />
          <PrivateCampaignAdminRoute exact path={ROUTER_PATH_TITLE.APPLICATIONS.PATH} component={ApplicationsPage} />
          <PrivateCampaignAdminRoute exact path={`${ROUTER_PATH_TITLE.APPLICATION_DETAILS.PATH}/:id`} component={ApplicationDetailsPage} />
          <PrivateCampaignAdminRoute exact path={ROUTER_PATH_TITLE.CAMPAIGN_ADMIN_USER_SETTINGS.PATH} component={UserSettingsPage} />

          <Route path={ROUTER_PATH_TITLE.LOGIN.PATH} component={LoginPage} />
          <Route path={ROUTER_PATH_TITLE.PREREGISTER.PATH} component={PreRegisterPage} />
          <Route path={ROUTER_PATH_TITLE.SIGNUP.PATH} component={SignUpPage} />
          <Route path={ROUTER_PATH_TITLE.FORGOT_PASSWORD.PATH} component={ForgotPasswordPage} />
          <Route path={`${ROUTER_PATH_TITLE.NEW_PASSWORD.PATH}/:hash`} exact component={NewPasswordPage} />
          <Route path={ROUTER_PATH_TITLE.WELCOME.PATH} component={WelcomePage} />

          <Route path={ROUTER_PATH_TITLE.COUPON_APPLICATION_FORM.PATH} component={CouponApplicationFormPage} />
          <Route path={ROUTER_PATH_TITLE.NOT_FOUND.PATH} component={NotFoundPage} />
        </Switch>
      </PageLayout>
    </Router>
  );
};

export default AppRouter;
