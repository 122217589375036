import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Model from '@tripian/model';
import { api } from '@tripian/core';

import { Button } from '../../../components/Base/Button/Button';
import { FormContainer } from '../../../components/Base/FormContainer/FormContainer';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { InputLabel } from '../../../components/Base/InputLabel/InputLabel';
import { OfferImage } from '../../../components/OfferForm/steps/3/OfferImage/OfferImage';
import { AppLoader } from '../../../components/AppLoader/AppLoader';
import { CHANGE_PHOTO, OFFERS } from '../../../const/ROUTER_PATH_TITLE';
import UserContext from '../../../context/User/UserContext';
import { errorMessage, successMessage } from '../../../helper/toastr';
import styles from './ChangePhotoPage.module.scss';

export const ChangePohotoPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [initLoading, setInitLoading] = useState<boolean>(true);

  const [changed, setChanged] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  const { payloadData } = useContext(UserContext);

  // const businessImage: Model.Image = {
  //   imageOwner: payloadData.business?.image.imageOwner || null,
  //   url: payloadData.business?.image.url || imageUrl || '',
  //   width: 640,
  //   height: 480,
  // };

  const businessImageUrl: string = payloadData.business?.image.url || '';

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      api
        .businessOffer(Number(id))
        .then((offerResponse: Model.Offer) => setImageUrl(offerResponse.imageUrl))
        .finally(() => {
          setInitLoading(false);
        });
    }

    return () => {
      unmounted = true;
    };
  }, [id]);

  document.title = CHANGE_PHOTO.TITLE;

  const goHome = () => history.push(OFFERS.PATH);

  const uploadImage = (imageFile: string): Promise<{ url: string }> => api.businessOfferFileUpload(imageFile);

  const updateOfferImage = () => {
    setLoading(true);

    api
      .businessOfferImageUpdate(Number(id), imageUrl || businessImageUrl)
      .then(() => {
        successMessage('Offer image updated successfuly !');
        goHome();
      })
      .catch((err) => {
        errorMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (initLoading) return <AppLoader fixed className={styles.changeImagePageLoader} active={initLoading} />;

  return (
    <FullCenter maxWidth={600} centerY={false}>
      <FormContainer>
        {loading ? (
          <div className="action-loader">
            <AppLoader active={loading} />
          </div>
        ) : null}

        <InputLabel text="Change Photo" customClassName="mb4" />
        <OfferImage
          imageUrl={imageUrl}
          upload={uploadImage}
          onChange={(imageUrl?: string) => {
            setChanged(true);
            setImageUrl(imageUrl);
          }}
          uploading={() => {}}
        />
        <div className={`${styles.buttonsDiv} row`}>
          <div className="col col12 col6-m">
            <Button fluid label="Cancel Update" type="secondary" onClick={() => goHome()} />
          </div>
          <div className="col col12 col6-m">
            <Button fluid type="primary" label="Confirm Update" disabled={changed === undefined} onClick={() => updateOfferImage()} />
          </div>
        </div>
      </FormContainer>
    </FullCenter>
  );
};
