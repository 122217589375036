interface IRouteProps {
  PATH: string;
  TITLE: string;
}

// public routes
export const PREREGISTER: IRouteProps = { PATH: '/preregister', TITLE: 'Pre-Register' };
export const SIGNUP: IRouteProps = { PATH: '/signup', TITLE: 'Signup' };
export const LOGIN: IRouteProps = { PATH: '/login', TITLE: 'Login' };
export const WELCOME: IRouteProps = { PATH: '/welcome', TITLE: 'Welcome' };
export const FORGOT_PASSWORD: IRouteProps = { PATH: '/forgot-password', TITLE: 'Forgot Password' };
export const NEW_PASSWORD: IRouteProps = { PATH: '/new-password', TITLE: 'New Password' };

// private routes
export const BUSINESS_ASSIGN: IRouteProps = { PATH: '/business-assign', TITLE: 'Business Assign' };
export const USER_SETTINGS: IRouteProps = { PATH: '/user-settings', TITLE: 'User Settings' };
export const BUSINESS_PROFILE: IRouteProps = { PATH: '/business-profile', TITLE: 'Business Profile' };

export const OFFERS: IRouteProps = { PATH: '/offers', TITLE: 'Offers' };
// export const OFFERS_HISTORY: IRouteProps = { PATH: '/offers-history', TITLE: 'Offers History' };
export const OFFER_CREATE: IRouteProps = { PATH: '/create-offer', TITLE: 'Create Offer' };
export const OFFER_PREVIEW: IRouteProps = { PATH: '/offer-preview', TITLE: 'Offer Preview' };
export const OFFER_EDIT: IRouteProps = { PATH: '/edit-offer', TITLE: 'Edit Offer' };
export const CHANGE_PHOTO: IRouteProps = { PATH: '/change-photo', TITLE: 'Change Photo' };
export const QR_READER: IRouteProps = { PATH: '/qr-reader', TITLE: 'Offer Payment QR Reader' };
export const OFFER_PAYMENT: IRouteProps = { PATH: '/offer-payment', TITLE: 'Offer Payment' };

// coupon
export const COUPON_APPLICATION_FORM: IRouteProps = { PATH: '/feelslikesummer', TITLE: 'Feels Like Summer Application Form' };

// campaign admin
export const CAMPAIGNS: IRouteProps = { PATH: '/campaigns', TITLE: 'Campaigns' };
export const CAMPAIGN_CREATE: IRouteProps = { PATH: '/create-campaign/', TITLE: 'Create Campaign' };
export const CAMPAIGN_UPDATE: IRouteProps = { PATH: '/update-campaign/', TITLE: 'Update Campaign' };
export const CAMPAIGN_REPORT: IRouteProps = { PATH: '/campaign-report', TITLE: 'Campaign Report' };
export const CAMPAIGN_CUSTOMER_REPORT: IRouteProps = { PATH: '/campaign-customer-report', TITLE: 'Campaign Customer Report' };
export const BUSINESS_REPORT: IRouteProps = { PATH: '/business-report', TITLE: 'Business Report' };
export const APPLICATIONS: IRouteProps = { PATH: '/applications', TITLE: 'Applications' };
export const APPLICATION_DETAILS: IRouteProps = { PATH: '/application', TITLE: 'Application' };
export const CAMPAIGN_ADMIN_USER_SETTINGS: IRouteProps = { PATH: '/campaign-admin-user-settings', TITLE: 'User Settings' };

// Super Admin
export const BUSINESS_OFFER_REPORT: IRouteProps = { PATH: '/business-offer-report', TITLE: 'Business Offer Report' };

// not-found
export const NOT_FOUND: IRouteProps = { PATH: '*', TITLE: 'Page Not Found :(' };
