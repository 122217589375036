import { useState } from 'react';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { TabMenu } from '../../../components/TabMenu/TabMenu';
import { BusinessReportPage } from '../BusinessReportPage/BusinessReportPage';
import styles from './BusinessPage.module.scss';
import { BusinessPreRegisterReportPage } from '../BusinessPreRegisterReportPage/BusinessPreRegisterReportPage';

enum TAB {
  WAITING_FOR_APPROVAL = 'waiting for approval',
  APPROVED = 'approved',
}

const tabItems: string[] = [TAB.WAITING_FOR_APPROVAL, TAB.APPROVED];

export const BusinessPage = () => {
  const [selectedTab, setSelectedTab] = useState<string>(tabItems[0]);

  return (
    <FullCenter centerY={false} customClassName={styles.fullCenter}>
      <TabMenu selectedMenuItem={selectedTab} menuItems={tabItems} onClick={(stab: string) => setSelectedTab(stab as any)} />
      {selectedTab === TAB.APPROVED && <BusinessReportPage />}
      {selectedTab === TAB.WAITING_FOR_APPROVAL && <BusinessPreRegisterReportPage />}
    </FullCenter>
  );
};
