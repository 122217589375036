/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import Model from '@tripian/model';
import { api, easy } from '@tripian/core';
import { CAMPAIGNS, CAMPAIGN_CREATE } from '../../../const/ROUTER_PATH_TITLE';
import { errorMessage } from '../../../helper/toastr';
import { TabMenu } from '../../../components/TabMenu/TabMenu';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { CampaignItem } from '../../../components/CampaignItem/CampaignItem';
import styles from './CampaignsPage.module.scss';

const tabItems: string[] = ['active', 'inactive'];

export const CampaignsPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [campaigns, setCampaigns] = useState<Model.Campaign[]>([]);
  const [selectedTab, setSelectedTab] = useState<Model.CAMPAIGN_STATUS>(Model.CAMPAIGN_STATUS.ACTIVE);
  // const [refreshCursor, setRefreshCursor] = useState<number>(0);

  const history = useHistory();
  document.title = CAMPAIGNS.TITLE;

  useEffect(() => {
    api
      .businessCampaigns(1, 100)
      .then((cs) => setCampaigns(cs.data))
      .catch((err) => errorMessage(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const td = new easy.TDate({ startDate: '2023-12-19', endDate: '2024-04-10', recurrent: [0, 1, 2, 3, 4, 5, 6], blackouts: [] });
    (window as any).td = td;
  }, []);

  const filteredCampaigns = useMemo(() => {
    return campaigns.filter((x) => x.status === selectedTab);
  }, [campaigns, selectedTab]);

  return (
    <FullCenter centerY={false} customClassName={styles.fullCenter} loading={loading}>
      <TabMenu selectedMenuItem={selectedTab} menuItems={tabItems} onClick={(stab: string) => setSelectedTab(stab as any)} />
      <div className={styles.uu}>
        {filteredCampaigns.map((campaign, index) => (
          <CampaignItem key={`campaign-item-${index}`} campaign={campaign} />
        ))}
        <a className={styles.float} onClick={() => history.push(CAMPAIGN_CREATE.PATH)}>
          <i className="fa fa-plus"></i>
        </a>
      </div>
    </FullCenter>
  );
};
