import moment from 'moment';
import { TableBody, TableCell, TableRow } from '@mui/material';
import Model from '@tripian/model';
import classes from './ReportTableBody.module.scss';

type Props = {
  data: Model.CampaignReport;
  onPay: (id: number) => void;
};

export const ReportTableBody: React.FC<Props> = ({ data, onPay }) => {
  return (
    <TableBody>
      {data.map((data, i) => (
        <TableRow key={`${data.customerUserId}-${data.offerId}-${i}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {data.id}
          </TableCell>
          <TableCell>{data.businessName}</TableCell>
          <TableCell>{data.offerName}</TableCell>
          <TableCell>{data.customerFullName}</TableCell>
          <TableCell>{moment(data.customerArrivalDate).format('MMM D')}</TableCell>
          <TableCell>{moment(data.customerDepartureDate).format('MMM D')}</TableCell>
          <TableCell>{moment(data.customerClaimDate).format('MMM D')}</TableCell>
          <TableCell>{moment(data.customerRedeemDateTime).format('MMM D - hh:mmA')}</TableCell>
          <TableCell>BBD${data.offerDiscount}</TableCell>
          {data.reimbursedDateTime ? (
            <TableCell>{moment(data.reimbursedDateTime).format('MMM D - hh:mmA')}</TableCell>
          ) : (
            <TableCell>
              <span className={classes.pay} onClick={() => onPay(data.id)}>
                PAY
              </span>
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};
