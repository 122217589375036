import { useCallback } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Icon } from '../../../../../components/Base/Icon/Icon';
import styles from './ReportTableHeader.module.scss';
import Model from '@tripian/model';

type Props = {
  orderColumnIndex: number;
  setOrderColumnIndex: (newOrderColumnIndex: number) => void;
  orderAsc: boolean;
  setOrderAsc: (newOrderAsc: boolean) => void;
  query: Partial<Model.BusinessReportRequest>;
  setQuery: (newFilter: Partial<Model.BusinessReportRequest>) => void;
};
export const ReportTableHeader: React.FC<Props> = ({ orderColumnIndex, setOrderColumnIndex, orderAsc, setOrderAsc, query, setQuery }) => {
  const rowHeaderClicked = useCallback(
    (index: number, columnName: string) => {
      let newOrder = `${columnName},`;

      if (orderColumnIndex === index) {
        setOrderAsc(!orderAsc);
        newOrder += `${orderAsc === true ? 'desc' : 'asc'}`;
      } else {
        setOrderColumnIndex(index);
        setOrderAsc(true);
        newOrder += 'asc';
      }

      // const newOrder = `${columnName},${orderAsc === true ? 'asc' : 'desc'}`;

      setQuery({ ...query, order: newOrder });
    },
    [orderAsc, orderColumnIndex, query, setOrderAsc, setOrderColumnIndex, setQuery],
  );

  return (
    <TableRow style={{ cursor: 'pointer' }} className={styles.tableHeader}>
      <TableCell onClick={() => rowHeaderClicked(-1, 'id')} className={styles.tableSmallWidth}>
        <div className={styles.tableHeaderContent}>
          ID {orderColumnIndex === -1 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(0, 'businessName')}>
        <div className={styles.tableHeaderContent}>
          Business Name {orderColumnIndex === 0 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(1, 'offerTitle')}>
        <div className={styles.tableHeaderContent}>
          Offer Title {orderColumnIndex === 1 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(2, 'fullName')}>
        <div className={styles.tableHeaderContent}>
          Full Name {orderColumnIndex === 2 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(3, 'arrivalDate')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Arrival Date {orderColumnIndex === 3 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(4, 'departureDate')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Departure Date {orderColumnIndex === 4 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(5, 'claimDate')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Claim Date {orderColumnIndex === 5 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(6, 'redeemDate')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Redeem Datetime {orderColumnIndex === 6 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(7, 'amount')}>
        <div className={styles.tableHeaderContent}>
          Amount {orderColumnIndex === 7 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell onClick={() => rowHeaderClicked(8, 'reimbursedDate')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Reimbursed {orderColumnIndex === 8 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
    </TableRow>
  );
};
