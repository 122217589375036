/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Select from 'react-select';
import './AutoComplete.module.scss';

export interface AutoCompletePropsOption {
  id: number | string;
  label: string;
  extra?: string;
}

export interface AutoCompleteProps {
  value?: AutoCompletePropsOption | null;
  options: Array<AutoCompletePropsOption>;
  onChange: (value: string) => void;
  onSelect: (value: AutoCompletePropsOption) => void;
  loading?: boolean;
  placeholder?: string;
}

export const AutoComplete: React.FC<AutoCompleteProps> = ({ value, options, onChange, onSelect, loading, placeholder }) => {
  const formatOptionLabel = (option: AutoCompletePropsOption) => (
    <div className="labelDiv">
      <div>{option.label}</div>
      <div className="extraOption">{option.extra}</div>
    </div>
  );

  const noOptionsMessage = (obj: { inputValue: string }) => {
    if (obj.inputValue.length > 2) {
      if (!loading && options.length === 0) {
        return <span>No Option Found.</span>;
      }
      return <span>Loading...</span>;
    }
    return null;
  };

  return (
    <>
      <Select
        onInputChange={onChange}
        options={options}
        isSearchable
        isLoading={loading}
        placeholder={placeholder}
        className="rSelect"
        classNamePrefix="rSelect"
        isClearable
        onChange={(autoCompletePropsOption: any) => {
          onSelect(autoCompletePropsOption as AutoCompletePropsOption);
        }}
        value={value}
        formatOptionLabel={formatOptionLabel}
        filterOption={() => {
          return true;
        }}
        noOptionsMessage={noOptionsMessage}
      />
    </>
  );
};
