import { api } from '@tripian/core';
import Model from '@tripian/model';
import { useEffect, useMemo, useState } from 'react';
import { ApplicationCard } from '../../../components/ApplicationCard/ApplicationCard';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { TabMenu } from '../../../components/TabMenu/TabMenu';
import { Button } from '../../../components/Base/Button/Button';
import { CircularProgress } from '@mui/material';
import { removeDuplicateValuesById } from '../../../helper/removeDuplicateValuesById';
import styles from './ApplicationsPage.module.scss';

enum TAB {
  WAITING = 'Waiting',
  REVIEWED = 'Reviewed',
}

const tabItems: string[] = [TAB.WAITING, TAB.REVIEWED];

export const ApplicationsPage = () => {
  const [selectedTab, setSelectedTab] = useState<string>(tabItems[0]);

  // Waiting Applications
  const [waitingApplicationsloading, setWaitingApplicationsLoading] = useState<boolean>(false);
  const [waitingApplications, setWaitingApplications] = useState<Model.CouponApplication[] | undefined>(undefined);
  const [waitingApplicationsFinished, setWaitingApplicationsFinished] = useState(false);
  const [waitingApplicationsPageIndex, setWaitingApplicationsPageIndex] = useState(1);

  // Reviewed Applications
  const [reviewedApplicationsloading, setReviewedApplicationsLoading] = useState<boolean>(false);
  const [reviewedApplications, setReviewedApplications] = useState<Model.CouponApplication[] | undefined>(undefined);
  const [reviewedApplicationsFinished, setReviewedApplicationsFinished] = useState(false);
  const [reviewedApplicationsPageIndex, setReviewedApplicationsPageIndex] = useState(1);

  // Load more
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      // setApplicationsFinished(false)

      if (selectedTab === TAB.WAITING && !waitingApplications) {
        setWaitingApplicationsFinished(false);
        setWaitingApplicationsLoading(true);
        api
          .businessCouponApplications(1, 'waiting')
          .then((applications) => {
            setWaitingApplications(applications.data);
            if (applications.pagination?.currentPage === applications.pagination?.totalPages) {
              setWaitingApplicationsFinished(true);
            }
          })
          .finally(() => setWaitingApplicationsLoading(false));
      }
      if (selectedTab === TAB.REVIEWED && !reviewedApplications) {
        setReviewedApplicationsFinished(false);
        setReviewedApplicationsLoading(true);
        api
          .businessCouponApplications(1, 'reviewed')
          .then((applications) => {
            setReviewedApplications(applications.data);
            if (applications.pagination?.currentPage === applications.pagination?.totalPages) {
              setReviewedApplicationsFinished(true);
            }
          })
          .finally(() => setReviewedApplicationsLoading(false));
      }
    };
    fetchApplications();
  }, [reviewedApplications, selectedTab, waitingApplications]);

  const waitingApplicationsLoadMoreOnClick = async () => {
    if (waitingApplications) {
      setLoadMoreLoading(true);
      // page index
      const newPageIndex = waitingApplicationsPageIndex + 1;
      setWaitingApplicationsPageIndex(newPageIndex);
      const newApplications = await api.businessCouponApplications(newPageIndex, 'waiting');
      // set results

      setWaitingApplications((prevApplicationsResult) => {
        if (prevApplicationsResult) {
          return removeDuplicateValuesById<Model.CouponApplication>(prevApplicationsResult.concat(newApplications.data));
        }
        return newApplications.data;
      });

      setLoadMoreLoading(false);
      if (newApplications.pagination?.currentPage === newApplications.pagination?.totalPages) {
        setWaitingApplicationsFinished(true);
      }
    }
  };

  const reviewedApplicationsLoadMoreOnClick = async () => {
    if (reviewedApplications) {
      setLoadMoreLoading(true);
      // page index
      const newPageIndex = reviewedApplicationsPageIndex + 1;
      setReviewedApplicationsPageIndex(newPageIndex);
      const newApplications = await api.businessCouponApplications(newPageIndex, 'reviewed');
      // set results

      setReviewedApplications((prevApplicationsResult) => {
        if (prevApplicationsResult) {
          return removeDuplicateValuesById<Model.CouponApplication>(prevApplicationsResult.concat(newApplications.data));
        }
        return newApplications.data;
      });

      setLoadMoreLoading(false);
      if (newApplications.pagination?.currentPage === newApplications.pagination?.totalPages) {
        setReviewedApplicationsFinished(true);
      }
    }
  };

  const loadMore = () => {
    if (waitingApplicationsloading || reviewedApplicationsloading) return null;

    if (!waitingApplicationsFinished && selectedTab === 'Waiting') {
      return <Button label="Load More" type="primary" size="small" onClick={waitingApplicationsLoadMoreOnClick} />;
    }

    if (!reviewedApplicationsFinished && selectedTab === 'Reviewed') {
      return <Button label="Load More" type="primary" size="small" onClick={reviewedApplicationsLoadMoreOnClick} />;
    }
  };

  const loadMoreByCategory = () => {
    if (
      (selectedTab === 'Waiting' && waitingApplicationsFinished === false) ||
      (selectedTab === 'Reviewed' && reviewedApplicationsFinished === false)
    ) {
      return (
        <div className="center m5">
          {loadMoreLoading ? (
            <div style={{ height: '12rem' }}>
              <CircularProgress className={styles.loadMoreLoading} color="primary" size={35} />
            </div>
          ) : (
            loadMore()
          )}
        </div>
      );
    }
  };

  const displayApplications = useMemo(() => {
    return selectedTab === 'Waiting' ? waitingApplications : reviewedApplications;
  }, [selectedTab, waitingApplications, reviewedApplications]);

  return (
    <FullCenter centerY={false} customClassName={styles.fullCenter} loading={waitingApplicationsloading || reviewedApplicationsloading}>
      <TabMenu selectedMenuItem={selectedTab} menuItems={tabItems} onClick={(stab: string) => setSelectedTab(stab as any)} />
      <div className={styles.ApplicationsWrapper}>
        {displayApplications?.map((data, i) => (
          <ApplicationCard key={i} application={data} />
        ))}
        {loadMoreByCategory()}
      </div>
    </FullCenter>
  );
};
