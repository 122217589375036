/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

const isInclusivelyAfterDay = (a: any, b: any) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !a.isBefore(b);
};

type Props = {
  isDayHighlighted: (day: moment.Moment) => boolean;
  date?: moment.Moment;
  onDateChange?: (m: moment.Moment | null) => void;
  numberOfMonths?: number;
  otherProps?: any;
};

const DayPickerSingleDateControllerWrapper: React.FC<Props> = ({ isDayHighlighted, date, onDateChange, numberOfMonths = 2, otherProps = {} }) => {
  return (
    <div>
      <DayPickerSingleDateController
        // autoFocus={false}
        // initialDate={null}
        // showInput={false}
        // day presentation and interaction related props
        // allowUnselect={false}
        renderCalendarDay={undefined}
        renderDayContents={null}
        isDayBlocked={(m: moment.Moment) => !isDayHighlighted(m)}
        isOutsideRange={(m: moment.Moment) => !isInclusivelyAfterDay(m, moment())}
        isDayHighlighted={isDayHighlighted}
        enableOutsideDays={false}
        // calendar presentation and interaction related props
        orientation={'horizontal'}
        withPortal={false}
        initialVisibleMonth={null}
        numberOfMonths={numberOfMonths}
        onOutsideClick={() => {}}
        keepOpenOnDateSelect={false}
        renderCalendarInfo={null}
        isRTL={false}
        // navigation related props
        navPrev={null}
        navNext={null}
        renderNavPrevButton={null}
        renderNavNextButton={null}
        onPrevMonthClick={() => {}}
        onNextMonthClick={() => {}}
        hideKeyboardShortcutsPanel
        // internationalization
        monthFormat={'MMMM YYYY'}
        focused={true}
        /* onDateChange={(m) => {
          console.log('onDateChange', m?.format('YYYY-MM-DD'));
        }} */
        // date={moment('2024-03-15')}
        date={date ?? null}
        onDateChange={(m: moment.Moment | null) => {
          if (m) {
            onDateChange?.(m);
            // console.log('onDateChange', m?.format('YYYY-MM-DD'));
          }
        }}
        {...otherProps}
      />
    </div>
  );
};

export default DayPickerSingleDateControllerWrapper;
