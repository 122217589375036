import { TableBody, TableCell, TableRow } from '@mui/material';
import Model from '@tripian/model';
import styles from './ReportTableBody.module.scss';

type Props = {
  data: Model.BusinessReport;
};

export const ReportTableBody: React.FC<Props> = ({ data }) => {
  return (
    <TableBody>
      {data.map((data, i) => (
        <TableRow className={styles.reportBody} key={`${data.businessUserId}-${i}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell align="center" />
          <TableCell className={styles.reportBodyItem} data-tooltip={data.businessUserEmail}>
            {data.businessUserEmail}
          </TableCell>
          <TableCell>{data.businessUserFullname}</TableCell>
          <TableCell>{data.businessPoiName}</TableCell>
          <TableCell>{data.status}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
