import React, { useMemo, useState } from 'react';
import Model from '@tripian/model';
import moment from 'moment';
import { useCampaignMenuEvents } from './useCampaignMenuEvents';
import { Icon } from '../Base/Icon/Icon';
import { IconRow } from '../IconRow/IconRow';
import styles from './CampaignItem.module.scss';

export interface Props {
  campaign: Model.Campaign;
}

export const CampaignItem: React.FC<Props> = ({ campaign }) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const { onItemClick, onCustomerReportClick, onDuplicate, onEdit } = useCampaignMenuEvents();

  const menu = useMemo(() => {
    if (campaign.status === Model.CAMPAIGN_STATUS.ACTIVE)
      return (
        <div className={`${styles.menuList} ${openMenu ? styles.menuListOpen : ''}`}>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onItemClick(campaign);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onItemClick(campaign);
            }}
          >
            <IconRow icon="BOOK_SAVED" header="Campaign Report" text="" />
          </div>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onCustomerReportClick(campaign);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onCustomerReportClick(campaign);
            }}
          >
            <IconRow icon="USER_TICK" header="Customer Report" text="" />
          </div>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onEdit(campaign);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onEdit(campaign);
            }}
          >
            <IconRow icon="EDIT" header="Edit Campaign" text="" />
          </div>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onDuplicate(campaign);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onDuplicate(campaign);
            }}
          >
            <IconRow icon="COPY" header="Duplicate" text="" />
          </div>
        </div>
      );
    else
      return (
        <div className={`${styles.menuList} ${openMenu ? styles.menuListOpen : ''}`}>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onItemClick(campaign);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onItemClick(campaign);
            }}
          >
            <IconRow icon="BOOK_SAVED" header="Campaign Report" text="" />
          </div>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onCustomerReportClick(campaign);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onCustomerReportClick(campaign);
            }}
          >
            <IconRow icon="USER_TICK" header="Customer Report" text="" />
          </div>
          <div
            className={styles.menuItem}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              onDuplicate(campaign);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
              onDuplicate(campaign);
            }}
          >
            <IconRow icon="COPY" header="Duplicate" text="" />
          </div>
        </div>
      );
  }, [campaign, onCustomerReportClick, onDuplicate, onEdit, onItemClick, openMenu]);

  return (
    <div
      className={styles.campaignItem}
      onClick={() => {
        onItemClick(campaign);
      }}
    >
      <div className="row">
        <div className="col col12">
          <div className={styles.campaignTitle}>
            <div
              className={styles.menu}
              role="button"
              tabIndex={0}
              onClick={(e) => {
                e.stopPropagation();
                setOpenMenu(true);
              }}
              onTouchStart={(e) => {
                e.stopPropagation();
                setOpenMenu(true);
              }}
              onBlur={() => setOpenMenu(false)}
            >
              <Icon name="MENU_CARD" />
              {menu}
            </div>
          </div>
          <h5 className={styles.campaignHeader}>{campaign.title}</h5>
          <hr className="mt4 mb2" />
        </div>
        <div className="col col12">
          <IconRow
            icon="CLOCK"
            header="Eligibility period"
            text={`${moment(campaign.timeframe.start).format('MMM D YYYY')} - ${moment(campaign.timeframe.end).format('MMM D YYYY')}`}
          />
          <IconRow icon="CAMPAIGN_LIMIT" header="Campaign Limit" text={`${campaign.currency}${campaign.maximumOfferAmount.toString()}`} />
          <IconRow icon="COMPONENT" header="Assigned voucher value" text={`${campaign.currency}${campaign.amount.toString()}`} />
          {campaign.maximumRecipients && campaign.limited === true && (
            <IconRow icon="AVAILABLE" header="Available number of recipients" text={campaign.maximumRecipients.toString()} />
          )}
          <IconRow icon="USER_TICK" header="Assigned voucher" text={campaign.assignedVouchers.toString()} />
        </div>
      </div>
    </div>
  );
};
