import React, { useEffect, useMemo } from 'react';
import Model from '@tripian/model';
import { Input } from '../../../../Base/Input/Input';
import NumberInput from '../../../../Base/NumberInput/NumberInput';
import { Select } from '../../../../Base/Select/Select';
import styles from './OfferTypeDetails.module.scss';

type OfferTypeDetailsProps = {
  offerType: Model.OFFER_TYPE;
  threshold: number;
  onChangeThreshold: (threshold: number) => void;
  discount: number;
  onChangeDiscount: (discount: number) => void;
  productName?: string;
  onChangeProductName: (productName: string) => void;
  giftName?: string;
  onChangeGiftName: (giftName: string) => void;
  productCount?: number;
  onChangeProductCount: (productCount: number) => void;
  campaigns: Model.Campaign[];
  campaignId?: number;
  onChangeCampaign: (campaignId: number) => void;
};

export const OfferTypeDetails: React.FC<OfferTypeDetailsProps> = ({
  offerType,
  threshold,
  onChangeThreshold,
  discount,
  onChangeDiscount,
  productName,
  onChangeProductName,
  giftName,
  onChangeGiftName,
  productCount,
  onChangeProductCount,
  campaigns,
  campaignId,
  onChangeCampaign,
}) => {
  useEffect(() => {
    const campaign = campaigns.find((c) => c.id === campaignId);
    if (campaign?.maximumOfferAmount === undefined) {
      console.warn('NO Maximum Offer Property has been added');
    } else {
      onChangeDiscount(campaign.maximumOfferAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, campaigns]);

  const campaignSelect = useMemo(
    () => (
      <Select
        id="buy"
        name="campaign"
        options={campaigns.map((campaign) => campaign.title)}
        selectedValue={campaigns.find((c) => c.id === campaignId)?.title}
        onChange={(event) => {
          const selectedCampaignId = campaigns.find((c) => c.title === event.target.value)?.id;
          if (selectedCampaignId) onChangeCampaign(selectedCampaignId);
        }}
        customClassName="mx2"
      />
    ),
    [campaigns, campaignId, onChangeCampaign],
  );

  const thresholdInput = useMemo(
    () => (
      <NumberInput
        customClassName={styles.numberInput}
        name="threshold"
        placeholder="Total"
        minValue={0}
        value={threshold > 0 ? threshold : undefined}
        onChange={(value) => onChangeThreshold(value)}
      />
    ),
    [threshold, onChangeThreshold],
  );

  const thresholdSelect = useMemo(
    () => (
      <Select
        id="buy"
        name="threshold"
        options={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
        selectedValue={threshold.toString()}
        onChange={(t) => onChangeThreshold(Number(t.target.value))}
        // size="large"
        customClassName="mx2"
      />
    ),
    [threshold, onChangeThreshold],
  );

  const discountInput = useMemo(() => {
    if (offerType === Model.OFFER_TYPE.VOUCHER) {
      const campaign = campaigns.find((c) => c.id === campaignId);

      /* if (campaign) {
        if (discount > campaign.amount) {
          onChangeDiscount(campaign.amount);
        }
      } else {
        onChangeDiscount(0);
      } */

      return (
        <NumberInput
          customClassName={styles.numberInput}
          name="discount"
          placeholder="Amount"
          value={discount > 0 ? discount : undefined}
          minValue={0}
          maxValue={campaign?.maximumOfferAmount ?? 0}
          onChange={(value) => onChangeDiscount(value)}
          disabled={campaign === undefined}
        />
      );
    }

    return (
      <NumberInput
        customClassName={styles.numberInput}
        name="discount"
        placeholder="Discount"
        value={discount > 0 ? discount : undefined}
        minValue={0}
        onChange={(value) => onChangeDiscount(value)}
      />
    );
  }, [campaignId, campaigns, discount, offerType, onChangeDiscount]);

  const discountSelect = useMemo(() => {
    return (
      <>
        <Select
          id="discountSelect"
          name="discountSelect"
          options={['FREE', 'with']}
          selectedValue={discount >= 100 ? 'FREE' : 'with'}
          onChange={(t) => {
            if (t.target.value === 'FREE') onChangeDiscount(100);
            else onChangeDiscount(75);
          }}
          // size="large"
          customClassName="mx2"
        />
        {discount >= 100 ? null : (
          <>
            <NumberInput
              customClassName={styles.numberInput}
              name="discount"
              placeholder="Discount"
              value={discount > 0 ? discount : undefined}
              minValue={0}
              maxValue={99}
              onChange={(value) => {
                onChangeDiscount(value);
              }}
            />
            % off
          </>
        )}
      </>
    );
  }, [discount, onChangeDiscount]);

  const productNameInput = useMemo(
    () => (
      <Input
        type="text"
        name="productName"
        placeholder="Product, e.g. Small Margherita Pizza"
        value={productName}
        onChange={(event) => onChangeProductName!(event.target.value)}
      />
    ),
    [productName, onChangeProductName],
  );

  const giftNameInput = useMemo(
    () => (
      <Input
        type="text"
        name="giftName"
        placeholder="Gift Product, e.g. Small Margherita Pizza"
        value={giftName}
        onChange={(event) => onChangeGiftName!(event.target.value)}
      />
    ),
    [giftName, onChangeGiftName],
  );

  const productCountSelect = useMemo(
    () => (
      <Select
        id="productCount"
        name="productCount"
        options={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
        selectedValue={productCount?.toString() || '1'}
        onChange={(t) => {
          onChangeProductCount(Number(t.target.value));
        }}
        // size="large"
        customClassName="mx2"
      />
    ),
    [productCount, onChangeProductCount],
  );

  const renderItems = () => {
    if (offerType === Model.OFFER_TYPE.AMOUNT) {
      return (
        <div className={styles.offerTypeItem}>
          Get ${discountInput} off your purchase when you spend ${thresholdInput} or more.
        </div>
      );
    } else if (offerType === Model.OFFER_TYPE.PERCENTAGE) {
      return (
        <div className={styles.offerTypeItem}>
          Get {discountInput}% off any purchase of you spend ${thresholdInput} or more.
        </div>
      );
    } else if (offerType === Model.OFFER_TYPE.QUANTITY) {
      return (
        <div className={styles.offerTypeItem}>
          <div className={styles.quantityDiv}>
            Buy {thresholdSelect} {productNameInput}
          </div>
          <div className={styles.quantityDiv}>
            Get {productCountSelect} {giftNameInput}
          </div>
          <div className={styles.quantityDiv}>{discountSelect}</div>
        </div>
      );
    } else if (offerType === Model.OFFER_TYPE.SPEND_FOR_GIFT) {
      return (
        <div className={styles.offerTypeItem}>
          <div className={styles.quantityDiv}>
            Get {productCountSelect} {giftNameInput}&nbsp;free
          </div>
          <div className={styles.quantityDiv}>when you spend ${thresholdInput} or more.</div>
        </div>
      );
    } else if (offerType === Model.OFFER_TYPE.VOUCHER) {
      return (
        <>
          <div className="row">
            <div className={styles.flexBetween}>
              <span className={`${styles.datePickerLabel} my3 bold`}>Select Campaign: </span> <span>{campaignSelect}</span>
            </div>
          </div>
          <div className="row">
            <div className={styles.flexBetween}>
              <span className={`${styles.datePickerLabel} my3 bold`}>Amount: </span> <span>${discountInput}</span>
            </div>
          </div>

          <div>
            <div className={styles.campaignDescription}>
              <div className={styles.iconWrapper}>
                <div className="my3 bold">Campaign Description: </div>
              </div>

              <div>{campaigns.find((c) => c.id === campaignId)?.description}</div>
            </div>
          </div>
        </>
      );
    }

    return <div>Something went wrong</div>;
  };

  return <div className={styles.content}>{renderItems()}</div>;
};
