import React from 'react';
import styles from './LineStep.module.scss';

type Props = {
  stepCount: number;
  step: number;
  customClassName?: string;
};

export const LineStep: React.FC<Props> = ({ stepCount, step, customClassName }) => {
  const lis = [];
  for (let i = 0; i < stepCount; i++) {
    lis.push(<li key={`step-line-${i}`} className={step > i ? styles.active : ''} style={{ width: `${100 / stepCount}%` }} />);
  }

  return (
    <div className={`${styles.container} ${customClassName || ''}`}>
      <ul className={styles.progressbar}>{lis}</ul>
    </div>
  );
};
