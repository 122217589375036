import React from 'react';
import { CN } from '../../className';
import styles from './FormContainer.module.scss';

interface Props {
  customClassName?: string;
  children: React.ReactNode;
}

export const FormContainer: React.FC<Props> = ({ customClassName, children }) => {
  return <div className={CN(styles.container, [[customClassName || '', customClassName !== undefined]])}>{children}</div>;
};
