import { useParams } from 'react-router';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { ReportTable } from './ReportTable/ReportTable';

import { CAMPAIGN_REPORT } from '../../../const/ROUTER_PATH_TITLE';
import { useCampaignReport } from './useCampaignReport';
import classes from './CampaignReportPage.module.scss';

export const CampaignReportPage = () => {
  const { id } = useParams<{ id: string }>();

  const { loading, data, fetchCampaignReport, onPay, onExportAll } = useCampaignReport(Number(id));

  document.title = CAMPAIGN_REPORT.TITLE;

  return (
    <FullCenter centerY={false} customClassName={classes.reportContainer}>
      <ReportTable data={data} fetch={fetchCampaignReport} loading={loading} onPay={onPay} exportAllOnClick={onExportAll} />
    </FullCenter>
  );
};
