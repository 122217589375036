import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { api } from '@tripian/core';
import Model from '@tripian/model';

import { OFFERS, OFFER_PREVIEW } from '../../../const/ROUTER_PATH_TITLE';
import UserContext from '../../../context/User/UserContext';
import { AppLoader } from '../../../components/AppLoader/AppLoader';
import { OfferPreviewItem } from './OfferPreviewItem/OfferPreviewItem';
import { VoucherOfferPreviewItem } from './VoucherOfferPreviewItem/VoucherOfferPreviewItem';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';

export const OfferPreviewPage = () => {
  const [offer, setOffer] = useState<Model.Offer>();
  const [initLoading, setInitLoading] = useState<boolean>(true);

  const [customerLoading, setCustomerLoading] = useState<boolean>(true);
  const [customers, setCustomers] = useState<Model.OfferCustomer[]>([]);

  const { payloadData } = useContext(UserContext);

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  document.title = OFFER_PREVIEW.TITLE;

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      api
        .businessOffer(Number(id))
        .then((offerResponse: Model.Offer) => {
          if (!unmounted) {
            setOffer(offerResponse);
          }
        })
        .finally(() => {
          setInitLoading(false);
        });
    }

    return () => {
      unmounted = true;
    };
  }, [id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted && initLoading === false) {
      api
        .businessOfferCustomers(Number(id))
        .then((cs: Model.OfferCustomer[]) => {
          if (!unmounted) {
            setCustomers(cs);
          }
        })
        .catch((er) => {
          console.error(er);
        })
        .finally(() => {
          setCustomerLoading(false);
        });
    }

    return () => {
      unmounted = true;
    };
  }, [id, initLoading]);

  // if (offer === undefined) return <h3 className="center mt8">404 - Offer not found</h3>;

  return (
    <FullCenter centerY={false}>
      <AppLoader active={initLoading} minHeight="20rem" transparent>
        {offer && (
          <>
            {offer.offerType === Model.OFFER_TYPE.VOUCHER ? (
              <VoucherOfferPreviewItem
                offer={offer}
                businessName={payloadData.business?.name}
                customers={customers}
                goBack={() => history.push(OFFERS.PATH)}
                customerLoading={customerLoading}
              />
            ) : (
              <OfferPreviewItem
                offer={offer}
                customers={customers}
                businessName={payloadData.business?.name ? payloadData.business.name : offer?.poiName ? offer.poiName : 'Business Name'}
                goBack={() => history.goBack()}
                customerLoading={customerLoading}
              />
            )}
          </>
        )}
      </AppLoader>
    </FullCenter>
  );
};
