import React, { useReducer } from 'react';
import reducer from './reducer';
import UserContext from './UserContext';

interface IProps {
  children: React.ReactNode;
}

const UserProvider: React.FC<IProps> = ({ children }) => {
  const [payloadData, dispatch] = useReducer(reducer, {});

  return <UserContext.Provider value={{ payloadData, dispatch }}>{children}</UserContext.Provider>;
};

export default UserProvider;
