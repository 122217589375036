import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { api } from '@tripian/core';
import { FORGOT_PASSWORD, LOGIN, NEW_PASSWORD, OFFERS } from '../../../const/ROUTER_PATH_TITLE';
import { getLocalStorageToken } from '../../../helper/localStorage';
import { wordTrim } from '../../../helper/common';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { LinkButton } from '../../../components/Base/LinkButton/LinkButton';
import { FormContainer } from '../../../components/Base/FormContainer/FormContainer';
import { ErrorMessage } from '../../../components/Base/ErrorMessage/ErrorMessage';
import { InputLabel } from '../../../components/Base/InputLabel/InputLabel';
import { Button } from '../../../components/Base/Button/Button';
import { Input } from '../../../components/Base/Input/Input';
import styles from './ForgotPasswordPage.module.scss';
import { Validate } from '../../../model/Validate';
import { validateForgotPassword } from '../../../helper/validate';

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState<string>('');
  const [validate, setValidate] = useState<Validate>({ isValid: true, message: undefined });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);

  const history = useHistory();
  document.title = FORGOT_PASSWORD.TITLE;

  useEffect(() => {
    let unmonted = false;
    if (!unmonted) {
      if (getLocalStorageToken()) {
        history.replace(OFFERS.PATH);
      }
    }
    return () => {
      unmonted = false;
    };
  }, [history]);

  const submitResetPassword = async () => {
    const formValidate = validateForgotPassword(email);
    setValidate(formValidate);

    if (formValidate.isValid) {
      return api
        .userResetPassword(email, `${window.location.origin}${NEW_PASSWORD.PATH}/`)
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setValidate({ ...validate, message: err });
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="full-center">
        <FullCenter loading={loading} centerY={false} maxWidth={500} customClassName={styles.fullCenter} loaderFixed={true}>
          {success ? (
            <div className={styles.forgotPasswordSuccessMessage}>
              <div className={styles.forgotPasswordSuccessMessageHeader}>
                <h4>We have sent a password reset link and code.</h4>
              </div>
              <div className={styles.forgotPasswordSuccessMessageText}>
                <span>Please check your mail inbox first and get your verification code.</span>
              </div>
              <Button
                customClassName={styles.forgotPasswordButton}
                type="primary"
                label="Back to sign in page"
                onClick={() => history.push(LOGIN.PATH)}
              />
            </div>
          ) : (
            <>
              <div className={styles.forgotPasswordContainer}>
                <h4>Forgot Password</h4>
                <div className={styles.forgotPasswordGoBack}>I remember my password</div>
                <LinkButton text="Login" onClick={() => history.push(LOGIN.PATH)} bold />
              </div>

              <FormContainer customClassName={styles.formContainer}>
                <form>
                  <ErrorMessage message={validate.message} />
                  <div>
                    <InputLabel text="Email" />
                    <div className={styles.inputDiv}>
                      <Input
                        type="text"
                        name="email"
                        size="large"
                        value={email}
                        onChange={(event: { target: { value: string } }) => setEmail(wordTrim(event.target.value))}
                      />
                    </div>
                  </div>
                  <Button
                    customClassName={styles.forgotPasswordButton}
                    onClick={() => {
                      setLoading(true);
                      submitResetPassword();
                    }}
                    label="Send verification mail"
                    type="primary"
                    disabled={email.trim().length < 1}
                  />
                </form>
              </FormContainer>
            </>
          )}
        </FullCenter>
      </div>
    </>
  );
};
