import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { api } from '@tripian/core';
import { QR_READER, OFFER_PAYMENT } from '../../../const/ROUTER_PATH_TITLE';
import { Button } from '../../../components/Base/Button/Button';
import classes from './OfferPaymentPage.module.scss';

export const OfferPaymentPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);

  const history = useHistory();
  const { offerId, userId } = useParams<{ offerId: string; userId: string }>();

  document.title = OFFER_PAYMENT.TITLE;

  useEffect(() => {
    const offerIdNumber = Number(offerId);
    const userIdNumber = Number(userId);

    if (isNaN(offerIdNumber) || isNaN(userIdNumber)) {
      setError('Invalid url.');
      setLoading(false);
      return;
    }

    api
      .businessOfferRedeemStatusUpdate(offerIdNumber, userIdNumber, 'completed')
      .then((res) => {
        if (res === 'completed') {
          setSuccess(true);
        } else {
          setError('An error occurred.');
        }
      })
      .catch((er) => {
        setError('An error occurred.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [offerId, userId]);

  return (
    <div className={classes.center}>
      {loading && <div>Loading...</div>}
      {error && <div>{error}</div>}
      {success && <div>Success</div>}
      {error && !success && <Button label="Go Back" onClick={() => history.push(QR_READER.PATH)} />}
    </div>
  );
};
