import React, { useCallback, useState } from 'react';
import { SelectField2 } from '../../../../Base/SelectField2/SelectField2';
import styles from './RecurrentDays.module.scss';

const DAYS: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

interface Props {
  recurrents: number[];
  onChange: (indexes: number[]) => void;
  disableds?: number[];
}

export const RecurrentDays: React.FC<Props> = ({ recurrents, onChange, disableds = [] }) => {
  const [indexes, setIndexes] = useState<number[]>(recurrents);

  const onCheck = useCallback(
    (index: number, checked: boolean) => {
      const newIndexes = checked ? [...indexes, index].sort() : indexes.filter((x) => x !== index);
      setIndexes(newIndexes);
      onChange(newIndexes);
    },
    [indexes, onChange],
  );

  return (
    <div className={styles.content}>
      {DAYS.map((day, i) => {
        return (
          <div key={`recurrent-${day}`} className={styles.campaignCategoryItem}>
            <SelectField2
              disabled={disableds.includes(i)}
              label={day}
              onChange={(selected) => {
                onCheck(i, selected);
              }}
              checked={disableds.includes(i) ? false : indexes.includes(i)}
            />
          </div>
        );
      })}
    </div>
  );
};
