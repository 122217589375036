import React from "react";
import style from "./RadioButton.module.scss"

type Props = {
    checked : boolean;
    radioButtonOnChange : (event: React.ChangeEvent<HTMLInputElement>) => void;
    domId? : string;
}

export const RadioButton : React.FC<Props> = ({checked,radioButtonOnChange,domId}) => {
    return (<input className={style.radioMain} type="radio" id={domId} checked={checked} onChange={radioButtonOnChange}/>)
}