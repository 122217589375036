import React from 'react';
import QRCode from 'react-qr-code';
import Model from '@tripian/model';
import voucherOfferImg from './img/summerCampaign.png';
import { CN } from '../className';
import styles from './VoucherOfferCard.module.scss';

export interface VoucherOfferCardProps {
  offer: Model.Offer;
  businessName?: string;
  blurred?: boolean;
}

export const VoucherOfferCard: React.FC<VoucherOfferCardProps> = ({ offer, businessName, blurred }) => {
  return (
    <div className={styles.offerCard}>
      {businessName && <div className={styles.businessName}>{businessName}</div>}
      <img
        className={styles.image}
        ref={(node) => {
          if (node) {
            node.style.setProperty('width', '100%', 'important');
          }
        }}
        src={voucherOfferImg}
        alt=""
      />
      <div className={CN(styles.qr, [[styles.blurred, blurred === true]])}>
        <QRCode value={offer.qrUrl ? `https://${offer.qrUrl}` : `https://bookbarbados-dev.tripian.com/offer-payment/${offer.id}`} />
      </div>
    </div>
  );
};
