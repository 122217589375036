import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api } from '@tripian/core';
import { LOGIN, NEW_PASSWORD } from '../../../const/ROUTER_PATH_TITLE';
import { Button } from '../../../components/Base/Button/Button';
import styles from './NewPasswordPage.module.scss';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { LinkButton } from '../../../components/Base/LinkButton/LinkButton';
import { FormContainer } from '../../../components/Base/FormContainer/FormContainer';
import { ErrorMessage } from '../../../components/Base/ErrorMessage/ErrorMessage';
import { InputLabel } from '../../../components/Base/InputLabel/InputLabel';
import { Input } from '../../../components/Base/Input/Input';
import { wordTrim } from '../../../helper/common';
import { validateNewPassword } from '../../../helper/validate';
import { Validate } from '../../../model/Validate';

export const NewPasswordPage = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordValidate, setPasswordValidate] = useState('');
  const [validate, setValidate] = useState<Validate>({ isValid: true, message: undefined });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const history = useHistory();
  const { hash } = useParams<{ hash: string }>();

  document.title = NEW_PASSWORD.TITLE;

  const submitNewPassword = () => {
    const formValidate = validateNewPassword(password, passwordValidate);
    setValidate(formValidate);
    if (formValidate.isValid) {
      return api
        .userUpdatePassword(password, hash)
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((err) => {
          setValidate({ ...validate, message: err });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <FullCenter loading={loading} centerY={false} maxWidth={500} customClassName={styles.fullCenter} loaderFixed={true}>
      {success ? (
        <div className={styles.newPasswordSuccessMessage}>
          <div className={styles.newPasswordSuccessMessageHeader}>
            <h4>
              Congratulations!
              <br />
              We have reset your password now.
            </h4>
          </div>

          <div className={styles.newPasswordSuccessMessageText}>
            <span>You can sign in with new password.</span>
          </div>
          <Button type="primary" label="Go to sign in page" onClick={() => history.push(LOGIN.PATH)} />
        </div>
      ) : (
        <>
          <div className={styles.newPasswordContainer}>
            <h4>New Password</h4>
            <div className={styles.newPasswordGoBack}>I remember my password</div>
            <LinkButton text="Login" onClick={() => history.push(LOGIN.PATH)} bold />
          </div>
          <FormContainer customClassName={styles.formContainer}>
            <form>
              <ErrorMessage message={validate.message} />
              <div>
                <InputLabel text="New Password">
                  <LinkButton text={showPassword ? 'Hide' : 'Show'} onClick={() => setShowPassword(!showPassword)} />
                </InputLabel>
                <div className={styles.inputDiv}>
                  <Input
                    type={`${showPassword ? 'text' : 'password'}`}
                    name="password"
                    size="large"
                    value={password}
                    onChange={(event) => setPassword(wordTrim(event.target.value))}
                  />
                </div>
                <InputLabel text="Retype Password">
                  <LinkButton text={showPasswordConfirm ? 'Hide' : 'Show'} onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} />
                </InputLabel>
                <div className={styles.inputDiv}>
                  <Input
                    type={`${showPasswordConfirm ? 'text' : 'password'}`}
                    name="passwordRetype"
                    size="large"
                    value={passwordValidate}
                    onChange={(event) => setPasswordValidate(wordTrim(event.target.value))}
                  />
                </div>
              </div>
              <Button
                customClassName={styles.newPasswordButton}
                onClick={() => {
                  setLoading(true);
                  submitNewPassword();
                }}
                label="Log in"
                type="primary"
                disabled={password.trim().length < 1 || passwordValidate.trim().length < 1}
              />
            </form>
          </FormContainer>
        </>
      )}
    </FullCenter>
  );
};
