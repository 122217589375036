import { useCallback } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Icon } from '../../../../../components/Base/Icon/Icon';
import styles from './ReportTableHeader.module.scss';
import Model from '@tripian/model';

type Props = {
  orderColumnIndex: number;
  setOrderColumnIndex: (newOrderColumnIndex: number) => void;
  orderAsc: boolean;
  setOrderAsc: (newOrderAsc: boolean) => void;
  query: Partial<Model.BusinessPreRegisterReportRequest>;
  setQuery: (newFilter: Partial<Model.BusinessPreRegisterReportRequest>) => void;
};
export const ReportTableHeader: React.FC<Props> = ({ orderColumnIndex, setOrderColumnIndex, orderAsc, setOrderAsc, query, setQuery }) => {
  const rowHeaderClicked = useCallback(
    (index: number, columnName: string) => {
      let newOrder = `${columnName},`;

      if (orderColumnIndex === index) {
        setOrderAsc(!orderAsc);
        newOrder += `${orderAsc === true ? 'desc' : 'asc'}`;
      } else {
        setOrderColumnIndex(index);
        setOrderAsc(true);
        newOrder += 'asc';
      }

      // const newOrder = `${columnName},${orderAsc === true ? 'asc' : 'desc'}`;

      setQuery({ ...query, order: newOrder });
    },
    [orderAsc, orderColumnIndex, query, setOrderAsc, setOrderColumnIndex, setQuery],
  );

  return (
    <TableRow style={{ cursor: 'pointer' }} className={styles.reporTableHeader}>
      <TableCell align="center" />
      <TableCell align="center" onClick={() => rowHeaderClicked(1, 'email')}>
        <div className={styles.reporTableHeaderContent}>
          Email {orderColumnIndex === 1 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell align="center" style={{ minWidth: '7rem' }} onClick={() => rowHeaderClicked(2, 'fullName')}>
        <div className={styles.reporTableHeaderContent}>
          Fullname {orderColumnIndex === 2 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell align="center" onClick={() => rowHeaderClicked(4, 'businessName')}>
        <div className={styles.reporTableHeaderContent}>
          Business Name {orderColumnIndex === 4 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
      <TableCell align="center" style={{ minWidth: '5rem' }} onClick={() => rowHeaderClicked(5, 'status')} className={styles.tableMediumWidth}>
        <div className={styles.reporTableHeaderContent}>
          Status {orderColumnIndex === 5 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
    </TableRow>
  );
};
