import React, { useEffect, useState } from 'react';
import { Input } from '../Input/Input';

type Props = {
  name: string;
  value?: number;
  minValue?: number;
  maxValue?: number;
  placeholder?: string;
  customClassName?: string;
  onChange: (value: number) => void;
  disabled?: boolean;
};

const NumberInput: React.FC<Props> = ({ name, value, minValue, maxValue, placeholder, customClassName, onChange, disabled }) => {
  const [number, setNumber] = useState<number | undefined>();

  useEffect(() => {
    setNumber(value);
  }, [value]);

  const inputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = Number(event.target.value);

    // min = 0 number = 99 max=100
    if (minValue && maxValue) {
      if (input < minValue) {
        setNumber(minValue);
        onChange(minValue);
      } else if (input > maxValue) {
        setNumber(maxValue);
        onChange(maxValue);
      } else {
        onChange(input);
      }
    } // min = 0 number = 99
    else if (minValue) {
      if (input < minValue) {
        setNumber(minValue);
        onChange(minValue);
      } else {
        setNumber(input);
        onChange(input);
      }
    } // number = 99 max = 100
    else if (maxValue) {
      if (input > maxValue) {
        setNumber(maxValue);
        onChange(maxValue);
      } else {
        setNumber(input);
        onChange(input);
      }
    } else {
      setNumber(input);
      onChange(input);
    }
  };

  return (
    <Input
      customClassName={customClassName}
      name={name}
      type="number"
      defaultValue={value}
      value={number || ''}
      min={minValue}
      max={maxValue}
      placeholder={placeholder}
      onChange={(event) => {
        inputOnChange(event);
      }}
      onKeyDown={(event: any) => ((event.which !== 8 && event.which !== 0 && event.which < 48) || event.which > 57) && event.preventDefault()}
      disabled={disabled}
    />
  );
};

export default NumberInput;
