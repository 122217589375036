import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { LOGIN, SIGNUP, OFFERS, FORGOT_PASSWORD, CAMPAIGNS } from '../../../const/ROUTER_PATH_TITLE';

import USER_ACTION_TYPES from '../../../context/User/USER_ACTION_TYPES';
import IPayloadData from '../../../context/User/IPayloadData';
import UserContext from '../../../context/User/UserContext';

import Model from '@tripian/model';
import { api } from '@tripian/core';
import { scrollTop, wordTrim } from '../../../helper/common';
import { getLocalStorageToken, removeLocalStorageToken, saveLocalStorageToken } from '../../../helper/localStorage';

import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { Input } from '../../../components/Base/Input/Input';
import { Button } from '../../../components/Base/Button/Button';
import { Icon } from '../../../components/Base/Icon/Icon';
import { InputLabel } from '../../../components/Base/InputLabel/InputLabel';
import { LinkButton } from '../../../components/Base/LinkButton/LinkButton';
import { ErrorMessage } from '../../../components/Base/ErrorMessage/ErrorMessage';
import { FormContainer } from '../../../components/Base/FormContainer/FormContainer';
import styles from './LoginPage.module.scss';

export const LoginPage = () => {
  const [loginRequestData, setLoginRequestData] = useState<Model.LoginRequest>({ email: '', password: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);

  const { dispatch } = useContext(UserContext);

  const history = useHistory();
  document.title = LOGIN.TITLE;

  useEffect(() => {
    let unmonted = false;
    if (!unmonted) {
      if (getLocalStorageToken()) {
        history.replace(OFFERS.PATH);
      }
    }
    return () => {
      unmonted = false;
    };
  }, [history]);

  useEffect(() => {
    scrollTop();
  }, []);

  const login = async () => {
    setErrorMessage(undefined);
    return api
      .loginEmail(loginRequestData.email, loginRequestData.password, true)
      .then((token: Model.Token) => {
        saveLocalStorageToken(token);
        fetchPayloadData(token);
      })
      .catch((err) => {
        setErrorMessage(err);
        setLoading(false);
      });
  };

  const fetchPayloadData = async (token: Model.Token) => {
    return api.combo
      .businessPayloadData()
      .then((payloadData: IPayloadData) => {
        dispatch({
          type: USER_ACTION_TYPES.SET_PAYLOAD,
          payload: payloadData,
        });
        if (payloadData.user?.userType === Model.USER_TYPE.COUPON_ADMIN || payloadData.user?.userType === Model.USER_TYPE.SUPER_ADMIN)
          history.replace(CAMPAIGNS.PATH);
        else history.replace(OFFERS.PATH);
      })
      .catch((err) => {
        setErrorMessage(err);
        setLoading(false);
        removeLocalStorageToken();
      });
  };

  return (
    <>
      <FullCenter loading={loading} centerY={false} maxWidth={500} customClassName={styles.fullCenter} loaderFixed={true}>
        <div className={styles.loginContainer}>
          <h4>Business Login</h4>
          <div className={styles.question}>Don’t have an account for your business?</div>
          <LinkButton text="Sign Up" onClick={() => history.push(SIGNUP.PATH)} bold />
        </div>

        <FormContainer customClassName={styles.formContainer}>
          <form>
            <ErrorMessage message={errorMessage} />
            <div>
              <InputLabel text="Email" />
              <div className={styles.inputDiv}>
                <Input
                  type="text"
                  name="email"
                  size="large"
                  value={loginRequestData.email}
                  onChange={(event) =>
                    setLoginRequestData({
                      ...loginRequestData,
                      email: wordTrim(event.target.value),
                    })
                  }
                />
              </div>
              <InputLabel text="Password">
                <LinkButton
                  text="Forgot pass?"
                  onClick={() => {
                    history.push(FORGOT_PASSWORD.PATH);
                  }}
                />
              </InputLabel>
              <div className={styles.inputDiv}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  size="large"
                  value={loginRequestData.password}
                  onChange={(event) =>
                    setLoginRequestData({
                      ...loginRequestData,
                      password: wordTrim(event.target.value),
                    })
                  }
                />
                <div
                  className={styles.eyeIconButton}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  onKeyDown={() => {}}
                >
                  {showPassword ? <Icon name="EYE" /> : <Icon name="EYE_SLASH" />}
                </div>
              </div>
            </div>
            <Button
              onClick={() => {
                setLoading(true);
                login();
              }}
              label="Log in"
              type="primary"
              disabled={loginRequestData.password.trim().length < 1}
            />
          </form>
        </FormContainer>
      </FullCenter>
    </>
  );
};
