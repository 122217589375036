import IConfig from './IConfig';

const staticLocalConfig: IConfig = {
  HOSTNAME: process.env.REACT_APP_HOSTNAME || window.location.hostname,
  API_URL: process.env.REACT_APP_API_URL || '',
  API_KEY: '',
  BRAND_NAME: '',
  QR_READER: 'customer',

  IMAGE_PATHS: {
    LOGO_PATH_DARK: '',
    LOGO_PATH_LIGHT: '',
  },

  COLORS: {
    PRIMARY: '',
    SECONDARY: '',
    SUCCESS: '',
    WARNING: '',
    INFO: '',
    DANGER: '',
    TEXT_PRIMARY: '',
    BACKGROUND: '',
    HEADER_BACKGROUND: '',
    HEADER_TEXT_COLOR: '',
  },
  SBT: {
    OFFER_TYPES: [],
    CAMPAIGN_DEFAULT_CURRENCY: '',
  },
};

// Check api url defined
if (staticLocalConfig.API_URL === '') {
  console.log('process.env', process.env);
  console.error('process.env.REACT_APP_API_URL (TRIPIAN_API_URL) is undefined!');
  throw new Error('process.env.REACT_APP_API_URL (TRIPIAN_API_URL) is undefined!');
}

export { staticLocalConfig };
