import Model, { helper } from '@tripian/model';
import { SignUpFormData } from '../model/SignUpFormData';
import { Validate } from '../model/Validate';

const validateSignUp = (signUpFormData: SignUpFormData): Validate => {
  const result: Validate = { isValid: false, message: undefined };

  if (signUpFormData.cityId === undefined) result.message = 'Please select a city.';
  else if (signUpFormData.poi === undefined) result.message = 'Please select a place.';
  // else if (signUpFormData.email.trim() === '') result.message = 'Please enter your email address.';
  else if (signUpFormData.firstName.trim() === '') result.message = 'Please enter your  first name.';
  else if (signUpFormData.lastName.trim() === '') result.message = 'Please enter your last name.';
  // else if (signUpFormData.email !== signUpFormData.email2) result.message = 'The e-mails you have entered does not match.';
  else if (emailValidation(signUpFormData.email, signUpFormData.email2) !== '')
    result.message = emailValidation(signUpFormData.email, signUpFormData.email2);
  else if (passwordValidation(signUpFormData.password) !== '') result.message = passwordValidation(signUpFormData.password);
  else if (signUpFormData.termsOfUse === false) result.message = 'Please accept the terms of use.';
  else result.isValid = true;

  return result;
};

export const validatePreRegister = (formData: Model.PreRegister): Validate => {
  const result: Validate = { isValid: false, message: undefined };
  if (formData.cityId === 0) result.message = 'Please select a city.';
  else if (formData.name.trim() === '') result.message = 'Please enter your first name, last name.';
  else if (formData.email.trim() === '') result.message = 'Please enter your email address.';
  else if (formData.googlePlaceId.trim() === '' || formData.businessName.trim() === '') result.message = 'Please search and select your business.';
  else result.isValid = true;

  return result;
};

const validateProfile = (firstName: string, lastName: string): boolean => {
  if (firstName.trim() === '') return false;
  else if (lastName.trim() === '') return false;

  return true;
};

const validateForgotPassword = (email: string): Validate => {
  const result: Validate = { isValid: false, message: undefined };

  const validateEmail = emailValidation(email);

  if (validateEmail !== '') result.message = validateEmail;
  else result.isValid = true;

  return result;
};

const validateNewPassword = (password: string, password2: string): Validate => {
  const result: Validate = { isValid: false, message: undefined };

  const validatePassword = passwordValidation(password, password2);

  if (validatePassword !== '') result.message = validatePassword;
  else result.isValid = true;

  return result;
};

const passwordValidation = (password: string, password2?: string): string => {
  if (password.trim() === '') return 'Please enter your password.';
  else if (password.trim().length < 6) return 'Password length can be minimum 6 characters.';
  else if (!password.match(/[a-z]/)) return 'Password must include lower case letter.';
  // else if (!signUpFormData.password.match(/[A-Z]/)) result.message = 'Password must include upper case letter.';
  else if (!password.match(/[0-9]/)) return 'Password must include a number.';
  // else if (signUpFormData.password2 === '') result.message = 'Please comfirm your password.';
  else if ((password2 || password2 === '') && password !== password2) return 'Your password diffirent from confirm password.';

  return '';
};

const emailValidation = (email: string, email2?: string): string => {
  const emailValid = helper.emailFormatValid(email);

  if (email.trim() === '') return 'Please enter your email address.';
  else if (!emailValid) return 'Email is invalid.';
  else if ((email2 || email2 === '') && email !== email2) return 'The e-mails you have entered does not match.';

  return '';
};

export { validateSignUp, validateProfile, validateForgotPassword, validateNewPassword, passwordValidation };
