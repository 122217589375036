import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Validate } from '../../../model/Validate';
import { api } from '@tripian/core';
import Model, { helper } from '@tripian/model';
import { LOGIN, PREREGISTER } from '../../../const/ROUTER_PATH_TITLE';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { Input } from '../../../components/Base/Input/Input';
import { Button } from '../../../components/Base/Button/Button';
import { InputLabel } from '../../../components/Base/InputLabel/InputLabel';
import { ErrorMessage } from '../../../components/Base/ErrorMessage/ErrorMessage';
import { RSelect } from '../../../components/RSelect/RSelect';
import { scrollTop } from '../../../helper/common';
import { validatePreRegister } from '../../../helper/validate';
import { FormContainer } from '../../../components/Base/FormContainer/FormContainer';
import GPlacesAutocomplete from '../../../components/GPlacesAutocomplete/GPlacesAutocomplete';
import { ModalPage } from '../../../components/Base/ModalPage/ModalPage';
import { LinkButton } from '../../../components/Base/LinkButton/LinkButton';
import { initialPreRegisterFormData } from '../../../helper/constants';
import styles from './PreRegisterPage.module.scss';
import UserContext from '../../../context/User/UserContext';

export const PreRegisterPage = () => {
  const { payloadData } = useContext(UserContext);
  const [formData, setFormData] = useState<Model.PreRegister>({
    ...initialPreRegisterFormData,
    email: payloadData.user?.email ?? '',
    name: payloadData.user ? payloadData.user.firstName + ' ' + payloadData.user.lastName : '',
  });
  const [validate, setValidate] = useState<Validate>({ isValid: true, message: undefined });
  const [loading, setLoading] = useState<boolean>(false);
  const [cities, setCities] = useState<Model.City[]>([]);
  const [bound, setBound] = useState<google.maps.LatLngBounds | undefined>();
  const [registered, setRegistered] = useState<boolean>();

  const history = useHistory();
  document.title = PREREGISTER.TITLE;

  useEffect(() => {
    let unmonted = false;

    if (!unmonted) {
      api.citiesAll().then((cities) => {
        setCities(cities);
      });
    }
    return () => {
      unmonted = true;
    };
  }, []);

  const onChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    switch (inputName) {
      case 'name':
        setFormData((prevData: Model.PreRegister) => ({ ...prevData, name: inputValue }));
        break;

      case 'email':
        setFormData((prevData: Model.PreRegister) => ({ ...prevData, email: inputValue }));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    scrollTop();
  }, []);

  const onSelectCity = (value: any) => {
    setFormData({
      ...formData,
      cityId: value.id,
    });

    const newBoundary = new google.maps.LatLngBounds(
      new google.maps.LatLng(value.payload.boundary[0], value.payload.boundary[2]),
      new google.maps.LatLng(value.payload.boundary[1], value.payload.boundary[3]),
    );

    setBound(newBoundary);
  };

  const callbackGPlacesAutocomplete = (googlePlace: google.maps.places.PlaceResult) => {
    setFormData((prevData: Model.PreRegister) => ({ ...prevData, businessName: googlePlace.name || '', googlePlaceId: googlePlace.place_id || '' }));
  };

  const submitForm = () => {
    const formValidate = validatePreRegister(formData);
    setValidate(formValidate);

    if (formValidate.isValid) {
      api
        .businessPreRegister(formData)
        .then(() => {
          setRegistered(true);
        })
        .catch((err) => {
          setValidate({ ...validate, message: err.response.data.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <FullCenter loading={loading} centerY={false} maxWidth={1088} customClassName={styles.fullCenter} loaderFixed={true}>
      <div className={styles.hContainer}>
        <h4>Pre Register</h4>
        {!payloadData.user && (
          <>
            <div className={styles.question}>Already have a business account?</div>
            <LinkButton text="Log in" onClick={() => history.push(LOGIN.PATH)} bold />
          </>
        )}
      </div>

      <FormContainer customClassName={styles.formContainer}>
        <form onSubmit={submitForm}>
          <div>
            <ErrorMessage message={validate.message} />
            <div className="row">
              <div className="col col12 col6-m">
                <InputLabel text="City" />
                <RSelect
                  options={cities
                    .sort((a, b) => helper.compareStringForSort(a.name, b.name))
                    .map((city) => ({
                      id: city.id,
                      label: city.name,
                      payload: city,
                      isSelected: formData.cityId === city.id,
                    }))}
                  defaultOptionId={formData.cityId}
                  onSelectedChanged={onSelectCity}
                />
              </div>
              <div className="col col12 col6-m">
                <InputLabel text="Business" />
                <GPlacesAutocomplete
                  placeholder="Search your business"
                  initialText={formData.businessName}
                  onSelectedChanged={callbackGPlacesAutocomplete}
                  boundry={bound}
                />
              </div>
            </div>
            <div className="row">
              <div className="col col12 col6-m">
                <InputLabel text="Name" />
                <Input type="text" name="name" size="large" value={formData.name} onChange={onChangeForm} />
              </div>
              <div className="col col12 col6-m">
                <InputLabel text="Email" />
                <Input type="text" name="email" size="large" value={formData.email} onChange={onChangeForm} />
              </div>
            </div>

            <Button
              onClick={() => {
                setLoading(true);
                submitForm();
              }}
              label="Request Registration"
              type="primary"
            />
          </div>
        </form>
      </FormContainer>
      <ModalPage size="small" show={registered} onClose={() => setRegistered(false)}>
        <div className={styles.preRegisterSuccessMessage}>
          <div className="center mt6">Your pre-registration request has been received.</div>
          <div className="center mt6">We will email you as soon as possible.</div>
        </div>
      </ModalPage>
    </FullCenter>
  );
};
