import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as SingleDateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker, MobileDateTimePickerProps } from '@mui/x-date-pickers/MobileDateTimePicker';

type Props = DateTimePickerProps<moment.Moment> &
  MobileDateTimePickerProps<moment.Moment> &
  React.RefAttributes<HTMLDivElement> & {
    typeable?: boolean;
  };

export const DateTimePicker: React.FC<Props> = ({ typeable = true, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {typeable ? (
        <DemoContainer components={['DateTimePicker']}>
          <SingleDateTimePicker {...props} />
        </DemoContainer>
      ) : (
        <DemoContainer components={['MobileDateTimePicker']}>
          <MobileDateTimePicker {...props} />
        </DemoContainer>
      )}
    </LocalizationProvider>
  );
};
