import { /* useCallback, */ useCallback, useState } from 'react';
import Model from '@tripian/model';
import { api } from '@tripian/core';

export const useBusinessPreRegisterReport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Model.DataPayload<Model.BusinessReport>>({ data: [] });

  const fetchBusinessPreRegisterReport = useCallback((query: Partial<Model.BusinessPreRegisterReportRequest>) => {
    setLoading(true);
    api
      .businessPreRegisterReport(query)
      .then((data) => {
        setData(data);
      })
      .finally(() => setLoading(false));
  }, []);

  const onExportAll = useCallback(() => {
    api.businessExport('pre-register-report').then((data) => {
      window.open(data.url);
    });
  }, []);

  return { loading, fetchBusinessPreRegisterReport, onExportAll, data };
};
