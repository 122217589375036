import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import ReportTable from './ReportTable/ReportTable';
import { useBusinessPreRegisterReport } from './useBusinessPreRegisterReport';
import { BUSINESS_REPORT } from '../../../const/ROUTER_PATH_TITLE';
import classes from './BusinessPreRegisterReportPage.module.scss';

export const BusinessPreRegisterReportPage = () => {
  const { loading, data, fetchBusinessPreRegisterReport, onExportAll } = useBusinessPreRegisterReport();

  document.title = BUSINESS_REPORT.TITLE;

  return (
    <FullCenter centerY={false} customClassName={classes.businessReportContainer}>
      <ReportTable data={data} fetch={fetchBusinessPreRegisterReport} loading={loading} exportAllOnClick={onExportAll} />
    </FullCenter>
  );
};
