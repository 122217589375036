/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { CN } from '../../className';
import styles from './ModalPage.module.scss';

export interface ModalPageProps {
  show?: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
  size?: 'small' | 'normal' | 'long';
  children: React.ReactNode;
}

export const ModalPage: React.FC<ModalPageProps> = ({ show = false, showCloseButton = true, size = 'long', onClose, children }) => {
  const modalClassName = CN(styles.modal, [
    [styles.small, size === 'small'],
    [styles.normal, size === 'normal'],
    [styles.long, size === 'long'],
  ]);

  return (
    <div className={`${styles.modalOverlay} ${show ? styles.active : null}`} onClick={onClose}>
      <div className={`${modalClassName} ${show ? styles.active : null}`} onClick={(e) => e.stopPropagation()}>
        {showCloseButton ? (
          <a className={styles.closeModal} onClick={onClose}>
            <svg viewBox="0 0 20 20">
              <path
                fill="#000000"
                d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
              ></path>
            </svg>
          </a>
        ) : null}
        <div style={{ width: '100%', paddingTop: '2rem' }}>{children}</div>
      </div>
    </div>
  );
};
