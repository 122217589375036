import React, { useRef, useEffect, useState } from 'react';
import isGoogle from '../../helper/isGoogle';
import { Input } from '../Base/Input/Input';
import './GPlacesAutocomplete.scss';

interface IGPlacesAutocomplete {
  onSelectedChanged: (selectedPlace: google.maps.places.PlaceResult) => void;
  initialText?: string;
  placeholder?: string;
  boundry?: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral;
}

const GPlacesAutocomplete: React.FC<IGPlacesAutocomplete> = ({ onSelectedChanged, initialText, placeholder, boundry }) => {
  const [text, setText] = useState(initialText);
  const [initialized, setInitialized] = useState(false);
  const placesAutocomplete = useRef<google.maps.places.Autocomplete>();

  /**
   * initialize
   */
  useEffect(() => {
    const init = () => {
      if (!isGoogle()) {
        setTimeout(() => {
          init();
        }, 500);
        return;
      }

      const autocompleteOptions: google.maps.places.AutocompleteOptions = {
        strictBounds: true,
      };

      placesAutocomplete.current = new google.maps.places.Autocomplete(
        document.getElementById('google-autocompelete') as HTMLInputElement,
        autocompleteOptions,
      );

      setTimeout(() => {
        const pacContainerParent = document.getElementById('pac-container-parent');
        const pacContainer = document.getElementsByClassName('pac-container')[0];
        if (pacContainer) pacContainerParent?.appendChild(pacContainer);
      }, 1000);

      setInitialized(true);
    };

    init();

    return () => {
      const pacContainers = document.getElementsByClassName('pac-container');
      for (let i = 0; i < pacContainers.length; i += 1) {
        const pacContainer = pacContainers[i];
        pacContainer.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (boundry) {
      setText('');
      onSelectedChanged({ name: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundry]);

  /**
   * Listener
   */
  useEffect(() => {
    const placeChangeListener = placesAutocomplete.current?.addListener('place_changed', () => {
      const place = placesAutocomplete.current?.getPlace();

      if (place) {
        setText(`${place.name}`);
        onSelectedChanged(place);
      }
    });

    return () => {
      if (placeChangeListener) {
        google.maps.event.removeListener(placeChangeListener);
      }
    };
  }, [onSelectedChanged]);

  /**
   * setBounds
   */
  useEffect(() => {
    // console.log(boundry);
    if (placesAutocomplete.current && boundry) {
      placesAutocomplete.current.setBounds(boundry);
    }
  }, [initialized, boundry]);

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  return (
    <>
      <Input
        type="text"
        name="google-autocompelete"
        id="google-autocompelete"
        size="large"
        value={text || ''}
        placeholder={placeholder}
        disabled={!boundry}
        onChange={(event) => {
          if (!event.target.value) {
            onSelectedChanged({ name: '' });
          }
          setText(event.target.value);
        }}
      />
      <div id="pac-container-parent" />
    </>
  );
};

export default GPlacesAutocomplete;
