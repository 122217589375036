import React from 'react';
import { CN } from '../../className';
import styles from './InputLabel.module.scss';

export interface InputLabelProps {
  text: string;
  customClassName?: string;
  textUppercase?: boolean;
  htmlFor?: string;
  children?: React.ReactNode;
}

export const InputLabel: React.FC<InputLabelProps> = ({ text, customClassName = '', textUppercase = true, htmlFor, children }) => {
  return (
    <div
      className={CN(styles.inputLabelDiv, [
        [customClassName, customClassName !== ''],
        [styles.textUppercase, textUppercase === true],
      ])}
    >
      <label htmlFor={htmlFor} className={htmlFor && styles.inputLabelHtmlFor}>
        <span className={styles.inputLabel}>{text}</span>
      </label>
      {children}
    </div>
  );
};
