import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { CHANGE_PHOTO, OFFER_CREATE, OFFER_PREVIEW } from '../../const/ROUTER_PATH_TITLE';
import { api } from '@tripian/core';
import Model from '@tripian/model';
import { initialOfferFormData } from '../../helper/constants';

export type OfferStatusType = 'Active' | 'Inactive' | 'Archived';

export const useOffersMenuEvents = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const history = useHistory();

  const onItemClick = (offer: Model.Offer) => history.push(`${OFFER_PREVIEW.PATH}/${offer.id}`, offer);
  const onChangePhoto = (offer: Model.Offer): void => history.push(CHANGE_PHOTO.PATH + '/' + offer.id);
  const onDuplicate = (offer: Model.Offer): void => {
    let duplicateOffer = { ...offer };

    if (moment(offer.timeframe.start).isAfter(moment())) {
      duplicateOffer.timeframe.start = initialOfferFormData.timeframe.start;
    }

    if (moment(offer.timeframe.end).isAfter(moment())) {
      duplicateOffer.timeframe.end = initialOfferFormData.timeframe.end;
    }

    history.push(`${OFFER_CREATE.PATH}`, duplicateOffer);
  };

  const onInactivate = async (offer: Model.Offer): Promise<Model.Offer> => {
    setLoading(true);
    return api
      .businessOfferStatusUpdate(offer.id, Model.OFFER_STATUS.INACTIVE)
      .catch((er) => {
        console.error(er);
        return Promise.reject(er);
      })
      .finally(() => setLoading(false));
  };

  const onActivate = async (offer: Model.Offer): Promise<Model.Offer | undefined> => {
    let newWarningMessage = '';
    if (offer.status === Model.OFFER_STATUS.EXPIRED) {
      newWarningMessage = 'Expired offers cannot reactivate. Please duplicate this offer and update date range.';
    } else if (offer.offerType !== Model.OFFER_TYPE.VOUCHER && moment(offer.timeframe?.start) < moment()) {
      newWarningMessage = 'Times and date may have out of range. Please duplicate this offer and update the date range.';
    } else if (0 < offer.usage) {
      newWarningMessage = 'This offer has been opt-in by some customers before. Please duplicate this offer and update date range.';
    }
    if (newWarningMessage !== '') {
      setWarningMessage(newWarningMessage);
      return undefined;
    }

    setLoading(true);
    return api
      .businessOfferStatusUpdate(offer.id, Model.OFFER_STATUS.ACTIVE)
      .catch((er) => {
        setErrorMessage(er.toString());
        return undefined;
      })
      .finally(() => setLoading(false));
  };

  const onArchive = async (offer: Model.Offer): Promise<Model.Offer | undefined> => {
    setLoading(true);
    return api
      .businessOfferStatusUpdate(offer.id, Model.OFFER_STATUS.ARCHIVE)
      .catch((er) => {
        setErrorMessage(er);
        return undefined;
      })
      .finally(() => setLoading(false));
  };

  const onDelete = async (offer: Model.Offer): Promise<number | undefined> => {
    setLoading(true);
    return api
      .businessOfferDelete(offer.id)
      .then((res) => res)
      .catch((er) => {
        setErrorMessage(er);
        return undefined;
      })
      .finally(() => setLoading(false));
  };

  return {
    loading,
    setLoading,
    warningMessage,
    setWarningMessage,
    errorMessage,
    setErrorMessage,
    onItemClick,
    onChangePhoto,
    onDuplicate,
    onInactivate,
    onActivate,
    onArchive,
    onDelete,
  };
};
