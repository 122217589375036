enum USER_ACTION_TYPES {
  SET_PAYLOAD = 'SET_PAYLOAD',
  SET_USER = 'SET_USER',
  SET_BUSINESS = 'SET_BUSINESS',
  LOG_OUT = 'LOG_OUT',
  // style
  SET_MENU_OPEN = 'SET_MENU_OPEN',
}

// const SET_USER = 'SET_USER';
// const SET_BUSINESS = 'SET_BUSINESS';
// const LOG_OUT = 'LOG_OUT';

// type USER_ACTION_TYPES = SET_USER | 'SET_BUSINESS' | 'LOG_OUT';

// export { SET_USER, LOG_OUT };
// export type { USER_ACTION_TYPES };

export default USER_ACTION_TYPES;
