import moment from 'moment';
import { TableBody, TableCell, TableRow } from '@mui/material';
import Model from '@tripian/model';

type Props = {
  displayRows: Model.BusinessOfferReport;
};

export const ReportTableBody: React.FC<Props> = ({ displayRows }) => {
  return (
    <TableBody>
      {displayRows.map((displayRow, i) => (
        <TableRow key={`${displayRow.id}-${i}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {displayRow.id}
          </TableCell>
          <TableCell>{displayRow.businessName}</TableCell>
          <TableCell>{displayRow.offerTitle}</TableCell>
          <TableCell>{displayRow.optinCount}</TableCell>
          <TableCell>{moment(displayRow.startDate).format('MMM D, YYYY')}</TableCell>
          <TableCell>{moment(displayRow.endDate).format('MMM D, YYYY')}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
