import Model from '@tripian/model';

const parseAccessToken = (idToken: string): Model.TokenPayload | undefined => {
  try {
    const base64Url: string = idToken.split('.')[1];
    const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload: string = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Invalid access token', error);
    return undefined;
  }
};

const parseToken = (token: Model.Token): Model.TokenPayload | undefined => {
  const idToken = token.idToken;
  return parseAccessToken(idToken);
};

const isExpirePayload = (tokenPayload: Model.TokenPayload): boolean => Date.now() / 1000 - tokenPayload.auth_time > 2400000;

const expirePayloadSecond = (tokenPayload: Model.TokenPayload): number => tokenPayload.exp - Date.now() / 1000;

const refreshTokenExpired = (token: Model.Token): boolean => {
  const tokenPayload = parseToken(token);
  if (tokenPayload) return isExpirePayload(tokenPayload);
  return false;
};

const idTokenExpireSeconds = (token: Model.Token): number => {
  const tokenPayload = parseToken(token);
  if (tokenPayload) return expirePayloadSecond(tokenPayload);
  return 0;
};

export { refreshTokenExpired, idTokenExpireSeconds };
