import React, { useState } from 'react';
import moment from 'moment';
import { DatePicker } from '../DatePicker/DatePicker';
import { Button } from '../Base/Button/Button';
import styles from './DateRangePicker.module.scss';

interface IDateRangePickerTemplate {
  range: { startDate: string; endDate: string };
  onchanged: (startDate: string, endDate: string) => void;
}

const DateRangePicker: React.FC<IDateRangePickerTemplate> = ({ range, onchanged }) => {
  const [dateRange, setDateRange] = useState<{ startDate?: string; endDate?: string }>({
    startDate: range.startDate,
    endDate: '',
  });

  return (
    <div className={`${styles.main} row mb0`}>
      <div className="col col5 p0 pr1">
        <DatePicker
          label="Start Date"
          slotProps={{ textField: { color: 'info', focused: false } }}
          typeable={false}
          value={moment(dateRange.startDate)}
          minDate={moment(range.startDate)}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setDateRange({ ...dateRange, startDate: date });
            }
          }}
          disablePast
        />
      </div>
      <div className="col col5 p0">
        <DatePicker
          label="End Date"
          slotProps={{ textField: { color: 'info', focused: false } }}
          typeable={false}
          value={moment(dateRange.endDate)}
          minDate={moment(dateRange.startDate)}
          maxDate={moment(range.endDate)}
          onChange={(value: moment.Moment | null) => {
            if (value) {
              const date = value.format('YYYY-MM-DD');
              setDateRange({ ...dateRange, endDate: date });
            }
          }}
          disablePast
        />
      </div>
      <div className="col col2 p0">
        <Button
          type="primary"
          size="small"
          onClick={() => {
            if (dateRange.startDate && dateRange.endDate) {
              onchanged(dateRange.startDate, dateRange.endDate);
              setDateRange({ startDate: '', endDate: '' });
            }
          }}
          label="Add date"
          disabled={!dateRange.startDate || !dateRange.endDate}
          customClassName={styles.customButton}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
