import Model from '@tripian/model';

const validateCampaignForFirstStep = (campaignData: Model.CampaignAddRequest): boolean => {
  if (campaignData.title.trim() === '') return false;
  else if (campaignData.startDate === '' || campaignData.startDate === undefined) return false;
  else if (campaignData.endDate === '' || campaignData.endDate === undefined) return false;
  else if (campaignData.amount === 0) return false;
  else if (campaignData.maximumRecipients === 0) return false;
  else if (campaignData.maximumOfferAmount === 0) return false;
  else if (campaignData.description.trim() === '') return false;
  return true;
};

export { validateCampaignForFirstStep };
