/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { api } from '@tripian/core';
import Model, { helper } from '@tripian/model';

import UserContext from '../../../context/User/UserContext';

import { OFFERS, PREREGISTER, SIGNUP } from '../../../const/ROUTER_PATH_TITLE';
import USER_ACTION_TYPES from '../../../context/User/USER_ACTION_TYPES';
import IPayloadData from '../../../context/User/IPayloadData';
import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { Input } from '../../../components/Base/Input/Input';
import { Button } from '../../../components/Base/Button/Button';
import { InputLabel } from '../../../components/Base/InputLabel/InputLabel';
import { LinkButton } from '../../../components/Base/LinkButton/LinkButton';
import { ModalPage } from '../../../components/Base/ModalPage/ModalPage';
import { AutoComplete } from '../../../components/Base/AutoComplete/AutoComplete';
import { RSelect } from '../../../components/RSelect/RSelect';
import { getId, scrollTop } from '../../../helper/common';
import { LineStep } from '../../../components/Base/LineStep/LineStep';
import { FormContainer } from '../../../components/Base/FormContainer/FormContainer';
import styles from './BusinessAssignPage.module.scss';

export const BusinessAssignPage = () => {
  const [formData, setFormData] = useState<{ cityId?: number; poi?: Model.BusinessSearch }>({ cityId: undefined, poi: undefined });
  const [tosAccepted, setTosAccepted] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [queryLoader, setQueryLoader] = useState<boolean>(false);
  const [businessResultArray, setBusinessResultArray] = useState<Model.BusinessSearch[]>([]);

  const [needHelp, setNeedHelp] = useState(false);
  const [cities, setCities] = useState<Model.City[]>([]);

  const { dispatch } = useContext(UserContext);

  const history = useHistory();
  document.title = SIGNUP.TITLE;

  const myRef = React.createRef<HTMLDivElement>();

  const timerRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    let unmonted = false;

    if (!unmonted) {
      api.citiesAll().then((cities) => {
        setCities(cities);
      });
    }
    return () => {
      unmonted = true;
    };
  }, []);

  /*   useEffect(() => {
    setFormData((prev) => ({
      cityId: prev.cityId,
      poi: undefined,
    }));
  }, [formData.cityId]); */

  useEffect(() => {
    let unmounted = false;
    setQueryLoader(true);

    if (query.length > 2) {
      if (timerRef.current) window.clearTimeout(timerRef.current);
      const search = async () => {
        if (formData.cityId) {
          setBusinessResultArray([]);
          api
            .businessSearch(query, formData.cityId)
            .then((businessList: Model.BusinessSearch[]) => {
              if (!unmounted) {
                setBusinessResultArray(businessList);
              }
            })
            .finally(() => setQueryLoader(false)); // TODO cityId
          // setQueryLoader(false);
        } else {
          setQueryLoader(false);
        }
      };
      timerRef.current = window.setTimeout(() => {
        search();
      }, 400);
    } else {
      setQueryLoader(false);
      if (query.length === 0) setBusinessResultArray([]);
    }

    return () => {
      unmounted = true;
    };
  }, [formData.cityId, query]);

  useEffect(() => {
    scrollTop();
  }, []);

  const onSelectPoi = (value: any) => {
    const currentBusiness = businessResultArray.find((business) => getId(business).joinedId === value.id);
    setFormData((prev) => ({
      cityId: prev.cityId,
      poi: currentBusiness,
    }));
  };

  const onSelectCity = (value: any) => {
    setFormData({
      cityId: value.id,
      poi: undefined,
    });
  };

  const fetchPayloadData = async () => {
    console.log('fetchPayloadData called');
    return api.combo
      .businessPayloadData()
      .then((payloadData: IPayloadData) => {
        console.log('fetchPayloadData bitti 1', payloadData);
        dispatch({
          type: USER_ACTION_TYPES.SET_PAYLOAD,
          payload: payloadData,
        });
        history.replace(OFFERS.PATH);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitForm = () => {
    if (formData.poi?.tripianPoiId) {
      api
        .businessAssign(formData.poi?.tripianPoiId)
        .then((user: Model.User) => {
          console.log('businessAssign response', user);
          // api.setToken(token);
          fetchPayloadData().then((d) => {
            console.log('fetchPayloadData bitti 2', d);
          });
          // return login({ email: formData.email, password: formData.password });
        })
        .catch((err) => {
          console.error('businessAssign error:', err);
        });
    } else {
      setLoading(false);
      myRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  return (
    <FullCenter loading={loading} centerY={false} maxWidth={1088} customClassName={styles.fullCenter} loaderFixed={true}>
      <LineStep stepCount={3} step={1} customClassName={styles.lineStep} />
      <div className={styles.hContainer}>
        <h4>Business Register</h4>
      </div>

      <FormContainer customClassName={styles.formContainer}>
        <form onSubmit={submitForm}>
          <div ref={myRef}>
            {/* <ErrorMessage message={errorMessage} /> */}
            <div className="row">
              <div className="col col12 col6-m">
                <InputLabel text="City" />
                <RSelect
                  options={cities
                    .sort((a, b) => helper.compareStringForSort(a.name, b.name))
                    .map((city) => ({
                      id: city.id,
                      label: city.name,
                      payload: city,
                      isSelected: formData.cityId === city.id,
                    }))}
                  defaultOptionId={formData.cityId}
                  onSelectedChanged={onSelectCity}
                />
              </div>
              <div className="col col12 col6-m">
                <InputLabel text="Business">
                  <LinkButton text="Need help?" onClick={() => setNeedHelp(true)} />
                </InputLabel>
                <AutoComplete
                  onChange={(qr) => {
                    setQuery(qr);
                  }}
                  options={businessResultArray.map((businessList) => {
                    const ids = getId(businessList);
                    return {
                      id: ids.joinedId,
                      label: businessList.business.name,
                      extra: businessList.business.address,
                    };
                  })}
                  value={
                    formData.poi
                      ? {
                          id: 0,
                          label: formData.poi.business.name,
                        }
                      : null
                  }
                  onSelect={onSelectPoi}
                  loading={queryLoader}
                  placeholder="Search your business"
                />
              </div>
            </div>
            <div className="row">
              <div className={`col col12 col8-m ${styles.tou}`}>
                <Input
                  type="checkbox"
                  name="tou"
                  size="large"
                  checked={tosAccepted}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setTosAccepted(event.target.checked)}
                />
                <span className={styles.label}>
                  I accept the{' '}
                  <a className={styles.link} href={window.tconfig.TOS_URL} target="_blank" rel="noreferrer">
                    Terms of Use
                  </a>
                </span>
              </div>
            </div>
            <Button
              disabled={!tosAccepted || formData.cityId === undefined || formData.poi === undefined}
              onClick={() => {
                setLoading(true);
                submitForm();
              }}
              label="Assign Business"
              type="primary"
              // disabled={!validateSignUp(formData).isValid || !tosAccepted}
            />
          </div>
        </form>
      </FormContainer>

      <ModalPage size="small" show={needHelp} onClose={() => setNeedHelp(false)}>
        <div style={{ padding: '2rem 0', fontSize: '1.5rem', textAlign: 'center' }}>
          If your business does not appear in the search results,
          <br />
          you may go to
          <LinkButton
            customClassName={styles.preRegisterButton}
            onClick={() => {
              setNeedHelp(false);
              history.push(PREREGISTER.PATH);
            }}
            text="Pre Register"
          />
          to register your business for inclusion in Tripian.
        </div>
        <div style={{ padding: '2rem 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              setNeedHelp(false);
            }}
            label="OK"
            type="primary"
          />
        </div>
      </ModalPage>
    </FullCenter>
  );
};
