import moment from 'moment';
import Model, { helper } from '@tripian/model';
import { Validate } from '../../model/Validate';

export const initialValidate: Validate = { isValid: false, message: undefined };

const validateTravelInformation = (travelInformation: Model.TravelInformation): Validate[] => {
  const result: Validate[] = [];
  result.push({ isValid: travelInformation.adults !== 0, message: 'Your size of Travel Group must be more than 1.' });
  result.push({ isValid: travelInformation.nameOfProperty.trim() !== '', message: 'Please select name of property.' });
  result.push({ isValid: travelInformation.proofOfHolidayBookingUrl.trim() !== '', message: 'Please upload booking info.' });
  return result;
};

const validatePersonalInformation = (personalInformation: Model.PersonalInformation, otherEmails: string[]): Validate[] => {
  const result: Validate[] = [];

  result.push({ isValid: personalInformation.title.trim() !== '', message: 'Please select a title.' });
  result.push({ isValid: personalInformation.firstName.trim() !== '', message: 'Please enter your first name.' });
  result.push({ isValid: personalInformation.lastName.trim() !== '', message: 'Please enter your last name.' });
  result.push({
    isValid: personalInformation.arrivalDate !== '' && personalInformation.arrivalDate !== undefined,
    message: 'Please select your arrival date.',
  });
  if (personalInformation.departureDate === '' || personalInformation.departureDate === undefined) {
    result.push({ isValid: false, message: 'Please select your departure date.' });
  } else if (moment(personalInformation.departureDate).diff(moment(personalInformation.arrivalDate), 'days', false) < 4) {
    result.push({ isValid: false, message: 'Your travel must be more than 4 days.' });
  } else {
    result.push({ isValid: true, message: undefined });
  }
  result.push({ isValid: personalInformation.departureDestination.trim() !== '', message: 'Please enter where are you arriving from.' });
  result.push({ isValid: personalInformation.airline.trim() !== '', message: 'Please enter your airline.' });
  result.push({ isValid: personalInformation.flightNumber.trim() !== '', message: 'Please enter your flight number.' });
  if (personalInformation.dateOfBirth === '' || personalInformation.dateOfBirth === undefined) {
    result.push({ isValid: false, message: 'Please enter your date of birth.' });
  } else if (moment().diff(personalInformation.dateOfBirth, 'years', false) < 18) {
    result.push({ isValid: false, message: 'You must be more than 18 years of age.' });
  } else {
    result.push({ isValid: true, message: undefined });
  }
  let emailInvalidMessage = emailValidation(personalInformation.emailAddress);
  if (emailInvalidMessage === undefined && otherEmails.includes(personalInformation.emailAddress))
    emailInvalidMessage = 'Please enter a different email address for each person.';
  result.push({ isValid: emailInvalidMessage === undefined, message: emailInvalidMessage });
  const phoneNumberInvalidMessage = validatePhoneForE164(personalInformation.phoneNumber);
  result.push({ isValid: phoneNumberInvalidMessage === undefined, message: phoneNumberInvalidMessage });

  return result;
};

const validateMarketingQuestions = (marketingQuestions: Model.MarketingQuestions): Validate[] => {
  const result: Validate[] = [];
  console.log('marketingQuestions', marketingQuestions);
  result.push({ isValid: marketingQuestions.howDidYouHear.trim() !== '', message: 'Please enter how did you hear.' });
  result.push({ isValid: marketingQuestions.howDidYouBookYourTrip.trim() !== '', message: 'Please enter how did you book your trip.' });
  return result;
};

const emailValidation = (email: string): string | undefined => {
  if (email.trim() === '') return 'Please enter your email address.';

  const emailValid = helper.emailFormatValid(email);
  if (!emailValid) return 'Email is invalid.';
  return undefined;
};

const validatePhoneForE164 = (phoneNumber: string) => {
  if (phoneNumber === '') return 'Please enter your phone number.';
  const regEx = /^\+?[1-9]\d{1,14}$/;
  if (regEx.test(phoneNumber) === false) return 'Phone number is invalid.';
  return undefined;
};

const ordinalSuffix = (i: number) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export { validateTravelInformation, validatePersonalInformation, validateMarketingQuestions, ordinalSuffix };
