import IPayloadData from './IPayloadData';
import { api } from '@tripian/core';
import { removeLocalStorageToken } from '../../helper/localStorage';
import USER_ACTION_TYPES from './USER_ACTION_TYPES';

const reducer = (
  state: IPayloadData = {},
  action: {
    type: USER_ACTION_TYPES;
    payload: IPayloadData;
  },
) => {
  switch (action.type) {
    case USER_ACTION_TYPES.SET_PAYLOAD:
      return action.payload;

    case USER_ACTION_TYPES.SET_USER:
      console.log('action.payload', action.payload);
      return {
        ...state,
        user: action.payload.user,
      };

    case USER_ACTION_TYPES.SET_BUSINESS:
      return {
        ...state,
        business: action.payload.business,
      };

    case USER_ACTION_TYPES.LOG_OUT:
      removeLocalStorageToken();
      api.removeToken();

      return {};

    case USER_ACTION_TYPES.SET_MENU_OPEN:
      return {
        ...state,
        menuOpen: action.payload.menuOpen,
      };

    default:
      return state;
  }
};
export default reducer;
