import Model from '@tripian/model';
import React, { useRef } from 'react';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '../../../../components/Base/Button/Button';
import { VoucherOfferCard } from '../../../../components/VoucherOfferCard/VoucherOfferCard';
/* import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'; */
import { toPng } from 'html-to-image';
import styles from './VoucherOfferPreviewItem.module.scss';

type Props = {
  offer?: Model.Offer;
  businessName?: string;
  customers: Model.OfferCustomer[];
  customerLoading: boolean;
  goBack: () => void;
};

export const VoucherOfferPreviewItem: React.FC<Props> = ({ offer, businessName, customers, customerLoading, goBack }) => {
  const ref = useRef<HTMLDivElement>(null);

  /* const exportPdf = () => {
    if (ref.current) {
      html2canvas(ref.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const doc = new jsPDF({
          format: 'a4',
          unit: 'px',
        });
        doc.addImage(imgData, 'JPEG', 35, 35, 0, 0);
        doc.save('download.pdf');
      });
    }
  }; */

  const exportPng = () => {
    if (ref.current) {
      toPng(ref.current, { cacheBust: false })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = `${offer?.title}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className={styles.hContainer}>
        {offer ? (
          <div className={styles.printContainer}>
            <div ref={ref} className={styles.voucherOfferCard}>
              <VoucherOfferCard offer={offer} businessName={businessName} />
            </div>
          </div>
        ) : null}
        {customerLoading ? (
          <div>Customers loading.</div>
        ) : customers.length === 0 ? (
          <div>No customers have opted in to this offer.</div>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>NAME</TableCell>
                    <TableCell>SURNAME</TableCell>
                    <TableCell>ARRIVAL DATE</TableCell>
                    <TableCell>DEPARTURE DATE</TableCell>
                    <TableCell>STATUS</TableCell>
                    <TableCell>REIMBURSED DATE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.map((customer) => (
                    <TableRow
                      key={`${customer.customerFirstName}-${customer.customerLastName}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {customer.customerFirstName}
                      </TableCell>
                      <TableCell>{customer.customerLastName}</TableCell>
                      <TableCell>{moment(customer.arrivalDate).format('DD.MM.YYYY')}</TableCell>
                      <TableCell>{moment(customer.departureDate).format('DD.MM.YYYY')}</TableCell>
                      <TableCell>{customer.status}</TableCell>
                      {customer.reimbursedDate ? (
                        <TableCell>{moment(customer.reimbursedDate).format('DD.MM.YYYY')}</TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
      <div className={`${styles.voucherOfferPreviewButtons} row`}>
        <div className="col col12 col6-m">
          <Button onClick={goBack} label="Back" type="secondary" customClassName={styles.button} />
        </div>
        <div className="col col12 col6-m">
          <Button onClick={exportPng} label="download QR" type="secondary" customClassName={styles.button} />
        </div>
      </div>
    </>
  );
};
