/* eslint-disable import/no-extraneous-dependencies */
import Model from '@tripian/model';
import { DataModel } from './dataModel';

/**
 * data
 */
const data: DataModel = {
  // PROGRAM
  /**
   * initialised is default false. When core.init function called it will be true.
   */
  initialised: false,

  /**
   * If last operation of core has any error it is true else it is false.
   * You have to set back to false manually after you handle your error case.
   */
  hasError: false,

  /**
   * If last operation of core has any error it is true else it is false.
   * You have to set back to false manually after you handle your error case.
   */
  errors: [],
  logs: [],

  configList: undefined,

  // CITY
  /**
   * All cities list (limit=300).
   */
  cities: {} as Record<Model.LangCodeKey, Model.City[]>,
  /**
   * Search result cities list (Only with name for now).
   */
  // citiesSearch: undefined,
  /**
   * Last interested city info.
   */
  // city: undefined,
  /**
   * City info that last found by coordinate (Web pages don't use this function+data yet).
   */
  // cityFind: undefined,

  feedbacks: {} as Record<Model.LangCodeKey, Model.Feedbacks>,
  userFeedbacks: {} as Record<Model.LangCodeKey, Model.UserFeedback[]>,

  // POI
  /**
   * All POI categories of POI datas (limit=100).
   */
  poiCategories: {} as Record<Model.LangCodeKey, Model.PoiCategory[]>,
  /**
   * All interested pois' info since core initialised. We cache all poi infos we get from API (may come from search, dailyPlanPoi, alternatives ..).
   */
  pois: {} as Record<Model.LangCodeKey, { showOffersOnly: number; pois: Model.Poi[] }[]>,
  /**
   * Current interested poi's info (may return from API or cached pois array).
   */
  // poi: undefined,

  // Questions
  /**
   * It joins questionsTrip, questionsProfile and questionsCompanion.
   */
  questions: {} as Record<Model.LangCodeKey, Model.Question[]>,
  /**
   * Questions for trip when we create trip or edit trip (We cache this data).
   */
  questionsTrip: {} as Record<Model.LangCodeKey, Model.Question[]>,
  /**
   * Questions for person when we create or edit user profile (We cache this data).
   */
  questionsProfile: {} as Record<Model.LangCodeKey, Model.Question[]>,
  /**
   * Questions for companion when we create or edit companion profile (We cache this data).
   */
  questionsCompanion: {} as Record<Model.LangCodeKey, Model.Question[]>,

  topTens: {} as Record<Model.LangCodeKey, { cityId: number; topTen: Model.TopTen[] }[]>,

  /**
   * User
   */
  // register: undefined,
  // token: undefined,
  user: undefined,

  /**
   * Favorites
   */
  favorites: {} as Record<Model.LangCodeKey, { cityId: number; favoritePois: Model.Favorite[] }[]>,

  /**
   * Companions
   */
  companions: {} as Record<Model.LangCodeKey, Model.Companion[]>,

  /**
   * Notifications
   */
  // notifications: undefined,
  /**
   * Reservations
   */
  reservations: {} as Record<Model.LangCodeKey, { cityId: number; userReservations: Model.UserReservation[] }[]>,

  /**
   * User Reactions
   */
  userReactions: {} as Record<Model.LangCodeKey, { tripHash: string; userReactions: Model.UserReaction[] }[]>,

  /**
   * Trips
   */
  tripRefs: undefined,
  tripSavedRefs: undefined,
  trips: {} as Record<Model.LangCodeKey, Model.Trip[]>,
  trip: {} as Record<Model.LangCodeKey, Model.Trip>,
  /**
   * Recommendations
   */
  // recommendations: undefined,

  /**
   * This data has used for explorer places first page for each cityId.
   */
  poisAll: {} as Record<
    Model.LangCodeKey,
    { cityId: number; poiCategories: number[]; showOffersOnly: number; pois: Model.DataPayload<Model.Poi[]> }[]
  >,

  autoCompleteTags: {} as Record<Model.LangCodeKey, { id: number; name: string }[]>,
  autoCompleteTagsAll: {} as Record<Model.LangCodeKey, { cityId: number; poiCategories: number[]; tags: { id: number; name: string }[] }[]>,
};

export default data;
