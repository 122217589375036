import React from 'react';
import { CN } from '../../className';
import styles from './LinkButton.module.scss';

export interface LinkButtonProps {
  text: string;
  onClick: () => void;
  customClassName?: string;
  bold?: boolean;
}

export const LinkButton: React.FC<LinkButtonProps> = ({ text, onClick, customClassName = '', bold }) => {
  return (
    <span className={CN(bold ? styles.linkButtonBold : styles.linkButton, [[customClassName, customClassName !== '']])} onClick={onClick}>
      {text}
    </span>
  );
};
