import { FullCenter } from '../../../components/Base/FullCenter/FullCenter';
import { ReportTable } from './ReportTable/ReportTable';
import { useBusinessOfferReport } from './useBusinessOfferReport';
import classes from './BusinessOfferReportPage.module.scss';

export const BusinessOfferReportPage = () => {
  const { loading, data } = useBusinessOfferReport();

  return (
    <FullCenter centerY={false} customClassName={classes.reportContainer} loading={loading}>
      <ReportTable fullRows={data} />
    </FullCenter>
  );
};
