import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { api } from '@tripian/core';
import Model from '@tripian/model';
import { useHistory, useLocation } from 'react-router';
import { refreshTokenExpired } from '../../helper/jwt';
import { Icon } from '../../components/Base/Icon/Icon';
import { getLocalStorageToken, removeLocalStorageToken, saveLocalStorageToken } from '../../helper/localStorage';
import {
  APPLICATIONS,
  APPLICATION_DETAILS,
  BUSINESS_PROFILE,
  CAMPAIGNS,
  CAMPAIGN_REPORT,
  CAMPAIGN_CREATE,
  CAMPAIGN_ADMIN_USER_SETTINGS,
  LOGIN,
  OFFERS,
  USER_SETTINGS,
  BUSINESS_REPORT,
  CAMPAIGN_CUSTOMER_REPORT,
  QR_READER,
  BUSINESS_OFFER_REPORT,
} from '../../const/ROUTER_PATH_TITLE';
import { CN } from '../../components/className';
import { AppLoader } from '../../components/AppLoader/AppLoader';
import AppLogo from '../../components/AppLogo/AppLogo';
import IPayloadData from '../../context/User/IPayloadData';
import UserContext from '../../context/User/UserContext';
import USER_ACTION_TYPES from '../../context/User/USER_ACTION_TYPES';
import styles from './PageLayout.module.scss';

export interface PageLayoutProps {
  children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [menuPath, setMenuPath] = useState<string>(OFFERS.PATH);

  const { payloadData, dispatch } = useContext(UserContext);
  const mobileMenuOpen = useMemo(() => payloadData.menuOpen === true, [payloadData]);
  const setMobileMenuOpen = useCallback(
    (newMenuOpen: boolean) => {
      dispatch({ type: USER_ACTION_TYPES.SET_MENU_OPEN, payload: { menuOpen: newMenuOpen } });
    },
    [dispatch],
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let unmonted = false;

    if (!unmonted) {
      // read browser local store
      const localStorageToken = getLocalStorageToken();

      // has ex token
      if (localStorageToken) {
        // parse to token

        // check expired for refresh
        const isRefreshTokenExpired: boolean = refreshTokenExpired(localStorageToken);

        if (isRefreshTokenExpired) {
          api.removeToken();
          removeLocalStorageToken();
          dispatch({ type: USER_ACTION_TYPES.LOG_OUT, payload: {} });
          setLoading(false);
        } else {
          api.setToken(localStorageToken);

          api
            .refreshToken()
            .then((token: Model.Token) => {
              saveLocalStorageToken(token);

              api.combo
                .businessPayloadData()
                .then((payloadData: IPayloadData) => {
                  dispatch({
                    type: USER_ACTION_TYPES.SET_PAYLOAD,
                    payload: payloadData,
                  });
                })
                .catch(() => {
                  dispatch({ type: USER_ACTION_TYPES.LOG_OUT, payload: {} });
                  history.push(LOGIN.PATH);
                })
                .finally(() => {
                  setLoading(false);
                });
            })
            .catch((er) => {
              console.log('refresh', er);
              removeLocalStorageToken();
              setLoading(false);
            });
        }
      } else {
        setLoading(false);
      }
    }

    return () => {
      unmonted = true;
    };
  }, [dispatch, history]);

  useEffect(() => {
    // set menu path in refresh
    // console.log(location.pathname);
    setMenuPath(location.pathname);
  }, [location.pathname]);

  const menuClick = (path: string) => {
    setMobileMenuOpen(false);
    // if (index !== menuIndex) {
    history.push(path);
    setMenuPath(path);
    // }
  };

  const logout = () => {
    api.logout();
    dispatch({ type: USER_ACTION_TYPES.LOG_OUT, payload: {} });
    history.push(LOGIN.PATH);
    setMenuPath(OFFERS.PATH);
  };

  const isCampaignAdminPrivatePage = useMemo(
    () => payloadData.user && payloadData.user.userType === Model.USER_TYPE.COUPON_ADMIN,
    [payloadData.user],
  );

  const isSuperAdminPrivatePage = useMemo(() => payloadData.user && payloadData.user.userType === Model.USER_TYPE.SUPER_ADMIN, [payloadData.user]);

  const isPrivatePage = useMemo(
    () => payloadData.user && payloadData.business && payloadData.business.isVerified,
    [payloadData.business, payloadData.user],
  );

  if (loading) return <AppLoader active={loading} />;

  if (isCampaignAdminPrivatePage || isSuperAdminPrivatePage) {
    return (
      <main className={styles.pcontainer}>
        <div className={`${styles.sideMenu} ${mobileMenuOpen ? '' : styles.close}`}>
          <div className={styles.sideMenuContent}>
            <section className={styles.logo}>
              <AppLogo dark height="4rem" className={styles.tripianPrivateLogo} />
              <div className={styles.toggle} onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                {mobileMenuOpen ? <Icon name="CLOSE" className="pt4" /> : <Icon name="MENU" className="pt4" />}
              </div>
            </section>
            <section>
              {isCampaignAdminPrivatePage ? (
                <div className={styles.adminTools}>Campaign Admin Tools</div>
              ) : isSuperAdminPrivatePage ? (
                <div className={styles.adminTools}>Super Admin Tools</div>
              ) : null}
            </section>
            <div className={styles.menu}>
              {isCampaignAdminPrivatePage && (
                <>
                  <div
                    className={CN(styles.menuItem, [
                      [
                        styles.active,
                        menuPath === '/' ||
                          menuPath === CAMPAIGNS.PATH ||
                          menuPath.startsWith(`${CAMPAIGN_REPORT.PATH}/`) ||
                          menuPath.startsWith(`${CAMPAIGN_CUSTOMER_REPORT.PATH}/`) ||
                          menuPath === CAMPAIGN_CREATE.PATH,
                      ],
                    ])}
                    onClick={() => menuClick(CAMPAIGNS.PATH)}
                  >
                    <Icon name="MENU_OFFERS" />
                    Campaigns
                  </div>
                  <div
                    className={CN(styles.menuItem, [
                      [styles.active, menuPath === APPLICATIONS.PATH || menuPath.startsWith(APPLICATION_DETAILS.PATH)],
                    ])}
                    onClick={() => menuClick(APPLICATIONS.PATH)}
                  >
                    <Icon name="MENU_BUSINESS" />
                    Applications
                  </div>
                  <div
                    className={CN(styles.menuItem, [[styles.active, menuPath === BUSINESS_REPORT.PATH]])}
                    onClick={() => menuClick(BUSINESS_REPORT.PATH)}
                  >
                    <Icon name="MENU_BUSINESS_REPORT" />
                    Business Report
                  </div>
                </>
              )}

              {isSuperAdminPrivatePage && (
                <>
                  <div
                    className={CN(styles.menuItem, [[styles.active, menuPath === BUSINESS_OFFER_REPORT.PATH]])}
                    onClick={() => menuClick(BUSINESS_OFFER_REPORT.PATH)}
                  >
                    <Icon name="MENU_BUSINESS_REPORT" />
                    {BUSINESS_OFFER_REPORT.TITLE}
                  </div>
                  <div className={CN(styles.menuItem, [[styles.active, menuPath === OFFERS.PATH]])} onClick={() => menuClick(OFFERS.PATH)}>
                    <Icon name="MENU_OFFERS" />
                    {OFFERS.TITLE}
                  </div>
                </>
              )}

              <div
                className={CN(styles.menuItem, [[styles.active, menuPath === CAMPAIGN_ADMIN_USER_SETTINGS.PATH || menuPath === USER_SETTINGS.PATH]])}
                onClick={() => {
                  if (isCampaignAdminPrivatePage) {
                    menuClick(CAMPAIGN_ADMIN_USER_SETTINGS.PATH);
                  } else {
                    menuClick(USER_SETTINGS.PATH);
                  }
                }}
              >
                <Icon name="MENU_USER" />
                User Settings
              </div>
            </div>
            <div className={styles.footerSpace} />

            <div className={styles.footer}>
              <a target="_blank" className={styles.tos} href={window.tconfig.TOS_URL} rel="noreferrer">
                Terms of Service
              </a>
              <a target="_blank" className={styles.tos} href={window.tconfig.PP_URL} rel="noreferrer">
                Privacy Policy
              </a>
              <div className={styles.logout} onClick={() => logout()}>
                <Icon name="MENU_LOGOUT" />
                Log Out
              </div>
            </div>
          </div>
        </div>
        <div id="pageLayout" className={styles.content}>
          {children}
        </div>
      </main>
    );
  }

  if (isPrivatePage)
    return (
      <main className={styles.pcontainer}>
        <div className={`${styles.sideMenu} ${mobileMenuOpen ? '' : styles.close}`}>
          <div className={styles.sideMenuContent}>
            <section className={styles.logo}>
              <AppLogo dark height="4rem" className={styles.tripianPrivateLogo} />
              <div className={styles.toggle} onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                {mobileMenuOpen ? <Icon name="CLOSE" className="pt4" /> : <Icon name="MENU" className="pt4" />}
              </div>
            </section>
            <section>
              <div className={styles.adminTools}>Business Owner Tools</div>
            </section>
            <div className={styles.menu}>
              <div
                className={CN(styles.menuItem, [[styles.active, menuPath === OFFERS.PATH || menuPath === '/']])}
                onClick={() => menuClick(OFFERS.PATH)}
              >
                <Icon name="MENU_OFFERS" />
                Offers
              </div>
              <div
                className={CN(styles.menuItem, [[styles.active, menuPath === BUSINESS_PROFILE.PATH]])}
                onClick={() => menuClick(BUSINESS_PROFILE.PATH)}
              >
                <Icon name="MENU_BUSINESS" />
                Business Profile
              </div>
              <div className={CN(styles.menuItem, [[styles.active, menuPath === QR_READER.PATH]])} onClick={() => menuClick(QR_READER.PATH)}>
                <Icon name="MENU_OFFERS" />
                Offer Payment
              </div>
              <div className={CN(styles.menuItem, [[styles.active, menuPath === USER_SETTINGS.PATH]])} onClick={() => menuClick(USER_SETTINGS.PATH)}>
                <Icon name="MENU_USER" />
                User Settings
              </div>
            </div>
            <div className={styles.footerSpace} />

            <div className={styles.footer}>
              <a target="_blank" className={styles.tos} href={window.tconfig.TOS_URL} rel="noreferrer">
                Terms of Service
              </a>
              <a target="_blank" className={styles.tos} href={window.tconfig.PP_URL} rel="noreferrer">
                Privacy Policy
              </a>
              <div className={styles.logout} onClick={() => logout()}>
                <Icon name="MENU_LOGOUT" />
                Log Out
              </div>
            </div>
          </div>
        </div>
        <div id="pageLayout" className={styles.content}>
          {children}
        </div>
      </main>
    );

  return (
    <div id="pageLayout" className={styles.container}>
      <AppLogo height="4rem" dark={false} className={styles.publicLogo} />
      {children}
    </div>
  );
};
