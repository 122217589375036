import { createContext } from 'react';
import USER_ACTION_TYPES from './USER_ACTION_TYPES';
import IPayloadData from './IPayloadData';

const UserContext = createContext<{
  payloadData: IPayloadData;
  dispatch: React.Dispatch<{
    type: USER_ACTION_TYPES;
    payload: IPayloadData;
  }>;
}>({
  payloadData: {},
  dispatch: () => null,
});

export default UserContext;
