export const removeDuplicateValuesById = <T extends { id: number }>(duplicateItems: T[]) => {
  const uniqueItems = duplicateItems.filter((item, itemIndex, allItems) => {
    const firstItemIndex = allItems.findIndex((i) => {
      if (item && i && item.id && i.id) return i.id === item.id;
      return false;
    });
    const isUniqueItem = firstItemIndex === itemIndex;
    return isUniqueItem;
  });
  return uniqueItems;
};
