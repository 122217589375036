import { useEffect } from 'react';
import AppRouter from './AppRouter/AppRouter';
import UserProvider from '../context/User/UserProvider';
import { helper } from '@tripian/model';
import * as gtag from '../gtag';
import useScript from './useScript';

const App = () => {
  useScript(window.tconfig.GOOGLE_ANALYTICS_URL || '');

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', window.tconfig.COLORS.PRIMARY);
    document.documentElement.style.setProperty('--primary-color-rgb', helper.hexToRgb(window.tconfig.COLORS.PRIMARY));
    document.documentElement.style.setProperty('--secondary-color', window.tconfig.COLORS.SECONDARY);
    document.documentElement.style.setProperty('--secondary-color-rgb', helper.hexToRgb(window.tconfig.COLORS.SECONDARY));
    document.documentElement.style.setProperty('--success-color', window.tconfig.COLORS.SUCCESS);
    document.documentElement.style.setProperty('--success-color-rgb', helper.hexToRgb(window.tconfig.COLORS.SUCCESS));
    document.documentElement.style.setProperty('--warning-color', window.tconfig.COLORS.WARNING);
    document.documentElement.style.setProperty('--warning-color-rgb', helper.hexToRgb(window.tconfig.COLORS.WARNING));
    document.documentElement.style.setProperty('--info-color', window.tconfig.COLORS.INFO);
    document.documentElement.style.setProperty('--info-color-rgb', helper.hexToRgb(window.tconfig.COLORS.INFO));
    document.documentElement.style.setProperty('--danger-color', window.tconfig.COLORS.DANGER);
    document.documentElement.style.setProperty('--danger-color-rgb', helper.hexToRgb(window.tconfig.COLORS.DANGER));
    document.documentElement.style.setProperty('--text-primary-color', window.tconfig.COLORS.TEXT_PRIMARY);
    document.documentElement.style.setProperty('--text-primary-color-rgb', helper.hexToRgb(window.tconfig.COLORS.TEXT_PRIMARY));
    document.documentElement.style.setProperty('--background-color', window.tconfig.COLORS.BACKGROUND);
    document.documentElement.style.setProperty('--background-color-rgb', helper.hexToRgb(window.tconfig.COLORS.BACKGROUND));
    document.documentElement.style.setProperty('--header-background-color', window.tconfig.COLORS.BACKGROUND);
    document.documentElement.style.setProperty('--header-background-color-rgb', helper.hexToRgb(window.tconfig.COLORS.BACKGROUND));
    document.documentElement.style.setProperty('--header-text-color', window.tconfig.COLORS.BACKGROUND);
    document.documentElement.style.setProperty('--header-text-color-rgb', helper.hexToRgb(window.tconfig.COLORS.BACKGROUND));
    document.documentElement.style.setProperty('--font-family', window.tconfig.BRAND_NAME === 'bookbarbados' ? 'Herokid' : 'Lato');
  }, []);

  useEffect(() => {
    gtag.pageview(window.location.pathname);
    document.documentElement.scrollTop = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <UserProvider>
      <AppRouter />
    </UserProvider>
  );
};

export default App;
