import React, { useCallback } from 'react';
import { IconName } from '../../../../../helper/constants';
import { SelectField } from '../../../../Base/SelectField/SelectField';

import styles from './CampaignAmount.module.scss';
import Model from '@tripian/model';

const AMOUNT_TYPES: Model.AMOUNT_TYPE[] = [Model.AMOUNT_TYPE.LIMITED, Model.AMOUNT_TYPE.UNLIMITED];

export interface CampaignAmountProps {
  selectedCategory?: Model.AMOUNT_TYPE;
  onChange: (category: Model.AMOUNT_TYPE) => void;
  disabled?: boolean;
}

export const CampaignAmount: React.FC<CampaignAmountProps> = ({ selectedCategory, onChange, disabled }) => {
  const memoizedIcon = useCallback((category: Model.AMOUNT_TYPE): IconName => {
    if (category === Model.AMOUNT_TYPE.LIMITED) {
      return 'LIMITED';
    }
    return 'UNLIMITED';
  }, []);

  return (
    <div className={styles.content}>
      {AMOUNT_TYPES.map((amount_type) => {
        return (
          <div key={`amount-limited-${amount_type}`} className={styles.campaignCategoryItem}>
            <SelectField
              label={amount_type}
              onSelect={() => {
                onChange(amount_type);
              }}
              checked={selectedCategory === amount_type}
              icon={memoizedIcon(amount_type)}
              disabled={disabled}
            />
          </div>
        );
      })}
    </div>
  );
};
