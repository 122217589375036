import React, { useCallback } from 'react';
import { CN } from '../../className';
// import { RadioButton } from '../RadioButton/RadioButton';
import style from './SelectField2.module.scss';

type Props = {
  label: string;
  onChange: (checked: boolean) => void;
  checked?: boolean;
  customClassName?: string;
  disabled?: boolean;
};

export const SelectField2: React.FC<Props> = ({ label, onChange, checked = false, customClassName, disabled = false }) => {
  const onChangeCall = useCallback(() => {
    if (disabled) return;
    onChange(!checked);
  }, [checked, disabled, onChange]);

  return (
    <div className={`${CN(style.mainDiv, [[style.checked, checked === true]])} ${customClassName}`} onClick={onChangeCall}>
      <div className={style.iconDiv}>
        <div className={style.iconText}>{label}</div>
      </div>
      {/* <div>
        <RadioButton checked={checked} radioButtonOnChange={onChangeCall} />
      </div> */}
    </div>
  );
};
