import React from 'react';
import { IconName } from '../../../helper/constants';
import { CN } from '../../className';
import { Icon } from '../Icon/Icon';
import { RadioButton } from '../RadioButton/RadioButton';
import style from './SelectField.module.scss';

type Props = {
  icon: IconName;
  label: string;
  onSelect: () => void;
  checked?: boolean;
  disabled?: boolean;
  customClassName?: string;
};

export const SelectField: React.FC<Props> = ({ icon, label, onSelect, checked = false, disabled, customClassName }) => {
  return (
    <div
      className={`${CN(style.mainDiv, [
        [style.checked, checked === true],
        [style.disabled, disabled === true],
      ])} ${customClassName}`}
      onClick={onSelect}
    >
      <div className={style.iconDiv}>
        <Icon name={icon} />
        <div className={style.iconText}>{label}</div>
      </div>
      <div>
        <RadioButton checked={checked} radioButtonOnChange={onSelect} />
      </div>
    </div>
  );
};
