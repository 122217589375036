import { useCallback } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Icon } from '../../../../../components/Base/Icon/Icon';
import styles from './ReportTableHeader.module.scss';
import Model from '@tripian/model';

type Props = {
  orderColumnIndex: number;
  setOrderColumnIndex: (newOrderColumnIndex: number) => void;
  orderAsc: boolean;
  setOrderAsc: (newOrderAsc: boolean) => void;
  query: Partial<Model.CampaignCustomerReportRequest>;
  setQuery: (newFilter: Partial<Model.CampaignCustomerReportRequest>) => void;
};

export const ReportTableHeader: React.FC<Props> = ({ orderColumnIndex, setOrderColumnIndex, orderAsc, setOrderAsc, query, setQuery }) => {
  const rowHeaderClicked = useCallback(
    (index: number, columnName: string) => {
      let newOrder = `${columnName},`;

      if (orderColumnIndex === index) {
        setOrderAsc(!orderAsc);
        newOrder += `${orderAsc === true ? 'desc' : 'asc'}`;
      } else {
        setOrderColumnIndex(index);
        setOrderAsc(true);
        newOrder += 'asc';
      }

      // const newOrder = `${columnName},${orderAsc === true ? 'asc' : 'desc'}`;

      setQuery({ ...query, order: newOrder });
    },
    [orderAsc, orderColumnIndex, query, setOrderAsc, setOrderColumnIndex, setQuery],
  );

  return (
    <TableRow style={{ cursor: 'pointer' }} className={styles.tableHeader}>
      <TableCell onClick={() => rowHeaderClicked(0, 'id')} className={styles.tableSmallWidth}>
        <div className={styles.tableHeaderContent}>
          ID {orderColumnIndex === 0 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(1, 'fullName')}>
        <div className={styles.tableHeaderContent}>
          Full Name {orderColumnIndex === 1 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(2, 'email')}>
        <div className={styles.tableHeaderContent}>
          Email {orderColumnIndex === 2 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(3, 'numberOfTravelers')}>
        <div className={styles.tableHeaderContent}>
          No. Of Travelers {orderColumnIndex === 3 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(5, 'applicationDate')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Application Date {orderColumnIndex === 5 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(4, 'status')}>
        <div className={styles.tableHeaderContent}>
          Status {orderColumnIndex === 4 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(6, 'arrivalDate')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Arrival Date {orderColumnIndex === 6 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(7, 'departureDate')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Departure Date {orderColumnIndex === 7 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(8, 'lengthOfStay')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Length Of Stay {orderColumnIndex === 8 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(9, 'propertyType')}>
        <div className={styles.tableHeaderContent}>
          Property Type {orderColumnIndex === 9 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(10, 'propertyName')}>
        <div className={styles.tableHeaderContent}>
          Property Name {orderColumnIndex === 10 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(11, 'bookingDate')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Booking Date {orderColumnIndex === 11 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(12, 'arrivalFrom')}>
        <div className={styles.tableHeaderContent}>
          Arrival From {orderColumnIndex === 12 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(13, 'airline')}>
        <div className={styles.tableHeaderContent}>
          Airline {orderColumnIndex === 13 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(14, 'claimCount')}>
        <div className={styles.tableHeaderContent}>
          Claim Count {orderColumnIndex === 14 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(15, 'redeemCount')}>
        <div className={styles.tableHeaderContent}>
          Redeem Count {orderColumnIndex === 15 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(16, 'totalRedeemedAmount')}>
        <div className={styles.tableHeaderContent}>
          Total Redeem Amount {orderColumnIndex === 16 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(17, 'claimDate')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Claimed Date {orderColumnIndex === 17 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(18, 'redeemedAt')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Redeemed At {orderColumnIndex === 18 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(19, 'redeemDate')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Redeemed Date {orderColumnIndex === 19 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(20, 'bookedTripBy')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Booked Trip By {orderColumnIndex === 20 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>

      <TableCell onClick={() => rowHeaderClicked(21, 'heardBy')} className={styles.tableMediumWidth}>
        <div className={styles.tableHeaderContent}>
          Heard By {orderColumnIndex === 21 && (orderAsc ? <Icon name="ARROW_UP" /> : <Icon name="ARROW_DOWN" />)}
        </div>
      </TableCell>
    </TableRow>
  );
};
