import React, { useCallback } from 'react';
import { offerTypeName } from '../../../../../core/data';
import { IconName } from '../../../../../helper/constants';
import Model from '@tripian/model';
import { SelectField } from '../../../../Base/SelectField/SelectField';

import styles from './OfferTypeList.module.scss';

export interface OfferTypeListProps {
  selectedOfferType: Model.OFFER_TYPE;
  onChange: (offerType: Model.OFFER_TYPE) => void;
}

export const OfferTypeList: React.FC<OfferTypeListProps> = ({ selectedOfferType, onChange }) => {
  const memoizedIcon = useCallback((offerType: Model.OFFER_TYPE): IconName => {
    if (offerType === Model.OFFER_TYPE.AMOUNT) {
      return 'DOLLAR';
    } else if (offerType === Model.OFFER_TYPE.PERCENTAGE) {
      return 'PERCENT';
    } else if (offerType === Model.OFFER_TYPE.QUANTITY) {
      return 'MULTIBUY';
    } else if (offerType === Model.OFFER_TYPE.VOUCHER) {
      return 'BOOK_ONLINE';
    }
    return 'GIFT';
  }, []);

  return (
    <div className={styles.content}>
      {window.tconfig.SBT.OFFER_TYPES.length > 0 &&
        window.tconfig.SBT.OFFER_TYPES.map((ot) => {
          return (
            <div key={`offer-types-${ot}`} className={styles.offerTypeListItem}>
              <SelectField
                label={offerTypeName(ot)}
                onSelect={() => {
                  onChange(ot);
                }}
                // disabled={window.tconfig.BRAND_NAME === 'bookbarbados' && ot !== Model.OFFER_TYPE.VOUCHER}
                checked={ot === selectedOfferType}
                icon={memoizedIcon(ot)}
              />
            </div>
          );
        })}
    </div>
  );
};
