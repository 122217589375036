import moment from 'moment';
import Model from '@tripian/model';
import styles from './ApplicationCard.module.scss';
import { useHistory } from 'react-router';
import { APPLICATION_DETAILS } from '../../const/ROUTER_PATH_TITLE';

interface IApplicationCard {
  application: Model.CouponApplication;
}

export const ApplicationCard: React.FC<IApplicationCard> = ({ application }) => {
  const history = useHistory();

  return (
    <div className={styles.cardContainer}>
      <div>
        <div className={styles.name}>
          {application.personalInformations[0].firstName} {application.personalInformations[0].lastName}
        </div>

        {/* <div className={styles.size}>{application.travelInformation.adults} Travelers</div> */}
        <div className={styles.size}>
          {application.personalInformations.filter((x) => x.status !== 0).length} / {application.personalInformations.length} traveler(s) reviewed.
        </div>
        {/* <div className={styles.size}>{application.personalInformations.filter((x) => x.status === 1).length} Travelers approved.</div>
        <div className={styles.size}>{application.personalInformations.filter((x) => x.status === -1).length} Travelers rejected.</div> */}
        <div className={styles.accomodation}>Staying in {application.travelInformation.nameOfProperty} during their holiday</div>
        <div className={styles.accomodation}>Application submitted on {moment(application.applicationDate).format('DD.MM.YYYY hh:mm')}</div>
        <div className={styles.downloadWrapper}>
          <a href={application.travelInformation.proofOfHolidayBookingUrl} download>
            Download Image From Here
          </a>
          <svg width="16" height="18" viewBox="0 0 16 18" fill="none">
            <path
              d="M6.73798 0.25C5.96436 0.25 5.31904 0.841231 5.25148 1.61189C5.11024 3.22317 5.0745 4.84164 5.14436 6.45693C5.06003 6.46258 4.97571 6.46847 4.8914 6.47462L3.4019 6.5832C2.44985 6.6526 1.91764 7.71416 2.43163 8.51854C3.5278 10.2341 4.94026 11.7254 6.59369 12.9132L7.19043 13.3418C7.67425 13.6894 8.32591 13.6894 8.80972 13.3418L9.40646 12.9132C11.0599 11.7254 12.4724 10.2341 13.5685 8.51855C14.0825 7.71416 13.5503 6.6526 12.5983 6.5832L11.1088 6.47462C11.0245 6.46847 10.9401 6.46258 10.8558 6.45693C10.9257 4.84164 10.8899 3.22317 10.7487 1.61188C10.6811 0.841229 10.0358 0.25 9.26219 0.25H6.73798ZM6.68157 7.11473C6.56786 5.3275 6.58909 3.53417 6.74513 1.75H9.25503C9.41106 3.53417 9.4323 5.3275 9.31859 7.11473C9.30584 7.31509 9.37396 7.51221 9.5077 7.66195C9.64144 7.8117 9.82964 7.90156 10.0302 7.91144C10.3535 7.92737 10.6767 7.94711 10.9997 7.97065L12.0815 8.04951C11.1219 9.46281 9.92044 10.6971 8.53133 11.6949L8.00008 12.0765L7.46882 11.6949C6.07972 10.6971 4.87824 9.4628 3.9187 8.04951L5.00046 7.97065C5.32345 7.94711 5.64664 7.92737 5.96999 7.91144C6.17052 7.90156 6.35871 7.81169 6.49246 7.66195C6.6262 7.51221 6.69432 7.31509 6.68157 7.11473Z"
              fill="#49a0ff"
            />
            <path
              d="M1.75 14C1.75 13.5858 1.41421 13.25 1 13.25C0.585786 13.25 0.25 13.5858 0.25 14V16C0.25 16.9665 1.0335 17.75 2 17.75H14C14.9665 17.75 15.75 16.9665 15.75 16V14C15.75 13.5858 15.4142 13.25 15 13.25C14.5858 13.25 14.25 13.5858 14.25 14V16C14.25 16.1381 14.1381 16.25 14 16.25H2C1.86193 16.25 1.75 16.1381 1.75 16V14Z"
              fill="#49a0ff"
            />
          </svg>
        </div>
      </div>

      <div
        onClick={(e) => {
          e.stopPropagation();
          history.push(APPLICATION_DETAILS.PATH + '/' + application.id.toString());
        }}
        onTouchStart={(e) => {
          e.stopPropagation();
          history.push(APPLICATION_DETAILS.PATH + '/' + application.id.toString());
        }}
        className={styles.arrow}
      >
        <svg viewBox="0 0 7 10" fill="none">
          <path
            d="M0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L6.03033 4.46967C6.32322 4.76256 6.32322 5.23744 6.03033 5.53033L2.03033 9.53033C1.73744 9.82322 1.26256 9.82322 0.96967 9.53033C0.676777 9.23744 0.676777 8.76256 0.96967 8.46967L4.43934 5L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967Z"
            fill="#49a0ff"
          />
        </svg>
      </div>
    </div>
  );
};
