import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { QrReader } from 'react-qr-reader';
import { QR_READER, OFFER_PAYMENT } from '../../../const/ROUTER_PATH_TITLE';
import classes from './QrReaderPage.module.scss';

export const QrReaderPage = () => {
  const [url, setUrl] = useState<string | undefined>();

  const history = useHistory();
  document.title = QR_READER.TITLE;

  useEffect(() => {
    console.log(url);
    if (url) {
      if (url.includes(OFFER_PAYMENT.PATH)) {
        const startIndex = url.indexOf(OFFER_PAYMENT.PATH);
        const relativePath = url.substring(startIndex);
        history.replace(relativePath);
        // window.location.href = url;
      } else {
        history.goBack();
      }
    }
  }, [history, url]);

  return (
    <div className={classes.qrReader}>
      <QrReader
        constraints={{ facingMode: 'environment' }}
        onResult={(result, error) => {
          if (!!result) {
            setUrl(result?.getText());
          }

          if (!!error) {
            console.info(error);
          }
        }}
        videoContainerStyle={{ width: '100%', height: '100%', paddingTop: '0' }}
        containerStyle={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};
