import React from 'react';
import { CN } from '../../className';

import styles from './Input.module.scss';

export interface InputProps {
  type: string;
  value?: string | number;
  name: string;
  id?: string;
  size?: 'small' | 'default' | 'large';
  minWidth?: string;
  customClassName?: string;
  placeholder?: string;
  defaultValue?: string | number;
  autoComplete?: string;
  min?: string | number;
  max?: string | number;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
}

export const Input: React.FC<InputProps> = ({ size = 'default', customClassName, disabled = false, ...props }) => {
  const className = CN(styles.input, [
    [styles.small, size === 'small'],
    [styles.large, size === 'large'],
    [styles.default, size === 'default'],
    [customClassName || '', customClassName !== undefined],
  ]);

  return <input className={className} disabled={disabled} {...props} autoComplete="off"></input>;
};
