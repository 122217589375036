import Model from '@tripian/model';
import LOCAL_STORAGE_KEYS from '../const/LOCAL_STORAGE_KEYS';

export const clearLocalStorage = (): void => {
  const clearKeys = LOCAL_STORAGE_KEYS.CLEAR.split(',');
  clearKeys.forEach((clearKey) => {
    localStorage.removeItem(clearKey);
  });
};

const getLocalStorageToken = (): Model.Token | undefined => {
  try {
    const trptkn = localStorage.getItem(`${window.location.hostname}-${LOCAL_STORAGE_KEYS.TOKEN}`);
    if (trptkn) {
      const token: Model.Token = JSON.parse(trptkn) as Model.Token;
      return token;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const removeLocalStorageToken = (): void => {
  localStorage.removeItem(`${window.location.hostname}-${LOCAL_STORAGE_KEYS.TOKEN}`);
};

const saveLocalStorageToken = (token: Model.Token) => {
  localStorage.setItem(`${window.location.hostname}-${LOCAL_STORAGE_KEYS.TOKEN}`, JSON.stringify(token));
};

export { getLocalStorageToken, removeLocalStorageToken, saveLocalStorageToken };
