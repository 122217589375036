import React from 'react';

import styles from './TextArea.module.scss';

export interface TextAreaProps {
  name: string;
  value?: string;
  customClassName?: string;
  placeholder?: string;
  rows?: number;
  charLimit?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: React.FC<TextAreaProps> = ({ rows = 2, charLimit = 0, customClassName, ...props }) => {
  return (
    <div>
      <textarea className={`${styles.textArea} ${customClassName}`} rows={rows} {...props} autoComplete="off" maxLength={charLimit} />
      {charLimit > 0 && <div className={styles.extraText}>{charLimit - (props.value ? props.value.length : 0)} characters left</div>}
    </div>
  );
};
