import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';

import { captionCreater, validateOfferForFirstStep, validateOfferForSecondStep } from './helper';
import Model from '@tripian/model';
import { easy } from '@tripian/core';
import { Button } from '../../components/Base/Button/Button';
import { Input } from '../../components/Base/Input/Input';
import { InputLabel } from '../Base/InputLabel/InputLabel';
/* import { LinkButton } from '../Base/LinkButton/LinkButton'; */

/* import { OfferFormSteps } from './OfferFormStepsLine/OfferFormStepsLine'; */
import { OfferCategory } from './steps/1/OfferCategory/OfferCategory';
import { OfferTypeCombobox } from './steps/2/OfferTypeCombobox/OfferTypeCombobox';
import { OfferTypeDetails } from './steps/2//OfferTypeDetails/OfferTypeDetails';
import { OfferImage } from './steps/3/OfferImage/OfferImage';

import { productTypeById, productTypeId } from '../../core/data';
import { OfferTypeList } from './steps/1/OfferTypeList/OfferTypeList';

import { timeFrame, initialOfferFormData } from '../../helper/constants';

import { TextArea } from '../Base/TextArea/TextArea';
import { OfferCard } from '../OfferCard/OfferCard';
import { CustomerOfferCard } from '../CustomerOfferCard/CustomerOfferCard';
import UserContext from '../../context/User/UserContext';
import { LineStep } from '../Base/LineStep/LineStep';
import { FormContainer } from '../Base/FormContainer/FormContainer';
import { FullCenter } from '../Base/FullCenter/FullCenter';
import { scrollTop } from '../../helper/common';
import NumberInput from '../Base/NumberInput/NumberInput';
import { DateTimePicker } from '../DateTimePicker/DateTimePicker';
import { RecurrentDays } from '../CampaignForm/steps/1/RecurrentDays/RecurrentDays';
import { BlackoutDays } from '../CampaignForm/steps/1/BlackoutDays/BlackoutDays';
import DayPickerSingleDateControllerWrapper from '../DayPickerSingleDateControllerWrapper/DayPickerSingleDateControllerWrapper';
import styles from './OfferForm.module.scss';
import { RSelect } from '../RSelect/RSelect';

type Props = {
  poiName: string;
  defaultImageUrl: string;
  formData: Model.OfferAddRequest;
  formOnChange: (offer: Model.OfferAddRequest) => void;
  voucherOfferFormData: Model.VoucherOfferAddRequest;
  voucherOfferFormOnChange: (offer: Model.VoucherOfferAddRequest) => void;
  formCallBack: (passive?: boolean) => void;
  goBack: () => void;
  uploadImage: (imageFile: string) => Promise<{ url: string }>;
  campaigns: Model.Campaign[];
  poisToSelect: Model.Poi[];
  setCustomPoiQuery: (query: string) => void;
};

export const OfferForm: React.FC<Props> = ({
  poiName,
  defaultImageUrl,
  formData,
  voucherOfferFormData,
  voucherOfferFormOnChange,
  formCallBack,
  formOnChange,
  goBack,
  uploadImage,
  campaigns,
  poisToSelect,
  setCustomPoiQuery,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showQuantity, setShowQuantity] = useState<boolean>(true);

  const formProductType = useMemo(() => productTypeById(formData.productTypeId), [formData.productTypeId]);

  const [step, setStep] = useState<number>(1);
  const [offerCategory, setOfferCategory] = useState<Model.PRODUCT_TYPE_NAME>(formProductType.name);
  const [showBlackOutDays, setShowBlackOutDays] = useState<boolean>(false);
  const [showRecurrentDays, setShowRecurrentDays] = useState<boolean>(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Model.Campaign | undefined>();

  const { payloadData } = useContext(UserContext);
  const history = useHistory();

  const [tdate, setTdate] = useState(
    new easy.TDate({
      startDate: formData.timeframe.start,
      endDate: formData.timeframe.end,
      recurrent: formData.timeframe.recurrent,
      blackouts: formData.timeframe.blackouts,
    }),
  );

  useEffect(() => {
    if (voucherOfferFormData.campaignId) {
      const selectedCampaign = campaigns.find((c) => c.id === voucherOfferFormData.campaignId);
      setSelectedCampaign(selectedCampaign);
    }
  }, [campaigns, voucherOfferFormData.campaignId]);

  useEffect(() => {
    const blackouts = selectedCampaign
      ? [...formData.timeframe.blackouts, ...selectedCampaign.timeframe.blackouts]
      : [...formData.timeframe.blackouts];
    const recurrent = selectedCampaign
      ? formData.timeframe.recurrent.filter((r) => selectedCampaign.timeframe.recurrent.includes(r))
      : [...formData.timeframe.recurrent];
    setTdate(
      new easy.TDate({
        startDate: formData.timeframe.start,
        endDate: formData.timeframe.end,
        recurrent,
        blackouts,
      }),
    );
  }, [
    formData.timeframe.start,
    formData.timeframe.end,
    formData.timeframe.recurrent,
    formData.timeframe.blackouts,
    campaigns,
    voucherOfferFormData.campaignId,
    selectedCampaign,
  ]);

  useEffect(() => {
    scrollTop();
  }, [step]);

  const backButtonClick = () => {
    if (step === 1) {
      history.goBack();
    } else {
      setStep(step - 1);
    }
  };

  const continueButtonClick = () => {
    setStep(step + 1);
  };

  const onChangePoi = (poi: Model.Poi) => {
    formOnChange({
      ...formData,
      placeId: poi.id,
      cityId: poi.cityId,
    });
    voucherOfferFormOnChange({
      ...voucherOfferFormData,
      placeId: poi.id,
      cityId: poi.cityId,
    });
  };

  const onChangeDate = (inputType: string, event: moment.Moment | null) => {
    if (event) {
      const dateTime = event.format();

      if (inputType === 'start') {
        formOnChange({
          ...formData,
          timeframe: {
            ...formData.timeframe,
            start: dateTime,
            end: dateTime,
          },
        });

        voucherOfferFormOnChange({
          ...voucherOfferFormData,
          timeframe: {
            ...formData.timeframe,
            start: dateTime,
            end: dateTime,
          },
        });
      } else {
        formOnChange({
          ...formData,
          timeframe: {
            ...formData.timeframe,
            end: dateTime,
          },
        });

        voucherOfferFormOnChange({
          ...voucherOfferFormData,
          timeframe: {
            ...formData.timeframe,
            end: dateTime,
          },
        });
      }
    }
  };

  const recurrentDisableds = useMemo(
    () => (selectedCampaign ? [0, 1, 2, 3, 4, 5, 6].filter((item) => !selectedCampaign.timeframe.recurrent.includes(item)) : []),
    [selectedCampaign],
  );

  const extendedBlackouts = useMemo(() => (selectedCampaign ? selectedCampaign.timeframe.blackouts : []), [selectedCampaign]);

  const stepHeader = step === 1 ? 'Offer Type' : step === 2 ? 'Details' : step === 3 ? 'Photo' : 'Preview Offer';
  const renderItems = () => {
    if (step === 1) {
      return (
        <>
          <InputLabel text="Title" customClassName="mt3" />
          <Input
            type="text"
            name="offerTitle"
            size="large"
            value={formData.title}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              formOnChange({ ...formData, title: event.target.value.substring(0, 40) });
              voucherOfferFormOnChange({ ...voucherOfferFormData, title: event.target.value });
            }}
            customClassName="mb4"
            placeholder="Write here"
          />
          <hr />
          {payloadData.user?.userType === Model.USER_TYPE.SUPER_ADMIN && (
            <>
              <InputLabel text="Select POI" customClassName="mt3" />
              <RSelect
                options={poisToSelect.map((poi, index) => {
                  return { label: poi.name, id: index, payload: poi };
                })}
                defaultOptionId={poisToSelect.findIndex((poi) => poi.id === formData.placeId || poi.id === voucherOfferFormData.placeId)}
                onInputChange={(searchQuery) => setCustomPoiQuery(searchQuery)}
                onSelectedChanged={(selectedOption) => {
                  onChangePoi(selectedOption.payload);
                }}
              />
            </>
          )}
          <hr />

          <div className={styles.offerTypeLabel}>
            <InputLabel text="Offer type" customClassName="mt3" />
            {/* <LinkButton onClick={() => {}} text="Need Help?" /> */}
          </div>
          <OfferTypeList
            selectedOfferType={
              window.tconfig.SBT.OFFER_TYPES.includes(Model.OFFER_TYPE.VOUCHER) ? voucherOfferFormData.offerType : formData.offerType
            }
            onChange={(offerType: Model.OFFER_TYPE) => {
              let newThreshold = initialOfferFormData.threshold;
              let newDiscount = initialOfferFormData.discount;
              let newProductName = initialOfferFormData.productName;
              let newDiscountedProductCount = initialOfferFormData.discountedProductCount;

              if (offerType === Model.OFFER_TYPE.QUANTITY) {
                newThreshold = 1;
                newDiscount = 100;
              }

              formOnChange({
                ...formData,
                offerType,
                threshold: newThreshold,
                discount: newDiscount,
                productName: newProductName,
                discountedProductCount: newDiscountedProductCount,
              });
            }}
          />

          <hr />
          <InputLabel text="Category" customClassName="mt3" />
          <OfferCategory
            selectedCategory={offerCategory}
            onChange={(productTypeName: Model.PRODUCT_TYPE_NAME) => {
              /* console.log(productTypeName + ' selected.'); */
              setOfferCategory(productTypeName);
              const ptId = productTypeId(productTypeName);
              formOnChange({ ...formData, productTypeId: ptId });
              voucherOfferFormOnChange({ ...voucherOfferFormData, productTypeId: ptId });
            }}
          />
        </>
      );
    } else if (step === 2) {
      return (
        <>
          {formData.offerType !== Model.OFFER_TYPE.VOUCHER && !window.tconfig.SBT.OFFER_TYPES.includes(Model.OFFER_TYPE.VOUCHER) && (
            <OfferTypeCombobox
              selectedOfferType={formData.offerType}
              onChange={(offerType: Model.OFFER_TYPE) => {
                let newThreshold = initialOfferFormData.threshold;
                let newDiscount = initialOfferFormData.discount;
                let newProductName = initialOfferFormData.productName;
                let newDiscountedProductCount = initialOfferFormData.discountedProductCount;

                if (offerType === Model.OFFER_TYPE.QUANTITY) {
                  newThreshold = 1;
                  newDiscount = 100;
                }

                formOnChange({
                  ...formData,
                  offerType,
                  threshold: newThreshold,
                  discount: newDiscount,
                  productName: newProductName,
                  discountedProductCount: newDiscountedProductCount,
                });
              }}
            />
          )}

          <OfferTypeDetails
            offerType={
              window.tconfig.SBT.OFFER_TYPES.includes(Model.OFFER_TYPE.VOUCHER)
                ? (voucherOfferFormData.offerType as Model.OFFER_TYPE.VOUCHER)
                : formData.offerType
            }
            threshold={formData.threshold}
            onChangeThreshold={(threshold: number) => formOnChange({ ...formData, threshold })}
            discount={formData.discount}
            onChangeDiscount={(discount: number) => {
              formOnChange({ ...formData, discount });
              voucherOfferFormOnChange({ ...voucherOfferFormData, discount });
            }}
            productName={formData.productName}
            onChangeProductName={(productName: string) => formOnChange({ ...formData, productName, giftName: productName })}
            giftName={formData.giftName}
            onChangeGiftName={(giftName: string) => formOnChange({ ...formData, giftName })}
            productCount={formData.discountedProductCount}
            onChangeProductCount={(discountedProductCount: number) => formOnChange({ ...formData, discountedProductCount })}
            campaigns={campaigns}
            campaignId={voucherOfferFormData.campaignId}
            onChangeCampaign={(campaignId: number) => {
              voucherOfferFormOnChange({ ...voucherOfferFormData, campaignId });
              const selectedCampaign = campaigns.find((c) => c.id === voucherOfferFormData.campaignId);
              if (selectedCampaign) {
                formOnChange({
                  ...formData,
                  timeframe: { ...formData.timeframe, start: selectedCampaign.timeframe.start, end: selectedCampaign.timeframe.end },
                });
              }
            }}
          />
          <hr />
          <InputLabel text="Offer eligibility period" customClassName={`${styles.datePickerLabel} my3 bold`} />
          <div className="row mb0">
            <div className="col col5 p0 pr1">
              {/* <InputLabel text="Start Date" />
              <DateTimePickerComponent
                placeholder="Start Date"
                min={timeFrame.today}
                // max={timeFrame.amonth}
                value={new Date(formData.timeframe.start)}
                showClearButton={false}
                openOnFocus
                onChange={(event: any) => {
                  onChangeDate('start', event);
                }}
                className={styles.dateTimePickerCustom}
                id="datetimepicker1"
              /> */}
              <DateTimePicker
                label="Start Date"
                slotProps={{ textField: { color: 'info', focused: false } }}
                typeable={false}
                value={
                  formData.offerType === Model.OFFER_TYPE.VOUCHER
                    ? moment(campaigns.find((c) => c.id === voucherOfferFormData.campaignId)?.timeframe.start)
                    : moment(formData.timeframe.start)
                }
                minDateTime={formData.offerType === Model.OFFER_TYPE.VOUCHER ? undefined : moment(timeFrame.today)}
                onChange={(value: moment.Moment | null) => {
                  onChangeDate('start', value);
                }}
                disablePast
                // disabled={formData.offerType === Model.OFFER_TYPE.VOUCHER}
                className={styles.datePicker}
              />
            </div>
            <div className="col col5 p0">
              {/* <InputLabel text="End Date" />
              <DateTimePickerComponent
                placeholder="End Date"
                id="datetimepicker2"
                min={new Date(formData.timeframe.start)}
                // max={
                //   moment(timeFrame.amonth).diff(moment(formData.timeframe.start), 'days') > 6
                //     ? new Date(moment(formData.timeframe.start).add(1, 'month').format())
                //     : timeFrame.amonth
                // }
                max={new Date(moment(formData.timeframe.start).add(13, 'day').format())}
                value={new Date(formData.timeframe.end)}
                showClearButton={false}
                openOnFocus
                className={styles.dateTimePickerCustom}
                onChange={(event: any) => {
                  onChangeDate('end', event);
                }}
              /> */}
              <DateTimePicker
                label="End Date"
                slotProps={{ textField: { color: 'info', focused: false } }}
                typeable={false}
                value={
                  formData.offerType === Model.OFFER_TYPE.VOUCHER
                    ? moment(campaigns.find((c) => c.id === voucherOfferFormData.campaignId)?.timeframe.end)
                    : moment(formData.timeframe.end)
                }
                minDateTime={formData.offerType === Model.OFFER_TYPE.VOUCHER ? undefined : moment(formData.timeframe.start)}
                maxDateTime={
                  selectedCampaign ? moment(selectedCampaign.timeframe.end) : moment(moment(formData.timeframe.start).add(13, 'day').format())
                }
                onChange={(value: moment.Moment | null) => {
                  onChangeDate('end', value);
                }}
                // disabled={formData.offerType === Model.OFFER_TYPE.VOUCHER}
                sx={{ width: 'fit-content' }}
              />
            </div>
          </div>
          <div>
            <div className={styles.titleWithCheckbox}>
              <Input
                id="recurrent"
                type="checkbox"
                name="recurrent"
                size="default"
                checked={showRecurrentDays}
                customClassName={styles.checkBoxInput}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setShowRecurrentDays(event.target.checked);
                  if (event.target.checked === false) {
                    formOnChange({ ...formData, timeframe: { ...formData.timeframe, recurrent: [0, 1, 2, 3, 4, 5, 6] } });
                  }
                }}
              />
              <InputLabel htmlFor="recurrent" textUppercase={false} text="Recurrent" customClassName="mt3" />
            </div>

            {showRecurrentDays && (
              <RecurrentDays
                recurrents={formData.timeframe.recurrent}
                onChange={(recurrent) => formOnChange({ ...formData, timeframe: { ...formData.timeframe, recurrent } })}
                disableds={recurrentDisableds}
              />
            )}
          </div>

          <div>
            <div className={styles.titleWithCheckbox}>
              <Input
                id="blackouts"
                type="checkbox"
                name="blackouts"
                size="default"
                checked={showBlackOutDays}
                customClassName={styles.checkBoxInput}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setShowBlackOutDays(event.target.checked);
                  if (event.target.checked === false) {
                    formOnChange({ ...formData, timeframe: { ...formData.timeframe, blackouts: [] } });
                  }
                }}
              />
              <InputLabel htmlFor="blackouts" textUppercase={false} text="Blackout dates" customClassName="mt3" />
            </div>
            {showBlackOutDays && (
              <BlackoutDays
                blackouts={formData.timeframe.blackouts}
                campaignRange={{ startDate: formData.timeframe.start, endDate: formData.timeframe.end }}
                onChange={(blackoutsDates: string[]) =>
                  formOnChange({ ...formData, timeframe: { ...formData.timeframe, blackouts: blackoutsDates } })
                }
                extendedBlackouts={extendedBlackouts}
              />
            )}
          </div>
          <div>
            <InputLabel textUppercase={false} text="Offer Available Days" customClassName="mt3 mb3" />
            <div className="mt3 mb3" style={{ display: 'flex', flexWrap: 'wrap' }}>
              <DayPickerSingleDateControllerWrapper
                isDayHighlighted={(day) => {
                  const dayFormatted = day.format('YYYY-MM-DD');
                  console.log('tdate.toList()', tdate.toList());
                  return tdate.toList().includes(dayFormatted);
                }}
              />
            </div>
          </div>

          {formData.offerType !== Model.OFFER_TYPE.VOUCHER && (
            <>
              <div className={styles.quantityDiv}>
                <input
                  className={styles.quantityCheck}
                  type="checkbox"
                  checked={showQuantity}
                  disabled
                  onChange={() => {
                    // setShowQuantity(!showQuantity);
                  }}
                />
                <span>Set a maximum # of recipients</span>
              </div>

              {showQuantity && (
                <NumberInput
                  customClassName={styles.numberInput}
                  name="quantity"
                  placeholder="Enter maximum #"
                  minValue={0}
                  value={formData.quantity && formData.quantity > 0 ? formData.quantity : undefined}
                  onChange={(value) => {
                    formOnChange({ ...formData, quantity: value });
                    voucherOfferFormOnChange({ ...voucherOfferFormData, quantity: value });
                  }}
                />
              )}
            </>
          )}
          <hr />
          <InputLabel text="Offer Description" customClassName="mt3" />
          <TextArea
            rows={3}
            name="description"
            value={formData.description}
            charLimit={100}
            onChange={(event) => {
              formOnChange({ ...formData, description: event.target.value });
              voucherOfferFormOnChange({ ...voucherOfferFormData, description: event.target.value });
            }}
          />
        </>
      );
    } else if (step === 3) {
      return (
        <OfferImage
          imageUrl={formData.imageUrl}
          onChange={(imageUrl?: string) => {
            formOnChange({ ...formData, imageUrl });
            voucherOfferFormOnChange({ ...voucherOfferFormData, imageUrl: imageUrl || '' });
          }}
          upload={uploadImage}
          uploading={() => {}}
        />
      );
    } else if (step === 4) {
      if (formData.offerType === Model.OFFER_TYPE.VOUCHER) {
        const currentCampaign = campaigns.find((campaign) => campaign.id === voucherOfferFormData.campaignId);

        const offer: Model.Offer = {
          caption: captionCreater(
            voucherOfferFormData.offerType as Model.OFFER_TYPE,
            formData.threshold,
            voucherOfferFormData.discount,
            formData.discountedProductCount || 0,
            formData.productName,
          ),
          currency: Model.OFFER_CURRENCY.BBD,
          description: voucherOfferFormData.description || '',
          discount: voucherOfferFormData.discount,
          discountedProductCount: formData.discountedProductCount || 0,
          id: 0,
          imageUrl: voucherOfferFormData.imageUrl || defaultImageUrl,
          title: voucherOfferFormData.title,
          offerType: voucherOfferFormData.offerType as Model.OFFER_TYPE,
          productName: formData.productName,
          productType: productTypeById(voucherOfferFormData.productTypeId),
          quantity: voucherOfferFormData.quantity || 0,
          threshold: formData.threshold,
          timeframe: formData.timeframe,
          status: Model.OFFER_STATUS.ACTIVE,
          businessId: 0,
          usage: 0,
          tripianPoiId: '',

          campaign: {
            id: currentCampaign?.id || 0,
            title: currentCampaign?.title || '',
            description: currentCampaign?.description || '',
            category: currentCampaign?.category || 'Voucher',
            limited: currentCampaign?.limited || false,
            timeframe: {
              start: currentCampaign?.timeframe.start || '',
              end: currentCampaign?.timeframe.end || '',
              recurrent: currentCampaign?.timeframe.recurrent || [],
              blackouts: currentCampaign?.timeframe.blackouts || [],
            },
            amount: currentCampaign?.amount || 0,
            maximumRecipients: currentCampaign?.maximumRecipients || 0,
            maximumOfferAmount: currentCampaign?.maximumOfferAmount || 0,
          },
        };
        return <CustomerOfferCard poiName={poiName} offer={offer} />;
      } else {
        const offer: Model.Offer = {
          caption: captionCreater(
            formData.offerType,
            formData.threshold,
            formData.discount,
            formData.discountedProductCount || 0,
            formData.productName,
          ),
          currency: formData.currency,
          // customers: [],
          description: formData.description || '',
          discount: formData.discount,
          discountedProductCount: formData.discountedProductCount || 0,
          id: 0,
          imageUrl: formData.imageUrl || defaultImageUrl,
          title: formData.title,
          offerType: formData.offerType,
          productName: formData.productName,
          productType: productTypeById(formData.productTypeId),
          quantity: formData.quantity || 0,
          threshold: formData.threshold,
          timeframe: formData.timeframe,
          status: Model.OFFER_STATUS.ACTIVE,

          // @TODO
          businessId: 0,
          usage: 0,
          tripianPoiId: '',
        };
        return <OfferCard businessName={payloadData.business?.name || 'Business Name'} offer={offer} voucherOffer={voucherOfferFormData} />;
      }
    }
  };

  const renderButtons = () => {
    if (step === 1) {
      return (
        <div className={`${styles.stepButtonsDiv} row`}>
          <div className="col col12 col6-m">
            <Button fluid label="Cancel" type="secondary" onClick={backButtonClick} />
          </div>
          <div className="col col12 col6-m">
            <Button fluid label="Continue" onClick={continueButtonClick} disabled={!validateOfferForFirstStep(formData)} />
          </div>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className={`${styles.stepButtonsDiv} row`}>
          <div className="col col12 col6-m">
            <Button fluid label="Back" type="secondary" onClick={backButtonClick} />
          </div>
          <div className="col col12 col6-m">
            <Button fluid onClick={continueButtonClick} label="Continue" disabled={!validateOfferForSecondStep(formData, voucherOfferFormData)} />
          </div>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className={`${styles.stepButtonsDiv} row`}>
          <div className="col col12 col6-m">
            <Button fluid label="Back" type="secondary" onClick={backButtonClick} />
          </div>
          <div className="col col12 col6-m">
            <Button
              fluid
              onClick={continueButtonClick}
              label="Preview Offer"
              disabled={!validateOfferForSecondStep(formData, voucherOfferFormData)}
            />
          </div>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className={`${styles.stepButtonsDiv} row`}>
          <div className="col col12 col6-m">
            <Button fluid label="Back" type="secondary" onClick={backButtonClick} />
          </div>
          <div className="col col12 col6-m">
            <Button fluid label="Save Only" type="secondary" onClick={() => formCallBack(true)} />
          </div>
          <div className="col col12">
            <Button fluid label="Save & Display Offer" onClick={() => formCallBack()} />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <LineStep stepCount={4} step={step} customClassName={styles.lineStep} />
      <FullCenter centerY={false} maxWidth={700}>
        <h4 className="center pt12">{stepHeader}</h4>
        <FormContainer>
          {renderItems()}
          {renderButtons()}
        </FormContainer>
      </FullCenter>
    </>
  );
};
